export const FETCH_CLIENTS = 'FETCH_CLIENTS'
export const FETCHING_CLIENTS = 'FETCHING_CLIENTS'
export const CLIENTS_FETCHED = 'CLIENTS_FETCHED'
export const FETCH_CLIENT_LIMIT = 'FETCH_CLIENT_LIMIT'
export const FETCHING_CLIENT_LIMIT = 'FETCHING_CLIENT_LIMIT'
export const CLIENT_LIMIT_FETCHED = 'CLIENT_LIMIT_FETCHED'

export const fetchClients = (params) => {
  return Object.assign({
    type: FETCH_CLIENTS
  }, params)
}

export const fetchingClients = (loading) => {
  return {
    type: FETCHING_CLIENTS,
    loading
  }
}

export const clientsFetched = (clients) => {
  return {
    type: CLIENTS_FETCHED,
    clients
  }
}

export const fetchClientLimit = (params) => {
  return Object.assign({
    type: FETCH_CLIENT_LIMIT
  }, params)
}

export const fetchingClientLimit = (loading) => {
  return {
    type: FETCHING_CLIENT_LIMIT,
    loading
  }
}

export const clientLimitFetched = (clientLimit) => {
  return {
    type: CLIENT_LIMIT_FETCHED,
    clientLimit
  }
}
