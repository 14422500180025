export default {
  DefaultRiskItem: {
    risk_type: '',
    risk_name: '',
    risk_trigger: '',
    risk_mgnt_strategy: ''
  },
  Accommondation: [
    { label: 'Own/Family Home', value: 'own-family-home' },
    { label: 'Shared Accomondation', value: 'shared-accommondation' },
    { label: 'Aged Care Facility', value: 'aged-care-facility' },
    { label: 'Supported Residential Service', value: 'supported-residential-service' },
    { label: 'Other', value: 'other' },
    { label: 'Undisclosed', value: 'unknown' }
  ]
}
