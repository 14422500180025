import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participant-careplan'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (clientId) {
    return request(`${url}/${clientId}`)
  },
  listAll (clientId) {
    return request(`${url}/list/all/${clientId}`)
  },
  export (values) {
    return requestFile(`${url}/export`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
