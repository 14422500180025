import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/billings-export'

export default {
  listExportByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  getExportPost (values) {
    return requestFile(`${url}/file`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
}
