import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, settingRecipientService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'

// UI
import { Button, List, Panel, Page, Pager } from '../../../components'
import { Permissions } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import '../styles.css'

const { Item: FormItem } = Form

const longFormItemLayout = {
  labelCol: { sm: 7, md: 7, lg: 7 },
  wrapperCol: { sm: 15, md: 15, lg: 15 }
}

class ReportRecipientSetting extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingForm: false,
      recipients: {}
    }
  }

  componentDidMount () {
    this.fetchRecipientSetting()
  }

  render () {
    const { form } = this.props
    const { recipients, loading, loadingForm } = this.state
    const { getFieldDecorator } = form

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>

        <Page.Content full>
          <Spin spinning={loadingForm} blur>
            <Page.Header title={`Report Recipient Setting`}>
              { this.hasAccess(Permissions.REPORT.RECIPIENT_SETTING.UPDATE)
                ? <div className='btn' onClick={() => this.handleSave()}>
                  Save
                </div>
                : null }
            </Page.Header>

            <div className='setting-list'>
              <Skeleton loading={loading} active>
                <Form>
                  <Panel title='Email Report Recipients'>
                    <FormItem label='Participant Day Counts Since Last Job' {...longFormItemLayout}>
                      {getFieldDecorator('report_day_counts_last_job', {
                        initialValue: recipients.report_day_counts_last_job,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Plan Details' {...longFormItemLayout}>
                      {getFieldDecorator('report_client_plan_detail', {
                        initialValue: recipients.report_client_plan_detail,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Plan Expiry' {...longFormItemLayout}>
                      {getFieldDecorator('report_client_plan_expiry', {
                        initialValue: recipients.report_client_plan_expiry,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Plan Reports Due' {...longFormItemLayout}>
                      {getFieldDecorator('report_client_report_due', {
                        initialValue: recipients.report_client_report_due,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label='Participant Task Reports Due' {...longFormItemLayout}>
                      {getFieldDecorator('report_task_report_due', {
                        initialValue: recipients.report_task_report_due,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Reminder Recipients'>
                    <FormItem label={'Participant\'s SC Hours Notification'} {...longFormItemLayout}>
                      {getFieldDecorator('reminder_ppt_sc_hours', {
                        initialValue: recipients.reminder_ppt_sc_hours,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Report Request Recipients'>
                    <FormItem label='Job Rcv Amt Import' {...longFormItemLayout}>
                      {getFieldDecorator('req_task_job_rct_amt', {
                        initialValue: recipients.req_task_job_rct_amt,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Profile Export Recipients'>
                    <FormItem label='General Contacts List Weekly Update' {...longFormItemLayout}>
                      {getFieldDecorator('setting_general_contact_export', {
                        initialValue: recipients.setting_general_contact_export,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                  <Panel title='Change Notifications Recipients'>
                    <FormItem label={'Support Coordinator Changes'} {...longFormItemLayout}>
                      {getFieldDecorator('update_employee', {
                        initialValue: recipients.update_employee,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label={'Participant Changes'} {...longFormItemLayout}>
                      {getFieldDecorator('update_participant', {
                        initialValue: recipients.update_participant,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label={'Provider Changes'} {...longFormItemLayout}>
                      {getFieldDecorator('update_provider', {
                        initialValue: recipients.update_provider,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                    <FormItem label={'Funder Changes'} {...longFormItemLayout}>
                      {getFieldDecorator('update_funder', {
                        initialValue: recipients.update_funder,
                        rules: [
                          { validator: this.validateEmailMultiple },
                          { required: true, message: ' ' }
                        ]
                      })(
                        <Input />
                      )}
                    </FormItem>
                  </Panel>
                </Form>
              </Skeleton>
            </div>
          </Spin>
        </Page.Content>
      </Page.Body>
    )
  }

  fetchRecipientSetting = async () => {
    this.setState({ loading: true })

    if (this.hasAccess(Permissions.REPORT.RECIPIENT_SETTING.LIST)) {
      const list = await settingRecipientService.getAllRecipients()

      if (list) {
        this.setState({ recipients: list, loading: false, loadingForm: false })
      } else {
        notify.error('Unable to get Recipient Setting', 'Please try again later.')
      }
    }
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0 ) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  handleSave = async () => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { loading, loadingForm, recipients } = this.state

        if (loading || loadingForm) return

        this.setState({ loadingForm: true })

        try {
          const r = await settingRecipientService.updateAllRecipients(values)

          if (r && r.id) {
            notify.success('Update Recipient Setting successfully', 'Recipient Setting is updated.')

            log.updateReportRecipientSetting(r.id, recipients, values, undefined, [
              { key: 'report_day_counts_last_job', label: 'Email of Report - Participant Day Counts Since Last Job' },
              { key: 'report_client_plan_detail', label: 'Email of Report - Participant Plan Details' },
              { key: 'report_client_plan_expiry', label: 'Email of Report - Participant Plan Expiry' },
              { key: 'report_client_report_due', label: 'Email of Report - Participant Plan Report Due' },
              { key: 'report_task_report_due', label: 'Email of Report - Participant Task Report Due' },
              { key: 'reminder_ppt_sc_hours', label: 'Email of Reminder - Participant SC Hours Report' },
              { key: 'req_task_job_rct_amt', label: 'Email of Report Request - Job Rcv Amt Import' },
              { key: 'update_participant', label: 'Email of Update - Participant' },
              { key: 'update_provider', label: 'Email of Update - Provider' },
              { key: 'update_employee', label: 'Email of Update - Support Coordinator' },
              { key: 'update_funder', label: 'Email of Update - Funder' },
            ])

            this.fetchRecipientSetting()
          } else {
            notify.error('Unable to update Recipient Setting', 'Please try again later.')
          }
        } catch (e) {
          notify.error('Unable to update Recipient Setting', 'Please try again later.')
        }

        this.setState({ loadingForm: false })
      }
    })
  }
}

export default Form.create()(ReportRecipientSetting)
