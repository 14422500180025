import { put, takeEvery } from 'redux-saga/effects'
import { FETCH_DASHBOARD_DATA, FETCHING_DASHBOARD_DATA, DASHBOARD_DATA_FETCHED } from '../actions/dashboard'
import dashboardService from '../../services/dashboard'
// import mainLogService from '../../services/setting/mainLog'
import { validator } from '../../util'

function * fetchDashboardData ({ loading, filter }) {
  try {
    yield put({ type: FETCHING_DASHBOARD_DATA, loading })
    const data = yield dashboardService.getCurrentData(filter)
    // const log = yield mainLogService.getLatest()
    // yield put({ type: DASHBOARD_DATA_FETCHED, data, log: validator.isNotEmptyArray(log) ? log : [], loading: false })
    yield put({ type: DASHBOARD_DATA_FETCHED, data, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_DASHBOARD_DATA, loading: false })
    console.error(e)
  }
}

function * watchDashboard () {
  yield takeEvery(FETCH_DASHBOARD_DATA, fetchDashboardData)
}

export default watchDashboard
