export default {
  SC_DAY_COUNT_SINCE_LAST_JOB: `sc-day-count-last-job`,
  SC_CLIENT_PLAN_DETAILS: `sc-client-plan-detail`,
  SC_CLIENT_PLAN_EXPIRY: `sc-client-plan-expiry`,
  SC_CLIENT_PLAN_ENDED: `sc-client-plan-ended`,
  SC_CLIENT_PLAN_ENDING: `sc-client-plan-ending`,
  SC_CLIENT_REPORT_DUE: `sc-client-report-due`,
  SC_CLIENT_PP_REPORT_DUE: `sc-client-pp-report-due`,
  SC_CLIENT_IMP_REPORT_DUE: `sc-client-imp-report-due`,
  SC_CLIENT_TASK_DUE: `sc-client-task-due`
}
