import React, { Component } from 'react'
import moment from 'moment-timezone'
import { authService, fileService } from '../../../services'
import { FileUploadMsg } from '../../../constants'
import { auth, formatter, log, validator } from '../../../util'
import { cloneDeep } from 'lodash'

import { Button, FileUpload, Loading, SideModal } from '../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../config'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

export class AddFileModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      budgetList: [],
      clientId: '',
      clientInfo: {},
      currentSubCatList: [],
      currentSubCat: {},
      fileList: [],
      fileUploadedList: {},
      item: {},
      itemPrev: {},
      isEdit: false,
      loading: false,
      spinLoading: false,
      visible: false,
      uploadErrorMsg: ''
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { budgetList = [], clientId = '', clientInfo = {}, item = {}, visible } = nextProps
    const isEdit = !!item.id
    const itemPrev = cloneDeep(item)

    if (visible === true && prevState.visible === false) {
      return { ...prevState, budgetList, clientId, clientInfo, item, itemPrev, isEdit, visible, currentSubCatList: [], currentSubCat: {} }
    } else {
      return { ...prevState, budgetList, clientId, clientInfo, item, itemPrev, isEdit, visible }
    }
  }

  findCatItems = (input, option) => {
    const item = `${option.props.children}`
    return item.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleSubmit = () => {
    const { clientId, clientInfo, form, onUpdate, categoriesList = [], subCategoriesList = [] } = this.props
    const { budgetList, item, itemPrev, isEdit = false, fileUploadedList } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (!isEdit && !fileUploadedList.uid) {
          this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
          return
        } else {
          try {
            const adminUser = await auth.getCurrentUser() || {}

            if (fileUploadedList.uid) {
              values.fileName = fileUploadedList.fileName
              values.filePath = fileUploadedList.filePath
              values.fileUrl = fileUploadedList.fileUrl
            }

            if (values.is_target_enabled === false) {
              values.target = null
              values.target_id = null
            } else {
              values.target = 'sc-budget'
            }

            // console.log('add modal values', values)

            this.setState({ loading: true })

            let r = null
            if (isEdit) {
              r = await fileService.save(item.id, values)
            } else {
              values.genre = 'participant'
              values.genre_id = clientId
              values.created_by_id = adminUser.id || ''
              values.created_by_name = adminUser.name || ''

              r = await fileService.add(values)
            }

            this.setState({ loading: false })
            if (onUpdate) {
              if (r && r.id) {
                this.fileClear()
                resetFields()

                const cat = values.main_cat_id ? categoriesList.find(e => e.id === values.main_cat_id) : null
                const subcat = values.sub_cat_id ? subCategoriesList.find(e => e.cat_sub_id === values.sub_cat_id) : null
                const bdgt = values.target_id ? budgetList.find(e => e.id === values.target_id) : null

                if (isEdit) {
                  const prevCat = itemPrev.main_cat_id ? categoriesList.find(e => e.id === itemPrev.main_cat_id) : null
                  const prevSubCat = itemPrev.sub_cat_id ? subCategoriesList.find(e => e.cat_sub_id === itemPrev.sub_cat_id) : null

                  const prevItem = {
                    file_name: itemPrev.name,
                    label: itemPrev.label,
                    issuance_date: formatter.toShortDate(itemPrev.issuance_date),
                    expiry_date: itemPrev.expiry_date ? formatter.toShortDate(itemPrev.expiry_date) : null,
                    main_category: prevCat ? prevCat.name : '',
                    sub_category: prevSubCat ? prevSubCat.cat_sub_name : '',
                    active: itemPrev.active
                  }

                  const currItem = {
                    file_name: values.fileName || item.name,
                    label: values.label,
                    issuance_date: formatter.toShortDate(values.issuance_date),
                    expiry_date: values.expiry_date ? formatter.toShortDate(values.expiry_date) : null,
                    main_category: cat ? cat.name : '',
                    sub_category: subcat ? subcat.cat_sub_name : '',
                    active: values.active
                  }

                  log.updateClientFile(clientId, prevItem, currItem, undefined, undefined, undefined, `${currItem.file_name}`)
                } else {
                  log.addClientFile(clientId, `New file added for ${clientInfo.first_name} ${clientInfo.last_name} with file name "${values.fileName}" and labelled with "${values.label}". ${values.is_target_enabled === true && bdgt ? `File is associated to plan "${formatter.toShortDate(bdgt.period_start_date)} - ${formatter.toShortDate(bdgt.period_end_date)}". ` : ''}${cat ? `Main Category as "${cat.name}", ` : ''}${subcat ? `Sub Category as "${subcat.cat_sub_name}".` : ''} ${values.issuance_date ? `Issuance Date set as "${formatter.toShortDate(values.issuance_date)}"` : ''}${values.expiry_date ? ` and Expiry Date set as "${formatter.toShortDate(values.expiry_date)}".` : '.'} File is ${values.active ? 'enabled': 'disabled'}.`)
                }
              }
              onUpdate(isEdit, r)
            }

            this.setState({ loading: false })
          } catch (e) {
            onUpdate(isEdit, e)
          }
        }
      } else if (!fileUploadedList.uid) {
        if (isEdit) {
          this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgInProgress })
        } else {
          this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgNoFile })
        }
      }
    })
  }

  handleTargetToggleChange = (e) => {
    const { form } = this.props
    if (e === false) {
      form.setFieldsValue({ target_id: undefined })
    }
  }

  handleMainCatChange = (id) => {
    const { form, subCategoriesList } = this.props
    const { setFieldsValue } = form
    const list = subCategoriesList.filter(e => e.id === id)
    // console.log('main cat list', list)
    this.setState({ currentSubCatList: list, currentSubCat: {} })
    setFieldsValue({ sub_cat_id: undefined })
  }

  handleSubCatChange = (id) => {
    const { currentSubCatList } = this.state
    const cat = currentSubCatList.find(e => e.cat_sub_id === id)
    // console.log('sub cat list', cat)
    if (cat) {
      this.setState({ currentSubCat: cat })
    }
  }

  handleIssuanceDateChange = async (value) => {
    this.setState({ spinLoading: true })
    const { form } = this.props
    const { setFieldsValue } = form
    const { currentSubCat } = this.state

    if (value) {
      if (currentSubCat.is_expiry && currentSubCat.expiry_value) {
        const expiryMonth = parseInt(currentSubCat.expiry_value)
        let expDate = formatter.toMomentClone(value)
        const expiryDate = expDate.add(expiryMonth, currentSubCat.expiry_unit || 'month')
        // console.log('issuance date change value', value, expiryDate)
        setFieldsValue({ expiry_date: expiryDate })
      }
    }

    this.setState({ spinLoading: false })
  }

  fileRemove = (file) => {
    const { fileList } = this.state
    const fileIndex = fileList.indexOf(file)

    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()
      this.setState({fileList: newFileList, fileUploadedList: {}})
    }
  }

  fileClear = () => {
    this.setState({fileList: [], fileUploadedList: {}})
  }

  fileChange = (info) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }
        this.setState({fileUploadedList: data, uploadErrorMsg: ''})
      }
    }
  }

  fileSet = (file) => {
    if (file && validator.isValidUploadType(file.type)) {
      this.setState({fileList: [file], uploadErrorMsg: ''})
      return true
    } else {
      this.setState({ uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatDOC })
      return false
    }

    // return false
  }

  render () {
    const { visible, categoriesList, subCategoriesList, clientId, form, onClose } = this.props
    const { budgetList, currentSubCatList, fileList, isEdit, item, loading, spinLoading, currentSubCat, uploadErrorMsg } = this.state
    const { getFieldValue, getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit Participant File' : 'Add Participant File'
    const isTargetEnabled = getFieldValue('is_target_enabled')

    return (
      <SideModal
        key={`sidebudget_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          this.fileClear()
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Main Category'>
                {getFieldDecorator('main_cat_id', {
                  initialValue: item.main_cat_id || '',
                  rules: [
                    { required: true, message: 'Please select main category' }
                  ]
                })(
                  <Select
                    placeholder='Please select main category'
                    showSearch
                    optionFilterProp='children'
                    onChange={(mainId) => this.handleMainCatChange(mainId)}
                    filterOption={(input, option) => this.findCatItems(input, option)}
                    disabled={isEdit}>
                    {
                      categoriesList.map((main) => (
                        <Option key={main.name} value={main.id}>{main.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem label='Sub Category'>
                {getFieldDecorator('sub_cat_id', {
                  initialValue: item.sub_cat_id || '',
                  rules: [
                    { required: true, message: 'Please select sub category' }
                  ]
                })(
                  <Select
                    placeholder='Please select sub category'
                    showSearch
                    optionFilterProp='children'
                    onChange={(subId) => this.handleSubCatChange(subId)}
                    filterOption={(input, option) => this.findCatItems(input, option)}
                    disabled={currentSubCatList.length < 1 || isEdit}>
                    {isEdit
                      ? subCategoriesList.map((sub) => (
                        <Option key={sub.cat_sub_name} value={sub.cat_sub_id}>{sub.cat_sub_name}</Option>
                      ))
                      : currentSubCatList.map((sub) => (
                        <Option key={sub.cat_sub_name} value={sub.cat_sub_id}>{sub.cat_sub_name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>

              <FormItem style={{margin: '0px'}} label='Associate to Plan?' />
              <Row>
                <Col lg={5}>
                  <FormItem>
                    {getFieldDecorator('is_target_enabled', {
                      initialValue: typeof item.is_target_enabled === 'boolean' ? item.is_target_enabled : false,
                      valuePropName: 'checked'
                    })(
                      <Switch
                        onChange={(e) => this.handleTargetToggleChange(e)}
                        disabled={budgetList.length < 1 || isEdit}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                      />
                    )}
                  </FormItem>
                </Col>
                <Col lg={19}>
                  <FormItem>
                    {getFieldDecorator('target_id', {
                      initialValue: item.target_id || null,
                      rules: [
                        { required: isTargetEnabled, message: 'Please select associated plan' }
                      ]
                    })(
                      <Select
                        showSearch
                        optionFilterProp='children'
                        filterOption={(input, option) => this.filterOptions(input, option)}
                        disabled={budgetList.length < 1 || isEdit || !isTargetEnabled}>
                          { budgetList.map((bdgt) => (
                              bdgt.id
                              ? <Option key={`tsco${bdgt.id}`} value={bdgt.id}>
                                {`${formatter.toShortDate(bdgt.period_start_date)} - ${formatter.toShortDate(bdgt.period_end_date)}`}
                              </Option>
                              : null
                          )) }
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <FormItem label='Issuance Date'>
                {getFieldDecorator('issuance_date', {
                  rules: [
                    { required: true, message: 'Please enter issuance date' }
                  ],
                  initialValue: item.issuance_date ? formatter.toMoment(item.issuance_date) : null
                })(
                  <DatePicker defaultPickerValue={moment(new Date())} onChange={(e) => this.handleIssuanceDateChange(e)} format={dateFormat} />
                )}
              </FormItem>

              {currentSubCat.is_expiry || item.expiry_date
                ? <FormItem label='Expiry Date'>
                  {getFieldDecorator('expiry_date', item.expiry_date ? {
                    rules: [
                      { required: true, message: 'Please enter expiry date' }
                    ],
                    initialValue: item.expiry_date ? formatter.toMoment(item.expiry_date) : null
                  } : {
                    rules: [
                      { required: true, message: 'Please enter expiry_date' }
                    ]
                  })(
                    <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} />
                  )}
                </FormItem>
                : null }

                <FormItem label='Label'>
                  {getFieldDecorator('label', {
                    initialValue: item.label,
                    rules: [
                      { required: true, message: 'Please enter label' },
                      { whitespace: true, message: 'Please enter label' }
                    ]
                  })(
                    <Input />
                  )}
                </FormItem>

                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: typeof item.active === 'boolean' ? item.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>

                <FileUpload
                  file={item.url}
                  fileName={item.name}
                  loading={spinLoading}
                  readOnly={false}
                  showError={!!uploadErrorMsg}
                  upload={{
                    method: 'POST',
                    action: `${apiHostname}/private/api/files/upload`,
                    data: {clientId: clientId},
                    name: 'file',
                    onRemove: this.fileRemove,
                    onChange: this.fileChange,
                    beforeUpload: this.fileSet,
                    headers: {Authorization: `Bearer ${auth.getCurrentToken()}` },
                    fileList: fileList,
                    multiple: false
                  }}
                />
                {/* <Upload
                  method={'POST'}
                  action={`${apiHostname}/private/api/files/upload`}
                  data= {{clientId: clientId}}
                  name={'file'}
                  onRemove={this.fileRemove}
                  onChange={this.fileChange}
                  beforeUpload={this.fileSet}
                  headers={{Authorization: `Bearer ${auth.getCurrentToken()}` }}
                  fileList={fileList}
                  multiple={false}
                >
                  <Button>
                    <Icon type="upload" /> Select File
                  </Button>
                </Upload> */}

                { uploadErrorMsg ? <div style={{color: 'red', fontSize: '13px', marginTop: '5px'}}>{uploadErrorMsg}</div> : null }
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileModal)
