import Permissions from '../permissions'

export default [
  {
    name: 'Provider List',
    desc: 'Manage all providers',
    icon: 'project',
    link: '/providers',
    enable: true,
    permission: Permissions.PROVIDER.INFO.LIST
  }
]
