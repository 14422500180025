import { clientService, clientCareplanService, employeeService, funderService, billingExportService, providerService, reportTypeService, settingContactsExportService, taskService } from '../services'
import formatter from './formatter'
import { ExportType, ReportType } from '../constants'

const fileDateFormat = 'YYYYMMDD'

export default {
  async fetchExport (type, data = {}, updateDate = new Date()) {
    const date = formatter.toDate(updateDate, fileDateFormat)
    let filename = ''
    let r = null

    if (type === 'client') {
      filename = `SC-Participant-${date}-${Date.now()}.csv`
      r = await clientService.exportClient()
    } else if (type === 'provider') {
      filename = `SC-Provider-${date}-${Date.now()}.csv`
      r = await providerService.exportProvider()
    } else if (type === 'task' && data) {
      filename = `SC-Task-Job-${date}-${Date.now()}.csv`
      r = await taskService.exportTask(data)
    } else if (type === 'funder') {
      filename = `SC-Funder-${date}-${Date.now()}.csv`
      r = await funderService.exportFunder()
    } else if (type === 'employee') {
      filename = `SC-SW-${date}-${Date.now()}.csv`
      r = await employeeService.exportEmployee()
    } else if (type === ExportType.TYPE_CLIENT_CAREPLAN) {
      filename = `CP-${date}-${Date.now()}.pdf`
      r = await clientCareplanService.export(data)
    } else if (type === ExportType.TYPE_BILLING_PRODA) {
      filename = `Claim-${data.period_code ? data.period_code : date}-${Date.now()}.zip`
      r = await billingExportService.getExportPost(data)
    } else if (type === ExportType.TYPE_BILLING_PAYROLL) {
      filename = `Payroll-${data.period_code ? data.period_code : date}-${Date.now()}.csv`
      r = await billingExportService.getExportPost(data)
    } else if (type === ExportType.TYPE_BILLING_INVOICE) {
      filename = `Invoice-${data.period_code ? data.period_code : date}-${Date.now()}.csv`
      r = await billingExportService.getExportPost(data)
    } else if (type === ExportType.TYPE_BILLING_HR_BALANCE) {
      filename = `HRBalance-${data.period_code ? data.period_code : date}-${Date.now()}.csv`
      r = await billingExportService.getExportPost(data)
    } else if (type === ExportType.TYPE_CONTACTS_GENERAL_SIMPLE && data) {
      filename = `SC-Contact-General-Simple-${date}-${Date.now()}.${data.fileType}`
      r = await settingContactsExportService.exportContact(type, data.fileType)
    }

    if (r) {
      if (data.is_open) {
        return openFile(r, filename)
      } else {
        return downloadFile(r, filename)
      }
    }
  },
  async fetchReportExport (type) {
    const date = formatter.toDate(new Date(), fileDateFormat)
    let filename = ''

    const r = await reportTypeService.exportReport(type)

    if (type === ReportType.SC_DAY_COUNT_SINCE_LAST_JOB) {
      filename = `SC-PPT-DayCountLastJob-${date}-${Date.now()}.csv`
    } else if (type === ReportType.SC_CLIENT_PLAN_DETAILS) {
      filename = `SC-PPT-PlanDetails-${date}-${Date.now()}.csv`
    } else if (type === ReportType.SC_CLIENT_PLAN_EXPIRY) {
      filename = `SC-PPT-PlanExpiry-${date}-${Date.now()}.zip`
    } else if (type === ReportType.SC_CLIENT_REPORT_DUE) {
      filename = `SC-PPT-PlanReportDue-${date}-${Date.now()}.zip`
    } else if (type === ReportType.SC_CLIENT_TASK_DUE) {
      filename = `SC-PPT-TaskDue-${date}-${Date.now()}.csv`
    }

    return downloadFile(r, filename)
  }
}

async function downloadFile (r, filename) {
  if (r) {
    const blob = await r.blob()
    if (blob.type === 'application/json') {
      try {
        const response = await blob.text()
        return JSON.parse(response)
      } catch (e) {
        return e
      }
    } else {
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename)
      } else {
        // const href = window.URL.createObjectURL(blob)
        const url = window.URL.createObjectURL(new Blob([blob]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        link.addEventListener('click', e => {
          setTimeout(() => URL.revokeObjectURL(url), 30000)
        })

        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      }
    }
  }
}

async function openFile (r, filename) {
  if (r) {
    const blob = await r.blob()
    if (blob.type === 'application/json') {
      try {
        const response = await blob.text()
        return JSON.parse(response)
      } catch (e) {
        return e
      }
    } else {
      const href = window.URL.createObjectURL(blob)
      // const url = window.URL.createObjectURL(new Blob([blob]))
      const tab = window.open(href, '_blank')

      const link = document.createElement('a')
      link.target = filename
      link.href = href
      link.download = filename
      link.addEventListener('click', e => {
        setTimeout(() => URL.revokeObjectURL(href), 30000)
      })

      document.body.appendChild(link)
      link.click()
      link.parentNode.removeChild(link)
    }
  }
}
