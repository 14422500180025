import React, { Component } from 'react'
import moment from 'moment-timezone'
import { clientProviderService, providerService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'
import { isEqual } from 'lodash'

import { Button, Loading, SideModal } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class AddProviderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allProviders: [],
      currentServices: [],
      isCurrentServiceUpdated: false,
      isDeactivatedTrigger: false,
      item: {},
      loadingProvider: false,
      loadingService: false,
      loadingUpdate: false,
      isEdit: false,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientId = '', item = {}, currentServices, loadingProvider, providerList, visible } = nextProps

    const isEdit = !!item.id
    // if item provider id is available, make the list only contains of current provider, else, filter out used provider
    const newProviderList = providerList.filter(e => item.provider_id ? item.provider_id === e.id : !e.is_disabled)

    return {
      ...prevState,
      allProviders: newProviderList,
      currentServices: prevState.isCurrentServiceUpdated ? prevState.currentServices : currentServices, // if services list is updated within the modal, ignore the change from outside
      loadingProvider,
      item,
      isEdit,
      visible
    }
  }

  fetchAllAvailableServices = async (pid) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.PROVIDER.LIST)) return

    const providerId = pid
    if (!providerId) return

    this.setState({ currentServices: [], loadingService: true })

    const r = await providerService.getAllProviderServices(providerId)

    if (validator.isArray(r)) {
      this.setState({ currentServices: r, loadingService: false, isCurrentServiceUpdated: true })
    } else {
      this.setState({ loadingService: false, isCurrentServiceUpdated: true })
    }
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleChangeActive = (e) => {
    const { form } = this.props
    const { item, isEdit } = this.state
    const prevActive = form.getFieldValue('active')

    if (item.active === true && prevActive === true && e === false && isEdit) {
      this.setState({ isDeactivatedTrigger: true })
    } else {
      this.setState({ isDeactivatedTrigger: false })
    }
  }

  handleChangeProvider = (id) => {
    this.fetchAllAvailableServices(id)
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant Provider saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Participant Provider successfully. Please try again later.')
    }
  }

  onClose = () => {
    const { form, onClose } = this.props
    const { resetFields } = form

    this.setState({ isCurrentServiceUpdated: false })
    resetFields()
    onClose()
  }

  onSubmit = async (e) => {
    const { clientId, form, onUpdate } = this.props
    const { validateFieldsAndScroll } = form
    const { allProviders, currentServices, isEdit, isDeactivatedTrigger, item, loadingUpdate } = this.state
    const { resetFields } = form

    if (loadingUpdate) return

    this.setState({ loadingUpdate: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          try {
            let r = null
            delete values.log_changes

            if (isEdit) {
              if (!isDeactivatedTrigger) {
                delete values.inactive_reason
              } else {
                values.is_inactive_reason = true
              }

              r = await clientProviderService.save(item.id, values)
            } else {
              values.client_id = clientId
              r = await clientProviderService.add(values)
            }

            if (r && r.id) {
              this.handleSaveSuccessful()

              const newProvider = allProviders.find(e => e.id === values.provider_id)
              const newProviderName = newProvider && newProvider.id ? newProvider.fullname : ''
              const newServices = currentServices.filter(e => values.services.findIndex(f => f === e.id) > -1)
              const newServicesName = newServices.map(e => e.name).join(', ')
              let newContacts = ''
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_first_name || ''}`
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_contact || ''}`
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_email || ''}`

              if (isEdit) {
                const isServiceChange = !isEqual(item.services, values.services)

                let inactiveReasonText = ''
                if (isDeactivatedTrigger) {
                  inactiveReasonText = values.inactive_reason
                }

                log.updateClientProvider(
                  clientId,
                  item,
                  values,
                  [
                    'client_id',
                    'created_at',
                    'cmsc_last_name',
                    'end_date',
                    'id',
                    'inactive_reason',
                    'log_action',
                    'log_changes',
                    'log_id',
                    'provider_fullname',
                    'provider_id',
                    'org_id',
                    'platform_id',
                    'sc_provider_services',
                    'services',
                    'services_name',
                    'updated_at'
                  ],
                  [
                    { key: 'cmsc_contact', label: 'Contact No' },
                    { key: 'cmsc_email', label: 'Contact Email' },
                    { key: 'cmsc_first_name', label: 'Contact Name' }
                  ],
                  `${isServiceChange ? `Services from "${item.services_name.join(', ')}" to "${newServicesName}". ` : ''}${inactiveReasonText ? `Inactive Reason: ${inactiveReasonText}. ` : ''}`,
                  `Provider "${newProviderName}" `
                )

              } else {
                let logText = `New Provider Added: ${newProviderName}, with the Services of ${newServicesName}, Start date ${formatter.toShortDate(values.start_date)}${newContacts ? `, Contacts: ${newContacts}` : ''}, Active set to ${values.active}${values.notes ? `, last with Notes "${values.notes}"` : ''}`

                log.addClientProvider(clientId, logText)
              }

              onUpdate()
              this.onClose()
            } else {
              if (r && r.errors) {
                this.handleSaveError(r)
              } else {
                this.handleSaveError()
              }
            }
          } catch (e) {
            console.log('provider save error', e)
            this.handleSaveError(e)
          }
        }

        this.setState({ loadingUpdate: false })
      })
    })
  }

  render () {
    const { visible, clientId, form, onClose } = this.props
    const { isEdit, isDeactivatedTrigger, item, loadingProvider, loadingService, loadingUpdate, allProviders, currentServices } = this.state
    const { getFieldDecorator, getFieldValue, resetFields } = form

    const title = isEdit ? 'Edit Provider' : 'Add Provider'

    return (
      <SideModal
        key={`sideprovider_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => this.onClose()}
        buttons={
          <Loading loading={loadingUpdate} blur key='btnr'>
            <Button key='pmrok' type='primary' onClick={(e) => this.onSubmit(e)}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        }
      >
        <Loading loading={loadingUpdate} blur>
          <Form layout='vertical' className='provider-form'>
            <FormItem label='Active'>
              {getFieldDecorator('active', {
                initialValue: isEdit ? item.active : false,
                valuePropName: 'checked'
              })(
                <Switch
                  onChange={(e) => this.handleChangeActive(e)}
                  checkedChildren='Yes'
                  unCheckedChildren='No'
                />
              )}
            </FormItem>

            { isDeactivatedTrigger
              ? <FormItem label='Inactive Reason'>
                {getFieldDecorator('inactive_reason', {
                  initialValue: null,
                  rules: [
                    { required: true, message: 'Please enter inactive reason' }
                  ]
                })(
                  <TextArea rows={2} />
                )}
              </FormItem>
              : item.log_id
                ? <div>
                  <FormItem style={{margin: '0px'}} label='Inactive Reason'>
                    {getFieldDecorator('log_changes', {
                      initialValue: item.log_changes,
                      rules: [
                        { required: true, message: 'Please enter inactive reason' }
                      ]
                    })(
                      <TextArea readOnly rows={2} />
                    )}
                  </FormItem>
                </div>
                : null }

            <FormItem label='Start Date'>
              {getFieldDecorator('start_date', {
                initialValue: item.start_date ? formatter.toMoment(item.start_date) : null,
                rules: [
                  { required: true, message: 'Please enter start date' }
                ]
              })(
                <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} />
              )}
            </FormItem>

            {/* <FormItem label='End Date'>
              {getFieldDecorator('end_date', {
                initialValue: item.end_date ? formatter.toMoment(item.end_date) : null,
                rules: [
                  { required: true, message: 'Please enter end date' }
                ]
              })(
                <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} />
              )}
            </FormItem> */}

            <Spin spinning={loadingProvider}>
              <FormItem label='Provider'>
                {getFieldDecorator('provider_id', {
                  initialValue: item.provider_id || null,
                  rules: [
                    { required: true, message: 'Please select provider' }
                  ]
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Select provider'
                    optionFilterProp='children'
                    notFoundContent='No available providers'
                    filterOption={(input, option) => this.filterOptions(input, option)}
                    disabled={isEdit || loadingProvider}
                    onChange={(id) => this.handleChangeProvider(id)}
                  >
                    { allProviders.map((item) => (
                        <Option key={`alpro${item.id}`} value={item.id}>
                          { item.fullname }
                        </Option>)) }
                  </Select>
                )}
              </FormItem>
            </Spin>

            { !isEdit && !loadingProvider
              ? <div style={{paddingBottom: '24px'}}>
                  <FormItem style={{margin: '0px', padding: '0px'}} label={`Can't find Provider in list?`} />
                  <div style={{width: '170px'}}>
                    <div className='btn' onClick={() => this.onClose()}>
                      <a href={`/providers/add`} rel='noopener noreferrer' target='_blank'>
                        Add New Provider
                      </a>
                    </div>
                  </div>
                </div>
              : null }

            <Spin spinning={loadingProvider || loadingService}>
              <FormItem label='Preferred Services'>
                {getFieldDecorator('services', {
                  initialValue: item.services || [],
                  rules: [
                    { required: true, message: 'Please select services' }
                  ]
                })(
                  <Select
                    showSearch
                    mode='multiple'
                    style={{ width: '100%' }}
                    placeholder='Select preferred services'
                    optionFilterProp='children'
                    notFoundContent='No available services'
                    filterOption={(input, option) => this.filterOptions(input, option)}
                    disabled={loadingProvider || loadingService || currentServices.length === 0}
                  >
                    { currentServices.map((item) => (
                        <Option key={`alcscv${item.id}`} value={item.id}>
                          { item.name }
                        </Option>)) }
                  </Select>
                )}
              </FormItem>
            </Spin>

            <div className='cmsc'>
              <FormItem label='Contact'>
                {getFieldDecorator('cmsc_first_name', {
                  initialValue: isEdit ? item.cmsc_first_name : null,
                  rules: [
                    { min: 1, message: 'Contact name must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact name must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Contact Name' }
                  ]
                })(
                  <Input placeholder='Name' />
                )}

              </FormItem>

              <FormItem>
                {getFieldDecorator('cmsc_contact', {
                  initialValue: isEdit ? item.cmsc_contact : null,
                  rules: [
                    { min: 1, message: 'Contact contact must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact contact must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Contact contact ' }
                  ]
                })(
                  <Input placeholder='Contact' />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('cmsc_email', {
                  initialValue: isEdit ? item.cmsc_email : null,
                  rules: [
                    { type: 'email', message: 'Please provide a valid Email' }
                  ]
                })(
                  <Input placeholder='Email' />
                )}
              </FormItem>
            </div>

            <FormItem label='Notes' style={{marginTop: '24px'}}>
              {getFieldDecorator('notes', {
                initialValue: item.notes || null
              })(
                <TextArea rows={4} />
              )}
            </FormItem>
          </Form>
        </Loading>
      </SideModal>
    )
  }

  hasAccess = (accessLevel) => {
    return auth.hasAccess(accessLevel)
  }
}

export default Form.create()(AddProviderModal)
