import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/custom-identifier'

export default {
  getCustomIdf (id) {
    return request(`${url}/${id}`)
  },
  addCustomIdf (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  saveCustomIdf (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  removeCustomIdf (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  listCustomIdfByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
}
