import { all, fork } from 'redux-saga/effects'

import watchClient from './client'
import watchEmployee from './employee'
import watchFunder from './funder'
import watchProvider from './provider'
import watchDashboard from './dashboard'
import watchPayroll from './payroll'

export default function * root () {
  yield all([
    fork(watchClient),
    fork(watchEmployee),
    fork(watchFunder),
    fork(watchDashboard),
    fork(watchPayroll),
    fork(watchProvider)
  ])
}
