import React, { Component } from 'react'
import { connect } from 'react-redux'
import { uploader, validator, formatter, log } from '../../util'
import { authService, customIdfService } from '../../services'
import { setRefreshActivityLog } from '../../states/actions'
import { cloneDeep } from 'lodash'

// UI
import { Loading, Panel } from '../../components'
import notify from '../Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input

const formItemLayout = {
  labelCol: { sm: 16, md: 16, lg: 16 },
  wrapperCol: { sm: 6, md: 6, lg: 6 }
}

export class CustomIdentifierList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      loadingUpdate: false,
      items: [],
      itemsOri: []
    }
  }

  componentDidMount () {
    this.fetchCustomValues()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.genreId !== this.props.genreId) {
      if (nextProps.genreId) {
        this.fetchCustomValues(nextProps.genreId)
      }
    }
  }

  render () {
    const { form, genre, genreId, permission } = this.props
    const { loading, loadingUpdate, items } = this.state
    const { getFieldDecorator, getFieldValue } = form

    return <div>
      <Loading loading={loadingUpdate} blur>
        <Panel
          title='Custom Identifier'
          subtitle={ validator.isNotEmptyArray(items) && permission && this.hasAccess(permission)
            ? <div className='btn' onClick={() => this.handleSave()}>Save Custom Identifier</div>
            : null
          }
        >
          <Skeleton loading={loading} active>
            <Form layout='horizontal'>
              { validator.isNotEmptyArray(items)
                ? items.map((itm, idx) => {
                const inputType = itm.input_type
                return (
                  <div key={`idff-${idx}`}>
                    <Row>
                      <Col span={1} />
                      <Col span={8}>
                        { inputType === 'text'
                          ? <FormItem {...formItemLayout} label={itm.label}>
                            {getFieldDecorator(`value${idx}`, {
                              initialValue: itm.value_char || null,
                              rules: [
                                { whitespace: true, message: `Please enter ${itm.label}` }
                              ]
                            })(
                              <Input />
                            )}
                          </FormItem>
                          : inputType === 'text-area'
                            ? <FormItem {...formItemLayout} label={itm.label}>
                              {getFieldDecorator(`value${idx}`, {
                                initialValue: itm.value_char || null,
                                rules: [
                                  { whitespace: true, message: `Please enter ${itm.label}` }
                                ]
                              })(
                                <TextArea rows={1} />
                              )}
                            </FormItem>
                            : inputType === 'toggle'
                              ? <FormItem {...formItemLayout} label={itm.label}>
                                {getFieldDecorator(`value${idx}`, {
                                  initialValue: itm.value_boolean || false,
                                  valuePropName: 'checked'
                                })(
                                  <Switch
                                    checkedChildren='Yes'
                                    unCheckedChildren='No'
                                  />
                                )}
                              </FormItem>
                              : null }
                      </Col>
                      <Col span={8}>
                        { itm.is_include_notes
                          ? <FormItem label=''>
                            {getFieldDecorator(`notes${idx}`, {
                              initialValue: itm.value_notes || null
                            })(
                              <TextArea style={{maxWidth: '500px'}} rows={1} placeholder={`Notes for ${itm.label}`} />
                            )}
                          </FormItem>
                          : null }
                      </Col>
                      <Col span={1} />
                    </Row>
                  </div>
                )})
              : <div>No Custom Identifer Available.</div> }
            </Form>
          </Skeleton>
        </Panel>
      </Loading>
    </div>
  }

  fetchCustomValues = async (rid = null) => {
    const { genre } = this.props

    this.setState({ loading: true })
    const genreId = rid || this.props.genreId
    if (!genreId) return

    const r = await customIdfService.get(genre, genreId)

    let items = []
    if (validator.isNotEmptyArray(r)) {
      items = r
    }

    this.setState({ items, itemsOri: cloneDeep(items), loading: false })
  }

  handleSave = () => {
    const { form, genre, genreId } = this.props
    const { items, itemsOri } = this.state
    const { validateFields } = form

    validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ loadingUpdate: true })

        try {
          for (let i = 0; i < items.length; i++) {
            if (items[i].input_type === 'toggle') {
              items[i].value_boolean = values[`value${i}`]
              items[i].value_char = null
            } else {
              items[i].value_boolean = null
              items[i].value_char = values[`value${i}`]
            }

            if (items[i].is_include_notes) {
              items[i].value_notes = values[`notes${i}`] || null
            } else {
              items[i].value_notes = null
            }
          }

          const r = await customIdfService.save(genre, genreId, { items })

          if (r && r.id) {
            let changes = ''
            let excluded = []
            for (let i = 0; i < itemsOri.length; i++) {
              const ori = itemsOri[i]
              const item = items[i]

              if (ori.is_log_allowed) {
                const c = log.generateItemChanges(
                  ori,
                  item,
                  undefined,
                  [
                    { key: 'value_boolean', label: item.label },
                    { key: 'value_char', label: item.label },
                    { key: 'value_notes', label: `Notes for ${item.label}` }
                  ]
                ) || ''

                changes += changes ? `, ${c}` : c
              }
            }

            if (changes) {
              log.updateCustomIdf(genre, genreId, changes)
            }

            this.handleSaveSuccessful()
          } else {
            this.handleSaveError()
          }
        } catch (e) {
          console.log('handle save e', e)
          this.handleSaveError()
        }

        this.setState({ loadingUpdate: false })
      }
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Custom Identifier saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save custom identifier successfully. Please try again later.')
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(CustomIdentifierList))
