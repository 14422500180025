import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/others'

export default {
  save (values) {
    return request(`${url}/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  list () {
    return request(`${url}/list`)
  }
}
