import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import App from './containers/App'
import ForgotPassword from './containers/ForgotPassword'
import Login from './containers/Login'
import reducers from './states/reducers'
import root from './states/sagas'
import authService from './services/auth'

import './index.css'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  reducers,
  composeEnhancer(applyMiddleware(sagaMiddleware))
)

sagaMiddleware.run(root)

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path='/forgot-password' component={ForgotPassword} />
        <Route path='/login' component={Login} />
        <Route path='/' render={() => (isLoggedIn() ? <App /> : <Redirect to='/login' />)} />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root')
)

function isLoggedIn () {
  return authService.isSignedIn()
}
