import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService, settingCustomIdfService } from '../../../services'
import { CustomIdfInputType, Permissions } from '../../../constants'
import { formatter, validator } from '../../../util'

// UI
import { List, Page, Pager } from '../../../components'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Popconfirm from 'antd/lib/popconfirm'
import Skeleton from 'antd/lib/skeleton'
import Tabs from 'antd/lib/tabs'

import './styles.css'
import AddIdfModal from './AddIdfModal'
import notify from '../../../components/Notification'

const pageSize = 20
const TabPane = Tabs.TabPane

const TabList = [
  { tabId: 1, title: 'Participant', moduleId: 1, moduleType: 'participant' },
  { tabId: 2, title: 'Support Coordinator', moduleId: 2, moduleType: 'employee' },
  { tabId: 3, title: 'Provider', moduleId: 3, moduleType: 'provider' },
  { tabId: 4, title: 'Job', moduleId: 4, moduleType: 'job' },
]

export class SettingCustomIdf extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    this.state = {
      currentPage: 1,
      currentTab: '1',
      currentTabItem: Object.assign({}, TabList[0]),
      filter: {},
      list: [],
      modalItem: {},
      loading: false,
      loadingUpdate: false,
      sort: {},
      searchText: '',
      showModal: false,
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchIdfList({ currentPage })
  }

  fetchIdfList = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.CUSTOM_IDF.LIST)) return

    const { currentTabItem } = this.state
    try {
      this.setState({ currentPage, loading: true })
      filter = {
        module_type: { condition: '=', value: currentTabItem.moduleType }
      }

      const settings = await settingCustomIdfService.listCustomIdfByPage(currentPage, pageSize, filter)
      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  changePage = (currentPage = 1) => {
    const { filter, searchText, sort } = this.state
    this.fetchIdfList({ loading: true, currentPage, filter, sort, searchText })
  }

  handleTabChange = (index) => {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({
      currentTab: index,
      currentTabItem: Object.assign({}, tab)
    }, () => {
      this.fetchIdfList({ currentPage: 1 })
    })
  }

  handleDelete = async (id) => {
    this.setState({ loading: true })

    try {
      const r = await settingCustomIdfService.removeCustomIdf(id)

      if (r && r.id) {
        notify.success('Deleted successfully', `Custom Identifier deleted successfully`)
      } else {
        notify.error('Unable to delete successfully', `Unable to delete custom identifier successfully.`)
      }
    } catch (e) {
      notify.error('Unable to delete successfully', `Unable to delete custom identifier successfully. Please try again later.`)
    }

    await this.fetchIdfList({ currentPage: 1 })

    this.setState({ loadingUpdate: false })

  }

  onIdfUpdate = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    this.toggleAddIdfModal(false, {},  () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `Custom Identifier ${action} successfully.`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} custom identifier successfully.`)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} custom identifier category successfully. Please try again later.`)
      }

      this.fetchIdfList({ currentPage: 1 })
    })
  }

  toggleAddIdfModal = (showModal = false, item = {}, cb = () => {}) => {
    this.setState({ showModal, modalItem: item }, () => cb())
  }

  render () {
    const { currentPage, currentTab, currentTabItem, list, loading, loadingUpdate, modalItem, showModal, total } = this.state

    const columns = [
      {
        title: 'Label',
        width: 10,
        key: 'label'
      },
      {
        title: 'Input Type',
        width: 9,
        render: ({ input_type: inputType }) => {
          const { name } = CustomIdfInputType.find(item => item.value === inputType)
          return (
            <div>{name}</div>
          )
        }
      },
      // {
      //   title: 'Include Export',
      //   width: 3,
      //   render: ({ is_include_export: includeExport }) => {
      //     return (
      //       <div style={{ fontSize: '11pt' }}>
      //         { includeExport
      //           ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //           : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }
      //       </div>
      //     )
      //   }
      // },
      // {
      //   title: 'Include Notes',
      //   width: 3,
      //   render: ({ is_include_notes: includeNotes }) => <div style={{ fontSize: '11pt' }}>
      //     { includeNotes
      //       ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //       : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }
      //     </div>
      // },
      // {
      //   title: 'Allow Log',
      //   width: 3,
      //   render: ({ is_log_allowed: allowLog }) => <div style={{ fontSize: '11pt' }}>
      //     { allowLog
      //       ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
      //       : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }
      //     </div>
      // },
      {
        title: 'Enable',
        width: 2,
        render: ({ active }) => <div style={{ fontSize: '11pt' }}>
          { active
            ? <Icon style={{ color: '#4fbc85' }} type='check-circle' theme='filled' />
            : <Icon style={{ color: '#bbb' }} type='check-circle' theme='filled' /> }
          </div>
      },
      {
        title: '',
        width: 1,
        render: (item) => {
          return (
            this.hasAccess(Permissions.SETTING.CUSTOM_IDF.UPDATE)
              ? <div style={{ cursor: 'pointer' }} onClick={() => this.toggleAddIdfModal(true, item)}>
                <Icon type='form' />
              </div>
              : null
          )
        }
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable: isEditable }) => this.hasAccess(Permissions.SETTING.CUSTOM_IDF.DELETE) && isEditable
        ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
        >
          <Icon type='delete' />
        </Popconfirm>
        : null
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={'Custom Identifier'} />

          <Tabs defaultActiveKey={currentTab} onChange={this.handleTabChange}>
            { TabList.map((t, index) => {
              return (
                <TabPane tab={t.title} key={String(index + 1)}>
                  <div>
                    <div className='setting-list'>
                      <Skeleton loading={loading} active>
                        <div className='header-action'>
                          { this.hasAccess(Permissions.SETTING.CUSTOM_IDF.CREATE)
                            ? <div className='btn' onClick={() => this.toggleAddIdfModal(true)}>
                              {`Add Custom Identifier`}
                            </div>
                            : null }
                        </div>

                        <List cols={columns} rows={list} />

                        <Pager
                          size={pageSize}
                          total={total}
                          totalText={`Total ${total} Identifier${total === 1 ? '' : 's'}`}
                          current={currentPage}
                          onChange={this.chanePage}
                          style={{ marginTop: '15px' }}
                        />
                      </Skeleton>
                    </div>
                  </div>
                </TabPane>
              )
            })}
          </Tabs>

        </Page.Content>

        <AddIdfModal
          visible={showModal}
          onClose={() => this.toggleAddIdfModal(false)}
          item={modalItem}
          onUpdate={(isEdit, r) => this.onIdfUpdate(isEdit, r)}
          moduleItem={currentTabItem}
        />

      </Page.Body>
    )
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingCustomIdf))
