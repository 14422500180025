import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
        ]
      },
      {
        subgroup: [
          {
            title: 'Participants',
            icon: 'usergroup-add',
            permission: [Permissions.PARTICIPANT.INFO.CREATE, Permissions.PARTICIPANT.INFO.LIST],
            menu: [
              {
                name: 'New Participant',
                link: '/participants/add',
                permission: Permissions.PARTICIPANT.INFO.CREATE
              },
              {
                name: 'List Participants',
                link: '/participants',
                permission: Permissions.PARTICIPANT.INFO.LIST
              },
            ],
          },
          {
            title: 'Providers',
            icon: 'project',
            permission: [Permissions.PROVIDER.INFO.CREATE, Permissions.PROVIDER.INFO.LIST],
            menu: [
              {
                name: 'New Provider',
                link: '/providers/add',
                permission: Permissions.PROVIDER.INFO.CREATE
              },
              {
                name: 'List Providers',
                link: '/providers',
                permission: Permissions.PROVIDER.INFO.LIST
              },
            ],
          }
        ]
      },
      {
        subgroup: [
          {
            title: 'Funders',
            icon: 'team',
            permission: [Permissions.FUNDER.INFO.CREATE, Permissions.FUNDER.INFO.LIST],
            menu: [
              {
                name: 'New Funder',
                link: '/funders/add',
                permission: Permissions.FUNDER.INFO.CREATE
              },
              {
                name: 'List Funders',
                link: '/funders',
                permission: Permissions.FUNDER.INFO.LIST
              },
            ],
          },
          {
            title: 'Support Coordinators',
            icon: 'user',
            permission: [Permissions.EMPLOYEE.INFO.CREATE, Permissions.EMPLOYEE.INFO.LIST],
            menu: [
              {
                name: 'List Support Coordinators',
                link: '/employees',
                permission: Permissions.EMPLOYEE.INFO.LIST
              },
            ],
          },
          {
            title: 'Billings',
            icon: 'dollar',
            permission: Permissions.BILLINGS.INFO.LIST,
            menu: [
              {
                name: 'List Billings',
                link: '/billings',
                permission: Permissions.BILLINGS.INFO.LIST
              },
            ],
          }
        ]
      },
    ]
  },
  {
    title: 'Section 2',
    groups: [
      {
        title: 'Reports',
        icon: 'bar-chart',
        permission: [
          Permissions.REPORT.DAY_COUNT_LAST_JOB.LIST,
          Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST,
          Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST,
          Permissions.REPORT.CLIENT_REPORT_DUE.LIST,
          Permissions.REPORT.TASK_DUE.LIST,
          Permissions.REPORT.SCHEDULER.LIST,
          Permissions.REPORT.RECIPIENT_SETTING.LIST
        ],
        menu: [
          {
            name: 'Day Counts Since Last Job',
            link: '/reports/ppt-day-counts',
            permission: Permissions.REPORT.DAY_COUNT_LAST_JOB.LIST
          },
          {
            name: 'Participants\'s Plan Details',
            link: '/reports/ppt-plan-details',
            permission: Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST
          },
          {
            name: 'Participants\' Plan Expiry',
            link: '/reports/ppt-plan-expiry',
            permission: Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST
          },
          {
            name: 'Participants\' Reports Due',
            link: '/reports/ppt-report-due',
            permission: Permissions.REPORT.CLIENT_REPORT_DUE.LIST
          },
          {
            name: 'Tasks Due',
            link: '/reports/task-due',
            permission: Permissions.REPORT.TASK_DUE.LIST
          },
          {
            name: 'Report Generate Request',
            link: '/reports/custom-generate',
            permission: Permissions.REPORT.SCHEDULER.LIST
          },
          {
            name: 'Report Recipient Setting',
            link: '/reports/setting',
            permission: Permissions.REPORT.RECIPIENT_SETTING.LIST
          },
        ],
      },
      {
        title: 'Settings',
        icon: 'setting',
        permission: [
          Permissions.SETTING.CONTACTS_EXPORT.LIST,
          Permissions.SETTING.CUSTOM_IDF.LIST,
          Permissions.SETTING.FILES.LIST,
          Permissions.SETTING.PROFILE.LIST,
          Permissions.SETTING.RATESETS.LIST,
          Permissions.SETTING.REASON.LIST
        ],
        menu: [
          {
            name: 'Contacts',
            link: '/settings/contacts',
            permission: Permissions.SETTING.CONTACTS_EXPORT.LIST
          },
          {
            name: 'Custom Identifier',
            link: '/settings/custom-identifier',
            permission: Permissions.SETTING.CUSTOM_IDF.LIST
          },
          {
            name: 'File',
            link: '/settings/files',
            permission: Permissions.SETTING.FILES.LIST
          },
          {
            name: 'Profile',
            link: '/settings/profile',
            permission: [
              Permissions.SETTING.PLANGUAGE.LIST,
              Permissions.SETTING.PRELATIONSHIP.LIST
            ]
          },
          {
            name: 'Rate Set & Categories',
            link: '/settings/rate-set',
            permission: Permissions.SETTING.RATESETS.LIST
          },
          {
            name: 'Reasons',
            link: '/settings/reasons',
            permission: Permissions.SETTING.REASON.LIST
          },
          {
            name: 'Services',
            link: '/settings/services',
            permission: Permissions.SETTING.SERVICES.LIST
          },
          {
            name: 'Tasks',
            link: '/settings/tasks',
            permission: Permissions.SETTING.TASKSTATUS.LIST
          },
          {
            name: 'Others',
            link: '/settings/others',
            permission: Permissions.SETTING.OTHERS.LIST
          },
        ],
      }
    ]
  }
]
