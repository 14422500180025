import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { auth, common, formatter, queryString, validator } from '../../../util'
import { authService, billingService } from '../../../services'
import { Permissions } from '../../../constants'

// UI
import { ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

const pageSize = 40
const Option = Select.Option

class BillingListPeriod extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      isUpdateFilter: false,
      isInitialised: false,
      list: [],
      loading: false,
      loadingList: false,
      searching: false,
      searchText: '',
      sort: {},
      total: 0
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location } = nextProps

    const { page } = common.getQueryStringSearchParams(location.search)

    const state = {
      ...prevState,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage
    }

    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchListPeriod({ isInitialised: false, currentPage, filter, loading, searchText, sort })
  }

  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingList, searching, total, title } = this.state

    const columns = [
      {
        title: 'Billings Period',
        width: 4,
        render: ({ period_date_from, period_date_to }) => <div>{`${formatter.toShortDate(period_date_from)} - ${formatter.toShortDate(period_date_to)}`}</div>
      },
      {
        title: 'Participants',
        width: 1,
        render: ({ sum_client }) => <div>{sum_client}</div>
      },
      {
        title: 'Support Coordinators',
        width: 1,
        render: ({ sum_employee }) => <div>{sum_employee}</div>
      },
      {
        title: 'Jobs',
        width: 1,
        render: ({ sum_job }) => <div>{sum_job}</div>
      },
      {
        title: 'Hours',
        width: 1,
        render: ({ sum_job_duration_hrs }) => <div>{formatter.toFloatDecimal(sum_job_duration_hrs)}</div>
      },
      {
        title: 'To Claim',
        width: 1,
        render: ({ sum_to_claim }) => <div>{sum_to_claim}</div>
      },
      {
        title: 'To Receive',
        width: 1,
        render: ({ sum_to_receive }) => <div>{sum_to_receive}</div>
      },
      {
        title: 'To Pay',
        width: 2,
        render: ({ sum_to_payroll }) => <div>{sum_to_payroll}</div>
      },
      {
        title: 'Amt Subtotal',
        width: 2,
        render: ({ sum_subtotal }) => <div>{formatter.toPrice(sum_subtotal ? sum_subtotal : 0)}</div>
      },
      {
        title: 'Rcv Subtotal',
        width: 2,
        render: ({ sum_received_subtotal }) => <div>{formatter.toPrice(sum_received_subtotal ? sum_received_subtotal : 0)}</div>
      },
      {
        title: '',
        width: 6,
        render: ({ period_code }) => <div className='list-action-buttons'>
          { this.hasAccess(Permissions.BILLINGS.CLAIM.LIST)
            ? <Link to={`/billings/manage/claim/${period_code}`} key={`claim${period_code}`}>
              <div className='action-button'>
                <Icon type='eye' />&nbsp;Claim
              </div>
            </Link>
            : null }
          { this.hasAccess(Permissions.BILLINGS.PAYROLL.LIST)
            ? <Link to={`/billings/manage/payroll/${period_code}`} key={`proll${period_code}`}>
              <div className='action-button'>
                <Icon type='eye' />&nbsp;Payroll
              </div>
            </Link>
            : null }
          { this.hasAccess(Permissions.BILLINGS.EXPORT.LIST)
            ? <Link to={`/billings/redownload/${period_code}`} key={`rdl${period_code}`}>
              <div className='action-button'>
                <Icon type='download' />&nbsp;Re-download
              </div>
            </Link>
            : null }
        </div>
      },
    ]

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={'Billings'} />

          <div className='billing-summary-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <List cols={columns} rows={list} />
              </Spin>
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} Period${total === 1 ? '' : 's'}`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchListPeriod({ currentPage, filter, searchText, sort })
  }

  fetchListPeriod = async ({ isInitialised = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.BILLINGS.INFO.LIST)) return

    try {
      const { fetchStatements } = this.props
      this.setState({
        currentPage,
        loading: isInitialised ? false : true,
        loadingList: isInitialised ? true : false
      })

      const r = await billingService.listByPage(currentPage, pageSize, filter, sort, searchText)

      if (r && validator.isArray(r.list)) {
        this.setState({
          loading: false,
          loadingList: false,
          list: r.list || [],
          total: r.total || 0
        })
      } else {
        this.handleLoadError(r)
      }
    } catch (e) {
      this.handleLoadError(e)
    }
  }

  handleLoadError = (r) => {
    notify.error('Unable to load successfully', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to load billing list successfully. Please try again later.')
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingListPeriod)
