import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService, settingFileService } from '../../../services'
import { formatter, validator } from '../../../util'

// UI
import { Button, Page, Panel, SideModal, Pager, List } from '../../../components'
import { Permissions } from '../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'
import Tabs from 'antd/lib/tabs'
import notify from '../../../components/Notification'

import './styles.css'
import AddCatModal from './AddCatModal'
import AddCatSubModal from './AddSubModal'

// import AccRef from './AccRef'
// import Language from './Language'
// import Relationship from './Relationship'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Files'

const TabList = [
  { tabId: 1, title: 'Participant', moduleId: 1, moduleType: 'participant' },
  { tabId: 2, title: 'Support Coordinator', moduleId: 2, moduleType: 'employee' },
  { tabId: 3, title: 'Provider', moduleId: 3, moduleType: 'provider' },
  { tabId: 4, title: 'Task', moduleId: 4, moduleType: 'task' },
  { tabId: 5, title: 'Job', moduleId: 5, moduleType: 'task-job' },
]

const CatTabList = [
  { tabId: 1, title: 'Categories', code: 'cat' },
  { tabId: 2, title: 'Sub-Categories', code: 'sub' }
]

export class SettingFile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentCatPage: 1,
      currentCatSubPage: 1,
      currentModuleTab: '1',
      currentModuleTabItem: Object.assign({}, TabList[0]),
      currentCatTab: '1',
      currentCatTabItem: Object.assign({}, CatTabList[0]),
      catList: { list: [], total: 0 },
      subCatList: { list: [], total: 0 },
      catModalItem: {},
      catSubModalItem: {},
      loading: false,
      loadingUpdate: false,
      showCatModal: false,
      showCatSubModal: false
    }
  }

  componentDidMount () {
    this.fetchCatList()
    // const tab = (new URLSearchParams(window.location.search)).get("t")
    // this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  fetchCatList = async (currentCatPage = 1) => {
    if (!this.hasAccess(Permissions.SETTING.FILES.LIST)) return

    const { currentModuleTabItem } = this.state
    let filter = {}
    this.setState({ loading: true })
    filter.module_id = { condition: '=', value: currentModuleTabItem.moduleId }
    filter.module_type = { condition: '=', value: currentModuleTabItem.moduleType }

    const r = await settingFileService.listFileCatByPage(currentCatPage, pageSize, filter)
    if (r && r.list) {
      this.setState({
        loading: false,
        catList: r,
        currentCatPage
      })
    } else {
      this.setState({ loading: false })
    }
  }

  fetchCatSubList = async (currentCatSubPage = 1) => {
    const { currentModuleTabItem } = this.state
    let filter = {}
    this.setState({ loading: true })
    filter.module_id = { condition: '=', value: currentModuleTabItem.moduleId }
    filter.module_type = { condition: '=', value: currentModuleTabItem.moduleType }
    filter.cat_sub_id = { condition: 'is not null' }

    const r = await settingFileService.listFileCatSubByPage(currentCatSubPage, pageSize, filter)
    if (r && r.list) {
      this.setState({
        loading: false,
        subCatList: r,
        currentCatSubPage
      })
    } else {
      this.setState({ loading: false })
    }
  }

  // hideModal = () => {
  //   const { form } = this.props
  //   const { modal } = this.state
  //   const { resetFields } = form
  //   resetFields()
  //   modal.item = { link: {} }
  //   modal.show = false
  //   this.setState({ modal, selectedItem: {} })
  // }

  // showModal = () => {
  //   const { modal } = this.state
  //   modal.show = true
  //   this.setState({ modal })
  // }

  // addLanguage = () => {
  //   this.setState({ addLanguage: true })
  // }

  // addRelationship = () => {
  //   this.setState({ addRelationship: true })
  // }

  // onTabChange = (key) => {
  //   this.setState({ activeTab: key })
  // }

  toggleAddCatModal = (showCatModal = false, item = {}, cb = () => {}) => {
    this.setState({ showCatModal, catModalItem: item }, () => cb())
  }

  toggleAddCatSubModal = (showCatSubModal = false, item = {}, cb = () => {}) => {
    this.setState({ showCatSubModal, catSubModalItem: item }, () => cb())
  }

  onModuleTabChange = (key) => {
    const tab = TabList.find(e => String(e.tabId) === key) || {}
    this.setState({ currentModuleTab: key, currentModuleTabItem: tab }, () => {
      const { currentCatTabItem } = this.state
      if (currentCatTabItem.code === 'cat') {
        this.fetchCatList()
      } else if (currentCatTabItem.code === 'sub') {
        this.fetchCatSubList()
      }
    })
  }

  onCatTabChange = (key) => {
    const tab = CatTabList.find(e => String(e.tabId) === key) || {}
    this.setState({ currentCatTab: key, currentCatTabItem: tab }, () => {
      const { currentCatTabItem } = this.state
      if (currentCatTabItem.code === 'cat') {
        this.fetchCatList()
      } else if (currentCatTabItem.code === 'sub') {
        this.fetchCatSubList()
      }
    })
  }

  handleCatDelete = async (id) => {
    const { currentCatPage } = this.state
    const action = 'deleted'
    const action2 = 'delete'

    this.setState({ loading: true })

    try {
      const r = await settingFileService.removeFileCat(id)

      if (r && r.id) {
        notify.success(`${formatter.capitalize(action)} Successfully`, `File Category ${action} successfully.`)
      } else {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file category successfully.`)
      }
    } catch (e) {
      notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file category successfully. Please try again later.`)
    }

    await this.fetchCatList(currentCatPage)

    this.setState({ loading: false })
  }

  handleCatPageChange = (currentPage) => {
    this.fetchCatList(currentPage)
  }

  handleCatSubDelete = async (id) => {
    const { currentCatSubPage } = this.state
    const action = 'deleted'
    const action2 = 'delete'

    this.setState({ loading: true })
    const r = await settingFileService.removeFileCatSub(id)

    if (r && r.id) {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `File Sub-Category ${action} successfully.`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file sub-category successfully.`)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file sub-category successfully. Please try again later.`)
      }
    }

    await this.fetchCatSubList(currentCatSubPage)

    this.setState({ loading: false })
  }

  handleCatSubPageChange = (currentPage) => {
    this.fetchCatSubList(currentPage)
  }

  onCatUpdate = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    this.toggleAddCatModal(false, {},  () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `File Category ${action} successfully.`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file category successfully.`)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file category successfully. Please try again later.`)
      }

      this.fetchCatList()
    })
  }

  onCatSubUpdate = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'
    this.toggleAddCatSubModal(false, {},  () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `File Sub-Category ${action} successfully.`)
        } else {
          notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file sub-category successfully.`)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} file sub-category successfully. Please try again later.`)
      }

      this.fetchCatSubList()
    })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { currentCatTab, currentCatTabItem, currentModuleTab, currentModuleTabItem, currentCatPage, currentCatSubPage, catModalItem, catSubModalItem, loading, catList, subCatList, showCatModal, showCatSubModal } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columnsCat = [
      {
        title: 'Name',
        width: 9,
        key: 'name'
      },
      {
        title: 'Description',
        width: 12,
        key: 'description'
      },
      {
        title: 'Enable',
        width: 1,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => <div className='action-buttons '>
          { item && item.is_editable && this.hasAccess(Permissions.SETTING.FILES.UPDATE)
            ? <div style={{ cursor: 'pointer' }} onClick={() => this.toggleAddCatModal(true, item)}>
              <Icon type='form' />
            </div>
            : null }
          { item && item.is_editable && this.hasAccess(Permissions.SETTING.FILES.DELETE)
            ? <Popconfirm
              title={`Confirm to delete this category? All sub categories under this category will be deleted as well.`}
              onConfirm={(e) => this.handleCatDelete(item.id)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' />
            </Popconfirm>
            : null }

        </div>
      }
    ]

    const columnsSub = [
      {
        title: 'Category',
        width: 6,
        key: 'cat_name'
      },
      {
        title: 'Sub Category',
        width: 10,
        key: 'cat_sub_name'
      },
      {
        title: 'Mandatory',
        width: 1,
        render: ({ is_mandatory }) => <div style={{ color: is_mandatory ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Expiry',
        width: 1,
        render: ({ is_mandatory }) => <div style={{ color: is_mandatory ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: 'Expiry Months',
        width: 2,
        render: ({ expiry_value }) => <div>{ expiry_value || '' }</div>
      },
      {
        title: 'Enable',
        width: 1,
        render: ({ cat_sub_active }) => <div style={{ color: cat_sub_active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => <div className='action-buttons '>
          { item && item.cat_sub_is_editable && this.hasAccess(Permissions.SETTING.FILES.UPDATE)
            ? <div style={{ cursor: 'pointer' }} onClick={() => this.toggleAddCatSubModal(true, item)}>
              <Icon type='form' />
            </div>
            : null }
          { item && item.cat_sub_is_editable && this.hasAccess(Permissions.SETTING.FILES.DELETE)
            ? <Popconfirm
              title='Confirm to delete this sub category?'
              onConfirm={(e) => this.handleCatSubDelete(item.id)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' />
            </Popconfirm>
            : null }
        </div>
      }
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs activeKey={currentModuleTab} onChange={this.onModuleTabChange}>
            { TabList.map(t1 => {
              return (
                <TabPane tab={t1.title} key={String(t1.tabId)}>
                  <Tabs activeKey={currentCatTab} onChange={this.onCatTabChange}>
                    <TabPane tab={CatTabList[0].title} key={String(CatTabList[0].tabId)}>
                      <div>
                        <Page.Header title={`${currentModuleTabItem.title} - ${currentCatTabItem.title}`}>
                          { this.hasAccess(Permissions.SETTING.FILES.CREATE)
                            ? <div className='btn' onClick={() => this.toggleAddCatModal(true)}>
                              {`Add ${CatTabList[0].title}`}
                            </div>
                            : null }
                        </Page.Header>
                        <div className='setting-list'>
                          <Skeleton loading={loading} active>
                            <List cols={columnsCat} rows={catList.list} />

                            <Pager
                              size={pageSize}
                              total={catList.total}
                              totalText={`Total ${catList.total} Categor${catList.total === 1 ? 'y' : 'ies'}`}
                              current={currentCatPage}
                              onChange={this.handleCatPageChange}
                              style={{ marginTop: '15px' }}
                            />
                          </Skeleton>
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tab={CatTabList[1].title} key={String(CatTabList[1].tabId)}>
                      <div>
                        <Page.Header title={`${currentModuleTabItem.title} - ${currentCatTabItem.title}`}>
                          { <div className='btn' onClick={() => this.toggleAddCatSubModal(true)}>
                              {`Add ${CatTabList[1].title}`}
                            </div> }
                        </Page.Header>
                        <div className='setting-list'>
                          <Skeleton loading={loading} active>
                            <List cols={columnsSub} rows={subCatList.list} />

                            <Pager
                              size={pageSize}
                              total={subCatList.total}
                              totalText={`Total ${subCatList.total} Sub Categor${subCatList.total === 1 ? 'y' : 'ies'}`}
                              current={currentCatSubPage}
                              onChange={this.handleCatSubPageChange}
                              style={{ marginTop: '15px' }}
                            />
                          </Skeleton>
                        </div>
                      </div>
                    </TabPane>
                  </Tabs>
                </TabPane>
              )
            })}
          </Tabs>

          <AddCatModal
            visible={showCatModal}
            onClose={() => this.toggleAddCatModal(false)}
            item={catModalItem}
            onUpdate={(isEdit, r) => this.onCatUpdate(isEdit, r)}
            moduleItem={currentModuleTabItem}
          />

          <AddCatSubModal
            visible={showCatSubModal}
            onClose={() => this.toggleAddCatSubModal(false)}
            item={catSubModalItem}
            onUpdate={(isEdit, r) => this.onCatSubUpdate(isEdit, r)}
            moduleItem={currentModuleTabItem}
            categories={catList.list}
          />

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingFile))
