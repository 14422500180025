import { request, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/settings/recipients'

export default {
  getAllRecipients () {
    return request(`${url}/list/all`)
  },
  updateAllRecipients (values) {
    return request(`${url}/update`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
