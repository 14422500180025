import { REFRESH_ACTIVITY_LOG, REFRESH_COMM_LOG } from '../actions/general'

const INITIAL_STATE = {
  shouldRefreshActivityLog: false,
  shouldRefreshCommLog: false
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_ACTIVITY_LOG:
      return { ...state, shouldRefreshActivityLog: action.done }
    case REFRESH_COMM_LOG:
      return { ...state, shouldRefreshCommLog: action.done }
    default:
      return state
  }
}
