export default {
  PARTICIPANT: {
    INFO: {
      LIST: 'listParticipant',
      READ: 'readParticipant',
      CREATE: 'createParticipant',
      UPDATE: 'updateParticipant',
      DELETE: 'deleteParticipant'
    },
    CAREPLAN: {
      LIST: 'listParticipantCarePlan',
      READ: 'readParticipantCarePlan',
      CREATE: 'createParticipantCarePlan',
      UPDATE: 'updateParticipantCarePlan',
    },
    FUNDINGPLAN: {
      LIST: 'listParticipantFundingPlan',
      READ: 'readParticipantFundingPlan',
      CREATE: 'createParticipantFundingPlan',
      UPDATE: 'updateParticipantFundingPlan',
      DELETE: 'deleteParticipantFundingPlan'
    },
    PROVIDER: {
      LIST: 'listParticipantProviders',
      READ: 'readParticipantProviders',
      CREATE: 'createParticipantProviders',
      UPDATE: 'updateParticipantProviders',
      DELETE: 'deleteParticipantProviders'
    },
    FILES: {
      LIST: 'listParticipantFiles',
      READ: 'readParticipantFiles',
      CREATE: 'createParticipantFiles',
      UPDATE: 'updateParticipantFiles',
      DELETE: 'deleteParticipantFiles'
    },
    TASKS: {
      LIST: 'listParticipantTasks',
      READ: 'readParticipantTasks',
      CREATE: 'createParticipantTasks',
      UPDATE: 'updateParticipantTasks',
      DELETE: 'deleteParticipantTasks'
    }
  },
  EMPLOYEE: {
    INFO: {
      LIST: 'listEmployee',
      READ: 'readEmployee',
      CREATE: 'createEmployee',
      UPDATE: 'updateEmployee',
      DELETE: 'deleteEmployee'
    },
  },
  FUNDER: {
    INFO: {
      LIST: 'listFunder',
      READ: 'readFunder',
      CREATE: 'createFunder',
      UPDATE: 'updateFunder',
      DELETE: 'deleteFunder'
    },
  },
  PROVIDER: {
    INFO: {
      LIST: 'listProvider',
      READ: 'readProvider',
      CREATE: 'createProvider',
      UPDATE: 'updateProvider',
      DELETE: 'deleteProvider'
    },
    FILES: {
      LIST: 'listProviderFiles',
      READ: 'readProviderFiles',
      CREATE: 'createProviderFiles',
      UPDATE: 'updateProviderFiles',
      DELETE: 'deleteProviderFiles'
    }
  },
  BILLINGS: {
    INFO: {
      LIST: 'listBillings',
      READ: 'readBillings',
      UPDATE: 'updateBillings',
      EMAIL: 'emailBillings',
      DOWNLOAD: 'downloadBillings',
    },
    CLAIM: {
      LIST: 'listBillingsClaim',
      READ: 'readBillingsClaim',
      UPDATE: 'updateBillingsClaim',
      DOWNLOAD: 'downloadBillingsClaim',
    },
    PAYROLL: {
      LIST: 'listBillingsPayroll',
      READ: 'readBillingsPayroll',
      UPDATE: 'updateBillingsPayroll',
      DOWNLOAD: 'downloadBillingsPayroll',
    },
    EXPORT: {
      LIST: 'listBillingsExport',
      UPDATE: 'updateBillingsExport',
      DOWNLOAD: 'downloadBillingsExport',
    },
  },
  TASKJOB: {
    INFO: {
      LIST: 'listTaskJob',
      READ: 'readTaskJob',
      CREATE: 'createTaskJob',
      UPDATE: 'updateTaskJob',
      DELETE: 'deleteTaskJob'
    },
    FILES: {
      LIST: 'listTaskJobFiles',
      READ: 'readTaskJobFiles',
      CREATE: 'createTaskJobFiles',
      UPDATE: 'updateTaskJobFiles',
      DELETE: 'deleteTaskJobFiles'
    },
  },
  // CREDIT: {
  //   INFO: {
  //     LIST: 'listCredit',
  //     READ: 'readCredit',
  //     CREATE: 'createCredit',
  //     UPDATE: 'updateCredit',
  //     DELETE: 'deleteCredit'
  //   }
  // },
  REPORT: {
    DAY_COUNT_LAST_JOB: {
      LIST: 'listReportDayCountLastJob',
    },
    CLIENT_PLAN_DETAILS: {
      LIST: 'listReportParticipantPlanDetails',
    },
    CLIENT_PLAN_EXPIRY: {
      LIST: 'listReportParticipantPlanExpiry',
    },
    CLIENT_REPORT_DUE: {
      LIST: 'listReportParticipantReportDue',
    },
    TASK_DUE: {
      LIST: 'listReportTaskDue',
    },
    SCHEDULER: {
      LIST: 'listReportGenerateRequest',
      READ: 'readReportGenerateRequest',
      CREATE: 'createReportGenerateRequest',
    },
    RECIPIENT_SETTING: {
      LIST: 'listReportRecipientSetting',
      UPDATE: 'updateReportRecipientSetting',
    },
  },
  SETTING: {
    CONTACTS_EXPORT: {
      LIST: 'listSettingContacts',
      // READ: 'readSettingContacts',
      // CREATE: 'createSettingContacts',
      // UPDATE: 'updateSettingContacts',
      // DELETE: 'deleteSettingContacts'
    },
    CUSTOM_IDF: {
      LIST: 'listSettingCustomIdentifier',
      READ: 'readSettingCustomIdentifier',
      CREATE: 'createSettingCustomIdentifier',
      UPDATE: 'updateSettingCustomIdentifier',
      DELETE: 'deleteSettingCustomIdentifier'
    },
    FILES: {
      LIST: 'listSettingFiles',
      READ: 'readSettingFiles',
      CREATE: 'createSettingFiles',
      UPDATE: 'updateSettingFiles',
      DELETE: 'deleteSettingFiles'
    },
    PROFILE: {
      LIST: 'listSettingProfile',
      READ: 'readSettingProfile',
      CREATE: 'createSettingProfile',
      UPDATE: 'updateSettingProfile',
      DELETE: 'deleteSettingProfile'
    },
    PLANGUAGE: {
      LIST: 'listSettingProfileLanguage',
      READ: 'readSettingProfileLanguage',
      CREATE: 'createSettingProfileLanguage',
      UPDATE: 'updateSettingProfileLanguage',
      DELETE: 'deleteSettingProfileLanguage'
    },
    PRELATIONSHIP: {
      LIST: 'listSettingProfileRelationship',
      READ: 'readSettingProfileRelationship',
      CREATE: 'createSettingProfileRelationship',
      UPDATE: 'updateSettingProfileRelationship',
      DELETE: 'deleteSettingProfileRelationship'
    },
    PVISA: {
      LIST: 'listSettingProfileVisaStatus',
      READ: 'readSettingProfileVisaStatus',
      CREATE: 'createSettingProfileVisaStatus',
      UPDATE: 'updateSettingProfileVisaStatus',
      DELETE: 'deleteSettingProfileVisaStatus'
    },
    REASON: {
      LIST: 'listSettingReasons',
      READ: 'readSettingReasons',
      CREATE: 'createSettingReasons',
      UPDATE: 'updateSettingReasons',
      DELETE: 'deleteSettingReasons'
    },
    RATESETS: {
      LIST: 'listSettingRateSet',
      READ: 'readSettingRateSet',
      CREATE: 'createSettingRateSet',
      UPDATE: 'updateSettingRateSet',
      DELETE: 'deleteSettingRateSet'
    },
    CATS: {
      LIST: 'listSettingCategories',
      READ: 'readSettingCategories',
      CREATE: 'createSettingCategories',
      UPDATE: 'updateSettingCategories',
      DELETE: 'deleteSettingCategories'
    },
    SERVICES: {
      LIST: 'listSettingServices',
      READ: 'readSettingServices',
      CREATE: 'createSettingServices',
      UPDATE: 'updateSettingServices',
      DELETE: 'deleteSettingServices'
    },
    TASKSTATUS: {
      LIST: 'listSettingTaskStatus',
      READ: 'readSettingTaskStatus',
      CREATE: 'createSettingTaskStatus',
      UPDATE: 'updateSettingTaskStatus',
      DELETE: 'deleteSettingTaskStatus'
    },
    OTHERS: {
      LIST: 'listSettingOther',
      UPDATE: 'updateSettingOther',
    }
  }
}
