import React, { Component } from 'react'
import moment from 'moment-timezone'
import { authService, billingUpdateService } from '../../../services'
import { formatter, log, validator } from '../../../util'
import { cloneDeep } from 'lodash'

import { Button, FileUpload, Loading, SideModal } from '../../../components'
import { BillingListType, BillingMarginStateType, BillingUpdateType } from '../../../constants'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../config'

import notify from '../../../components/Notification'

import './styles.css'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

export class StatusUpdateModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      actionType: null,
      jobList: [],
      listType: null,
      loading: false,
      loadingUpdate: false,
      period: {},
      totalRcvAmt: 0,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { jobList = [], listType, actionType = null, period, visible } = nextProps

    if (visible === true && prevState.visible === false) {
      let subtotal = 0
      for (let i = 0; i < jobList.length; i++) {
        const inv = jobList[i]
        subtotal += inv.received_subtotal
      }

      return { ...prevState, jobList, listType, actionType, period, visible, totalRcvAmt: subtotal }
    } else if (visible === false && prevState.visible === true) {
      return { ...prevState, jobList: [], listType: null, actionType: null, period, visible, totalRcvAmt: 0 }
    } else {
      return prevState
    }
  }

  handleSubmit = async () => {
    const { form, onUpdate } = this.props
    const { actionType, jobList, listType, loading, loadingUpdate, period } = this.state

    if (loading || loadingUpdate) return

    this.setState({ loadingUpdate: true })

    const type = actionType === BillingUpdateType.BLS_UPDATE_CLAIM
      ? 'claim'
      : actionType === BillingUpdateType.BLS_UPDATE_PAYROLL
        ? 'topay'
        : actionType === BillingUpdateType.BLS_UPDATE_RCV_PYMT
          ? 'rcvpymt'
          : ''

    if (type) {
      try {
        const paymentDate = form.getFieldValue('payment_date')

        const jobs = []
        for (let i = 0; i < jobList.length; i++) {
          const job = jobList[i]

          const dt = actionType === BillingUpdateType.BLS_UPDATE_RCV_PYMT
            ? {
              id: job.id,
              update_received_subtotal: job.update_received_subtotal,
              update_received_notes: job.update_received_notes,
              update_received_date: job.update_received_date,
              update_is_closed: job.update_is_closed
            }
            : { id: job.id }
            jobs.push(dt)
        }

        const r = await billingUpdateService.updateStatus(type, { jobs, list_type: listType, payment_date: paymentDate, period_code: period.code })

        if (r && r.count !== undefined) {
          this.closeModal()

          if (onUpdate) {
            await onUpdate(r.jobs, r.file, actionType)
          }

          if (r.error) {
            notify.warning('Some status are unable to be updated', formatter.toErrorMessage(r.error))
          } else {
            notify.warning('Status updated', `Job(s) selected are updated successfully.`)
          }
        } else {
          console.log('res', r)
          notify.error('Unable to update the status', 'Please try again later.')
        }
      } catch (e) {
        console.log('re', e)
        notify.error('Unable to update the status', 'Some error occrured. Please try again later.')
      }
    } else {
      notify.error('Unable to update the status', 'No allowed action performed.')
    }

    this.setState({ loadingUpdate: false })
  }

  getModalContent = () => {
    const { actionType, jobList, totalRcvAmt } = this.state
    const { getFieldDecorator } = this.props.form
    let title = ''
    let content = ''

    const isSingleInv = jobList.length === 1

    if (actionType === BillingUpdateType.BLS_UPDATE_CLAIM) {
      title = <div className='sct-title'>
        Claim Job{isSingleInv ? '' : 's'}?
      </div>

      content = <div className='sct-content'>
      Do you want to claim the following job{isSingleInv ? '' : 's'}?
      <ul>
        { jobList.map(e => (<li><div>
            <div>{formatter.capitalize(e.id_numbering, false)} - {e.client_fullname} - Job dated at {formatter.toShortDate(e.job_date)} ({formatter.toFloatDecimal(e.job_duration_hrs)} hr{e.job_duration_hrs === 1 ? 's' : ''})</div>
            <div>processed at {formatter.toShortDate(e.created_at)}</div>
          </div>
        </li>)) }
      </ul>
      </div>
    } else if (actionType === BillingUpdateType.BLS_UPDATE_PAYROLL) {
      title = <div className='sct-title'>
        Pay Job{isSingleInv ? '' : 's'}?
      </div>

      content = <div className='sct-content'>
      Do you want to pay the following job{isSingleInv ? '' : 's'}?
      <ul>
        { jobList.map(e => (<li><div>
            <div>{formatter.capitalize(e.id_numbering, false)} - {e.employee_fullname} - Job dated at {formatter.toShortDate(e.job_date)} ({formatter.toFloatDecimal(e.job_duration_hrs)} hr{e.job_duration_hrs === 1 ? 's' : ''})</div>
            <div>processed at {formatter.toShortDate(e.created_at)}</div>
          </div>
        </li>)) }
      </ul>
      </div>
    } else if (actionType === BillingUpdateType.BLS_UPDATE_RCV_PYMT) {
      title = <div className='sct-title'>
        Update Received Payment?
      </div>

      content = <div className='sct-content'>
      Do you want to update received payment for the following job{isSingleInv ? '' : 's'}?
      <ul>
        { jobList.map(e => {
          const isShowRcvAmtNotFull = e.update_received_subtotal && (e.update_received_margin_state < BillingMarginStateType.MORE_MARGIN && e.update_received_margin_state !== BillingMarginStateType.EQUAL)

          return (
            <li key={`jsls-${e.id_numbering}`}>
              <div>{formatter.capitalize(e.id_numbering, false)} - {e.client_fullname} - Job dated at {formatter.toShortDate(e.job_date)} ({formatter.toFloatDecimal(e.job_duration_hrs)} hr{e.job_duration_hrs === 1 ? '' : 's'})</div>
              <ul>
                { e.update_received_subtotal ? <li><b>rcv'd amount: {formatter.toPrice(e.update_received_subtotal)}</b></li> : null }
                { e.update_received_date ? <li><b><i>rcv'd date: {formatter.toShortDate(e.update_received_date)}</i></b></li> : null }
                { e.update_received_notes ? <li>rcv'd notes: {e.update_received_notes}</li> : null }
                { e.update_is_closed
                  ? <li>
                    <b>Is Closed?: {formatter.toYesNo(e.update_is_closed)}</b>
                    { isShowRcvAmtNotFull
                      ? <div style={{color: 'red'}}>
                        <div>Expected Rcv'd Amount: {formatter.toPrice(e.subtotal)}</div>
                        <div>Actual Rcv'd Amount: {formatter.toPrice(e.actual_received_subtotal)}</div>
                      </div>
                      : null }
                  </li>
                  : null }
              </ul>
            </li>
          )
        }) }
      </ul>
      </div>
    }

    return { title, content }
  }


  closeModal = () => {
    const { onClose, form } = this.props
    const { resetFields } = form

    resetFields()
    onClose()
  }

  render () {
    const { key, visible, form, onClose } = this.props
    const { actionType, jobList, loading, loadingUpdate } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const { title, content } = this.getModalContent(actionType, jobList)
    const isLoading = loading || loadingUpdate

    return (
      <Modal
        width='600px'
        key={`invstatsupdate_${key}`}
        visible={visible}
        title={title}
        onCancel={isLoading ? () => {} : () => { this.closeModal() }}
        footer={[
          <Loading key={`umpts-buttons`} loading={loading} blur>
            <Button
              key='close'
              ghost
              feedback={isLoading}
              onClick={isLoading ? () => {} : () => this.closeModal()}
            >
              Close
            </Button>
            <Button
              style={{backgroundColor: '#3d34eb', color: '#FFF'}}
              key='confirm'
              ghost
              feedback={isLoading}
              onClick={isLoading ? () => {} : () => this.handleSubmit()}
            >
              Confirm
            </Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            { content }
          </Form>
        </Loading>
      </Modal>
    )
  }
}

export default Form.create()(StatusUpdateModal)
