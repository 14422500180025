import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import './styles.css'

import { debounce } from 'lodash'

const ColorSet = [
  { label: '', color: '#000000' },
  { label: '', color: '#444' },
  { label: '', color: '#888' },
  { label: '', color: '#bbb' },
  { label: '', color: '#ff0000' },
  { label: '', color: '#aa0000' },
  { label: '', color: '#770000' },
  { label: '', color: '#ff4400' },
  { label: '', color: '#ff8800' },
  { label: '', color: '#ff8888' },
  { label: '', color: '#ffaa00' }, //10
  { label: '', color: '#aa8800' },
  { label: '', color: '#448800' },
  { label: '', color: '#004400' },
  { label: '', color: '#008800' },
  { label: '', color: '#00bb00' },
  { label: '', color: '#88bb00' }, //20
  { label: '', color: '#000088' },
  { label: '', color: '#0000ff' },
  { label: '', color: '#0088ff' },
  { label: '', color: '#8888ff' },
  { label: '', color: '#ff88ff' },
  { label: '', color: '#660fff' },
  { label: '', color: '#990fff' },
]

const divider = 12

export default class ColorPicker extends Component {
  constructor (props) {
    super(props)
    const { value = null } = this.props

    this.state = {
      isSet: false,
      value
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { value, onChange } = nextProps

    let state = { ...prevState }

    state.isSet = true
    state.value = value

    return state
  }

  handleValueChange = (value) => {
    const { onChange } = this.props

    this.setState({ value })
    onChange && onChange(value)
  }

  render () {
    const { disabled = false, extra = null } = this.props
    const { value } = this.state
    const rowCount = Math.round(ColorSet.length / divider)
    const boxSize = 360 / divider
    const arr = new Array(rowCount).fill('arr')

    return (
      <div className={`color-picker ${disabled ? 'disabled' : ''}`}>
        { arr.map((e, idx) => {
          const colorSet = ColorSet.slice((idx * divider), ((idx + 1) * divider))
          return (
            <Row>
              { colorSet.map((f, index) => {
                const isSelected = f.color === value
                return (
                  <Col lg={24 / divider}>
                    <div
                      className={`picker-box ${isSelected ? 'selected' : ''}`}
                      style={{backgroundColor: disabled ? `${f.color}77` : f.color, width: `${boxSize}px`, height: `${boxSize}px`}}
                      onClick={() => this.handleValueChange(f.color)}
                    />
                  </Col>
                )
              })}
            </Row>
          )}
        )}
      </div>
    )
  }
}
