import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'
import { auth } from '../../../util'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

// import CancellationReason from './Cancellation'
// import ResignedExitedReason from './ResignedExited'
import ReasonList from './List'
import './styles.css'

const TabPane = Tabs.TabPane
const settingTitle = 'Reason'

const TabList = [
  { tabId: 1, path: '/inactive-sw', type: 'inactive-employee', title: 'Inactive (Support Coordinator)' },
  { tabId: 2, path: '/inactive-ppt', type: 'inactive-client', title: 'Inactive (Participant)' },
  { tabId: 3, path: '/cancellation', type: 'cancellation', title: 'Cancellation' }
]

export class SettingReason extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      tab: tab && tab.tabId ? tab : TabList[0]
    }
  }

  handleTabChange = (index) => {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/settings/reasons${tab.path}`)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentTab, tab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey={currentTab} onChange={this.handleTabChange}>
            <TabPane tab={TabList[0].title} key='1'>
              <ReasonList type={TabList[0].type} />
            </TabPane>
            <TabPane tab={TabList[1].title} key='2'>
              <ReasonList type={TabList[1].type} />
            </TabPane>
            {/* <TabPane tab={TabList[2].title} key='3'>
              <ReasonList type={TabList[2].type} />
            </TabPane> */}
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingReason))
