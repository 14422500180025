import Permissions from '../permissions'

export default [
  {
    name: 'Day Counts Since Last Job',
    icon: 'calendar',
    link: '/reports/ppt-day-counts',
    desc: 'Manage Participant\'s Day Counts Since Last Job Report',
    enable: true,
    permission: Permissions.REPORT.DAY_COUNT_LAST_JOB.LIST
  },
  {
    name: 'Participants\'s Plan Details',
    icon: 'clock-circle',
    link: '/reports/ppt-plan-details',
    desc: 'Manage Participant\'s Allocated Job Hours Report',
    enable: true,
    permission: Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST
  },
  {
    name: 'Participants\' Plan Expiry',
    icon: 'dollar',
    link: '/reports/ppt-plan-expiry',
    desc: 'Manage Participants\' Plan Expiry Report',
    enable: true,
    permission: Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST
  },
  {
    name: 'Participants\' Reports Due',
    desc: 'Manage participant\'s Implementation and Progress Due Report',
    icon: 'issues-close',
    link: '/reports/ppt-report-due',
    enable: true,
    permission: Permissions.REPORT.CLIENT_REPORT_DUE.LIST
  },
  {
    name: 'Tasks Due',
    desc: 'Manage Tasks Due Report',
    icon: 'drag',
    link: '/reports/task-due',
    enable: true,
    permission: Permissions.REPORT.TASK_DUE.LIST
  },
  {
    name: 'Report Generate Request',
    icon: 'hdd',
    link: '/reports/custom-generate',
    desc: 'Generate Custom Report by per Request',
    enable: true,
    permission: Permissions.REPORT.SCHEDULER.LIST
  },
  {
    name: 'Report Recipient Setting',
    icon: 'setting',
    link: '/reports/setting',
    desc: 'Edit Report Email Recipient Setting',
    enable: true,
    permission: Permissions.REPORT.RECIPIENT_SETTING.LIST
  },
]
