import { request, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/custom-identifier'

export default {
  get (genre, genreId) {
    return request(`${url}/${genre}/${genreId}`)
  },
  save (genre, genreId, values) {
    return request(`${url}/${genre}/${genreId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  }
}
