import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { authService, scRateService, scCategoryService, logService } from '../../../../services'
import { setRefreshActivityLog } from '../../../../states/actions'
import moment from 'moment-timezone'
import uid from 'uid-safe'

// UI
import { Button, ControlLabel, FileUpload, Loading, List, ListDiv, Panel, Page, Pager, SectionTitle, SideModal, SearchInput } from '../../../../components'
import { FileUploadMsg, Permissions } from '../../../../constants'
import notify from '../../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Table from 'antd/lib/table'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import Notification from 'antd/lib/notification'
import { SupportCatItemsUnit } from '../../../../constants'
import { auth, formatter, log, uploader, validator } from '../../../../util'
import { apiHostname } from '../../../../config'

import DatePicker from 'antd/lib/date-picker'
// import Subset from './subset'
import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane
const { confirm } = Modal

const Option = Select.Option
const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const urlRedirect = '/settings/rate-set/edit'

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 7 },
  wrapperCol: { sm: 14, md: 14, lg: 16 }
}

const formItemDateLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 18, md: 18, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

const DefaultNewSet = {
  id_new: null,
  sets_count: 0,
  rs_name: null,
  rs_start_date: null,
  rs_end_date: null,
  rs_active: true,
  is_expandable: true,
  cat_list: [],
  categories: []
}

const DefaultNewValue = {
  id_new: null,
  rate_set_id: null,
  category_id: null,
  category_name: null,
  day: 'c0',
  value: null,
  label: null
}

export class SettingRateSetEdit extends Component {
  constructor (props) {
    super(props)
    this.state = {
      allCategories: [],
      item: {},
      loading: false,
      loadingCategories: true,
      loadingForm: false,
      showEdit: true,
      showSave: false,
      sets: [],
      setsCount: 0,
      setsDuplicateCount: 0
    }
  }

  componentDidMount () {
    this.fetchRateSet()
  }

  render () {
    const { form, history } = this.props
    const { getFieldDecorator } = form
    const { item, loading, loadingForm, showEdit, showSave } = this.state

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit()
              ? `Add New Rate`
              : loading
                ? <div className='rs-header-skeleton' style={{ width: 200 }} />
                : `Rate Set - ${item.name}`}
          >
            {
              showEdit && this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.UPDATE)
                ? (
                  <div className='btn' onClick={this.handleEdit}>
                  Edit
                  </div>)
                : null
            }
            {
              ((!this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.CREATE)) ||
                (showSave && this.isEdit() && this.hasAccess(Permissions.SETTING.RATESETS.UPDATE)))
                ? (
                  <div className='btn' onClick={this.handleSave}>
                  { loadingForm ? <div><Icon type="loading" /> &nbsp;Saving...</div> : 'Save' }
                  </div>)
                : null
            }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          { this.renderForm() }
        </Page.Content>
      </Page.Body>
    )
  }

  renderForm = () => {
    const { form, history } = this.props
    const { getFieldDecorator } = form
    const { item, loading, loadingCategories, loadingForm, sets, showEdit, showSave } = this.state

    const isRateEditDisabled = item && item.is_editable === false
    const isEdit = this.isEdit()

    return (
      <div>
        <Form>
          <Loading loading={loadingForm} blur>
            <Skeleton loading={loading} active>
              <div className='rs-info'>
                <Row style={{ marginTop: '20px' }}>
                  <Col lg={10}>
                    <FormItem label='Name' {...formItemLayout}>
                      {getFieldDecorator('name', {
                        initialValue: item.name || '',
                        rules: [
                          { min: 2, message: 'Name must be between 2 and 128 characters' },
                          { max: 128, message: 'Name must be between 2 and 128 characters' },
                          { required: true, message: 'Please enter name' },
                          { whitespace: true, message: 'Please enter name' }
                        ]
                      })(
                        <Input disabled={isRateEditDisabled} />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={2}>
                    <FormItem label='' {...formItemLayout}>
                      {getFieldDecorator('active', {
                        initialValue: item.active || false,
                        valuePropName: 'checked',
                      })(
                        <Switch
                          checkedChildren='Enable'
                          unCheckedChildren='Disable'
                          disabled={isRateEditDisabled}
                        />
                      )}
                    </FormItem>
                  </Col>
                  <Col lg={4} />
                  <Col lg={4}>
                    { isEdit
                      ? <Button key='add-subset' ghost={loadingCategories} disabled={loading || loadingForm} onClick={() => this.handleSetAdd()}>Add Subset</Button>
                      : null }
                  </Col>
                  <Col lg={4} />
                </Row>
              </div>

              { sets.map((set, setIdx) => {
                const setIdf = set.rs_id || set.id_new
                const isSetDelete = set.is_delete && set.is_delete === true
                return (
                  <Panel
                    type={'expandable'}
                    isExpandable={set.is_expandable}
                    expandableFunction={() => this.handleSetToggle(setIdx)}
                    disabled={isSetDelete}
                    key={`setpnp${setIdf}`}
                    title={set.rs_name ? `${set.rs_name}` : `New Set${set.sets_count ? ` ${set.sets_count}` : ` ${set.id_new}` }`}
                    extra={<Tooltip title={ set.is_expandable ? 'Collapse' : 'Expand'}>
                      <div className='rs-panel-circle'>
                        <Icon type={ set.is_expandable ? 'up-circle' : 'down-circle'} />
                      </div>
                    </Tooltip>}
                    subtitle={<div className='row-button' onClick={(e) => this.handleSetPropagation(e)}>
                      { !isSetDelete
                        ? <Button onClick={() => this.handleSetDuplicate(set, setIdx)}>Duplicate</Button>
                        : null }
                      { isSetDelete
                        ? <Tooltip title={'Undo delete set'}>
                          <Popconfirm
                            title='Confirm to undo the delete?'
                            onConfirm={() => this.handleSetUndo(set, setIdx, setIdf)}
                            okText='Yes'
                            cancelText='No'
                            placement='left'
                          >
                            <div className='rs-panel-remove'><Icon type='undo'/></div>
                          </Popconfirm>
                        </Tooltip>
                        : <Tooltip title={'Delete set'}>
                          <Popconfirm
                            title='Confirm to delete this?'
                            onConfirm={() => this.handleSetDelete(set, setIdx, setIdf)}
                            okText='Yes'
                            cancelText='No'
                            placement='left'
                          >
                            <div className='rs-panel-remove'><Icon type='delete' theme='filled' /></div>
                          </Popconfirm>
                        </Tooltip>}
                    </div>}
                  >
                    <Row gutter={16}>
                      <Col lg={10}>
                        <FormItem label='Name' {...formItemLayout}>
                          {getFieldDecorator(`rs_name${setIdf}`, {
                            initialValue: set.rs_name || '',
                            rules: [
                              { min: 2, message: 'Name must be between 2 and 128 characters' },
                              { max: 128, message: 'Name must be between 2 and 128 characters' },
                              { required: !isSetDelete, message: 'Please enter name' },
                              { whitespace: true, message: 'Please enter name' }
                            ]
                          })(
                            <Input onChange={(e) => this.handleSetChange(e, setIdx, 'rs_name')} />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={2}>
                        <FormItem label='' {...formItemLayout}>
                          {getFieldDecorator(`rs_active${setIdf}`, {
                            initialValue: set.rs_active || false,
                            valuePropName: 'checked',
                          })(
                            <Switch
                              checkedChildren='Enable'
                              unCheckedChildren='Disable'
                              onChange={(e) => this.handleSetChange(e, setIdx, 'rs_active')}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row gutter={16}>
                      <Col lg={6}>
                        <FormItem label='Start Date' {...formItemDateLayout}>
                          {getFieldDecorator(`rs_start_date${setIdf}`, {
                            initialValue: set.rs_start_date ? formatter.toMoment(set.rs_start_date) : null,
                            rules: [
                              { required: true, message: ' ' },
                              { validator: (r, v, c) => this.validateSetDate(r, v, c, setIdx, setIdf, isSetDelete) }
                            ]
                          })(
                            <DatePicker
                              defaultPickerValue={moment(new Date())}
                              format={'DD/MM/YYYY'}
                              onChange={(e) => this.handleSetChange(e, setIdx, 'rs_start_date')}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={6}>
                        <FormItem label='End Date' {...formItemDateLayout}>
                          {getFieldDecorator(`rs_end_date${setIdf}`, {
                            initialValue: set.rs_end_date ? formatter.toMoment(set.rs_end_date) : null,
                            rules: [
                              { required: true, message: ' ' },
                              { validator: (r, v, c) => this.validateSetDate(r, v, c, setIdx, setIdf, isSetDelete) }
                            ]
                          })(
                            <DatePicker
                              defaultPickerValue={moment(new Date())}
                              format={'DD/MM/YYYY'}
                              onChange={(e) => this.handleSetChange(e, setIdx, 'rs_end_date')}
                            />
                          )}
                        </FormItem>
                      </Col>
                    </Row>

                    <div className='rs-category'>
                      <Row className='h2' gutter={10}>
                        <Col lg={3}>
                          Category
                          { loadingCategories && !isSetDelete
                            ? null
                            : <Popover
                              content={
                                <FormItem>
                                  {getFieldDecorator(`cat_select_${setIdf}`, {
                                    initialValue: undefined,
                                  })(
                                    <Select
                                      labelInValue
                                      style={{ width: 250 }}
                                      onChange={(e) => this.handleCategoryAdd(e, setIdx, setIdf)}
                                    >
                                      { set.cat_list.map((cat, idx) => {
                                          return <Option key={`sccat-${setIdf}-${cat.id}`} value={cat.id}>{ cat.name }</Option>
                                        }) }
                                    </Select>
                                  )}
                                </FormItem>
                              }
                              title='Add Category'
                              placement='right'
                            >
                              <span className='add-category'> <Icon type='plus-circle' theme='filled' /></span>
                            </Popover> }
                        </Col>
                      </Row>

                      { set.categories && set.categories.map((cat, catIdx) => {
                        const catId = cat.id || cat.id_new
                        const catIdf = `${setIdf}-${catId}-${cat.category_id}`
                        const isCategoryDelete = cat.is_delete && cat.is_delete === true

                        return (
                          <div key={`setcnt-${catIdf}`} className={`rs-category-list ${isCategoryDelete ? 'disabled' : ''}`}>
                            <Row gutter={10}>
                              <Col lg={20}>
                                <div className='row-button'>
                                  { isCategoryDelete
                                    ? <Tooltip title={'Undo delete category'}>
                                      <Popconfirm
                                        title='Confirm to undo the delete?'
                                        onConfirm={(e) => this.handleCategoryUndo(cat, catIdx, setIdx)}
                                        okText='Yes'
                                        cancelText='No'
                                        placement='left'
                                      >
                                        <div className='remove-row undo'><Icon type='undo' /></div>
                                      </Popconfirm>
                                    </Tooltip>
                                    : <Tooltip title={'Delete category'}>
                                      <Popconfirm
                                        title='Confirm to delete this?'
                                        onConfirm={(e) => this.handleCategoryDelete(cat, catIdx, setIdx)}
                                        okText='Yes'
                                        cancelText='No'
                                        placement='left'
                                      >
                                        <div className='remove-row'><Icon type='delete' /></div>
                                      </Popconfirm>
                                    </Tooltip> }
                                  <div className='cat-title'>
                                    { cat.category_name } { cat.label ? `  [${cat.label}]` : '' }
                                  </div>
                                </div>
                              </Col>
                              <Col lg={4} />
                            </Row>
                            <Row gutter={10} className='cat-row'>
                              <Col md={4} lg={4} className='cat-sub-title'>
                                Service Booking Rate
                              </Col>
                              <Col md={4} lg={4}>
                                <FormItem>
                                  {getFieldDecorator(`value${catIdf}`, {
                                    initialValue: cat.value,
                                    rules: [
                                      { required: true, message: ' ' },
                                      { validator: this.validateCategoryValue }
                                    ]
                                  })(
                                    <Input
                                      className='rs-field'
                                      placeholder='0.00'
                                      onChange={(e) => this.handleCategoryChange(e, catIdx, setIdx, 'value')}
                                      disabled={isCategoryDelete}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                              <Col style={{display: 'none'}} md={4} lg={4}>
                                <FormItem>
                                  {getFieldDecorator(`label${catIdf}`, {
                                    initialValue: cat.label,
                                    rules: [
                                      { required: true, message: 'Please enter label' }
                                    ]
                                  })(
                                    <Input
                                      className='rs-field'
                                      placeholder='Label'
                                      onChange={(e) => this.handleCategoryChange(e, catIdx, setIdx, 'label')}
                                      disabled={isCategoryDelete}
                                    />
                                  )}
                                </FormItem>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}
                    </div>
                  </Panel>
                )
              } )}
            </Skeleton>
          </Loading>
        </Form>
      </div>
    )
  }

  /** Section 2: Fetch Functions */
  fetchRateSet = async () => {
    this.setState({ loading: true })

    const code = this.getCode()

    if (code === 'add') {
      if (!this.hasAccess(Permissions.SETTING.RATESETS.CREATE)) return

      this.setState({ loading: false, loadingCategories: false, loadingForm: false })
    } else {
      if (!this.hasAccess(Permissions.SETTING.RATESETS.READ)) return

      const item = await scRateService.getCode(code)

      if (item && item.id) {
        const isSetsAvailable = item && validator.isNotEmptyArray(item.sets)
        this.setState({
          loading: false,
          item,
          sets: isSetsAvailable ? cloneDeep(item.sets) : []
        }, async () => {
          this.scrollToTop()
          await this.fetchAllCategories()
          if (!isSetsAvailable) this.handleSetAdd()
        })
      }
    }
  }

  fetchAllCategories = async () => {
    const cats = await scCategoryService.getAll()

    if (validator.isNotEmptyArray(cats)) {
      this.setState({ allCategories: cats, loadingCategories: false })
    }
  }

  /** Section 3: Set Related Functions */
  handleSetAdd = () => {
    const { sets, setsCount, allCategories, loadingCategories } = this.state

    if (loadingCategories) return

    const newCount = setsCount + 1
    const newSet = cloneDeep(DefaultNewSet)
    newSet.id_new = Date.now()
    newSet.sets_count = newCount
    newSet.cat_list = cloneDeep(allCategories)
    newSet.rs_name = `New Set ${newCount}`

    sets.unshift(newSet)

    this.setState({ sets, setsCount: newCount })
  }


  handleEdit = () => {
    this.setState({
      showEdit: false,
      showSave: true
    })
  }

  handleSetToggle = (idx) => {
    const { sets } = this.state

    sets[idx].is_expandable = !sets[idx].is_expandable

    this.setState({ sets })
  }

  handleSetDuplicate = (set, setIdx) => {
    const { form } = this.props
    const { sets, setsCount, setsDuplicateCount } = this.state

    const newSet = cloneDeep(sets[setIdx])

    const newSetsCount = setsCount + 1
    const newSetsDuplicateCount = setsDuplicateCount + 1

    // reset id fields for duplicated data
    delete newSet.id
    delete newSet.rs_id

    if (validator.isNotEmptyArray(newSet.categories)) {
      for (let i = 0; i < newSet.categories.length; i++) {
        delete newSet.categories[i].id
        delete newSet.categories[i].rs_id
        newSet.categories[i].id_new = Date.now()
      }
    }

    newSet.sets_count = newSetsCount
    newSet.id_new = Date.now()
    newSet.rs_name = `${newSet.rs_name} - ${newSetsDuplicateCount}`

    sets.unshift(newSet)

    this.setState({ sets, setsCount: newSetsCount, setsDuplicateCount: newSetsDuplicateCount }, () => {
      form.validateFieldsAndScroll()
    })
  }

  handleSetDelete = (set, setIdx, setIdf) => {
    const { form } = this.props
    const { sets } = this.state

    if (set && set.id) {
      sets[setIdx].is_delete = true
    } else {
      sets.splice(setIdx, 1)
    }

    this.setState({ sets })
  }

  handleSetUndo = (set, setIdx, setIdf) => {
    const { form } = this.props
    const { sets } = this.state

    if (set && set.id) {
      sets[setIdx].is_delete = false
    }

    this.setState({ sets })
  }

  handleSetPropagation = (e) => {
    e.stopPropagation()
  }

  handleSetChange = (e, setIdx, field) => {
    const { form } = this.props
    const { sets } = this.state
    const value = e && e.target ? e.target.value : e
    let isDate = false

    if (setIdx < sets.length && sets[setIdx]) {
      if (field.indexOf('rs_start_date') > -1) {
        sets[setIdx][field] = moment.isMoment(value) ? value.startOf('day') : moment(value).startOf('day')
        isDate = true
      } else if (field.indexOf('rs_end_date') > -1) {
        sets[setIdx][field] = moment.isMoment(value) ? value.endOf('day') : moment(value).endOf('day')
        isDate = true

      } else {
        sets[setIdx][field] = value
      }
    }

    this.setState({ sets }, () => {
      if (isDate) {
        form.validateFields()
      }
    })
  }

  validateSetDate = (rule, value, callback, setIdx, setIdf, isSetDelete = false) => {
    const { form } = this.props
    const { sets } = this.state
    const field = rule.field
    const anotherField = field.indexOf('rs_start_date') > -1
      ? 'rs_end_date'
      : field.indexOf('rs_end_date') > -1
        ? 'rs_start_date'
        : ''
    const anotherValue = form.getFieldValue(`${anotherField}${setIdf}`)

    if (isSetDelete === true) {
      callback()
    } else if (anotherField) {
      if (value === null) {
        callback(new Error('Please select date'))
      } else {
        const mValue = moment.isMoment(value) ? value.clone() : moment(value)
        const mAnotherValue = moment.isMoment(anotherValue) ? anotherValue.clone() : moment(anotherValue)

        if (field.indexOf('rs_start_date') > -1  && mValue.isAfter(mAnotherValue, 'date')) {
          callback(new Error('Start Date must be before/same as End Date'))
        } else if (field.indexOf('rs_end_date') > -1 && mAnotherValue.isAfter(mValue, 'date')) {
          callback(new Error('Start Date must be before/same as End Date'))
        } else {
          const compareSets = sets.filter((e, idx) => idx !== setIdx && e.is_delete !== true)

          let isError = false
          for (let i = 0; i < compareSets.length; i++) {
            const cst = compareSets[i]

            const mStd = moment.isMoment(cst.rs_start_date) ? cst.rs_start_date.clone() : moment(cst.rs_start_date)
            const mEtd = moment.isMoment(cst.rs_end_date) ? cst.rs_end_date.clone() : moment(cst.rs_end_date)

            if (mValue.isSameOrAfter(mStd, 'day') && mValue.isSameOrBefore(mEtd, 'day')) {
              isError = true
              callback(new Error('Selected Date is overlapped with other sets'))
              break
            }
          }

          if (isError === false) {
            callback()
          }
        }
      }
    } else {
      callback(new Error('Invalid Input'))
    }

    // form.validateFields([`${anotherField}${setIdf}`]) // called at onchange function
  }

  /** Section 4: Category Related Function */
  handleCategoryAdd = (v, idx, idf) => {
    const { form } = this.props
    const value = v.key
    const { sets, allCategories } = this.state

    const cat = allCategories.find(e => e.id === value)

    if (cat && cat.id) {
      const setsCatIdx = sets[idx].categories.findIndex(e => e.category_id === cat.id)

      if (setsCatIdx > -1) {

      } else {
        const newValue = cloneDeep(DefaultNewValue)
        newValue.category_name = cat.name
        newValue.category_id = cat.id
        newValue.label = cat.identifier
        newValue.id_new = Date.now()
        sets[idx].categories.push(newValue)

        const newAllCategories = allCategories.slice().filter(e => sets[idx].categories.findIndex(f => f.category_id === e.id) < 0)

        sets[idx].cat_list = newAllCategories
      }
    }

    this.setState({ sets })
  }

  handleCategoryDelete = (cat, catIdx, setIdx) => {
    const { allCategories, sets } = this.state

    if (setIdx < sets.length && sets[setIdx]) {
      const cats = sets[setIdx].categories

      if (catIdx < cats.length && cats[catIdx]) {
        if (cat && cat.id) {
          sets[setIdx].categories[catIdx].is_delete = true
        } else {
          sets[setIdx].categories.splice(catIdx, 1)

          const newAllCategories = allCategories.slice().filter(e => sets[setIdx].categories.findIndex(f => f.category_id === e.id) < 0)
          sets[setIdx].cat_list = newAllCategories
        }
      }
    }

    this.setState({ sets })
  }

  handleCategoryUndo = (cat, catIdx, setIdx) => {
    const { sets } = this.state

    if (setIdx < sets.length && sets[setIdx]) {
      const cats = sets[setIdx].categories

      if (catIdx < cats.length && cats[catIdx]) {
        sets[setIdx].categories[catIdx].is_delete = false
      }
    }

    this.setState({ sets })
  }

  handleCategoryChange = (e, catIdx, setIdx, field) => {
    const { sets } = this.state

    const value = e && e.target ? e.target.value : e

    if (setIdx < sets.length && sets[setIdx]) {
      const cats = sets[setIdx].categories

      if (catIdx < cats.length && cats[catIdx]) {
        sets[setIdx].categories[catIdx][`${field}`] = value
      }
    }

    this.setState({ sets })
  }

  validateCategoryValue = (rule, value, callback) => {
    const pValue = parseFloat(value)
    if (value === null || value === undefined || value === '' || pValue === 0) {
      callback(new Error(`Value is required`))
    } else {
      const v = validator.isCurrencyAmount(value)
      if (!v) {
        callback(new Error(`Value is invalid`))
      } else {
        callback()
      }
    }
  }

  handleSave = async () => {
    const { loading, loadingCategories, loadingForm } = this.state
    const { form } = this.props

    if (loading || loadingCategories || loadingForm) return

    form.validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        const { item, sets } = this.state
        const isEdit = this.isEdit()

        this.setState({ loadingForm: true })

        try {
          const body = cloneDeep(sets)

          for (let i = 0; i < body.length; i++) {
            delete body[i].cat_list
          }

          let r = null

          if (isEdit) {
            r = await scRateService.save(item.id, { sets: body })
          } else {
            const val = {
              name: values.name,
              active: values.active
            }

            r = await scRateService.add(val)
          }

          if (r && r.id) {
            const redirect = () => {
              setTimeout(() => {
                window.location.replace(`${urlRedirect}/${r.id}`)
              }, 200)
            }

            this.handleSaveSuccessful()

            if (isEdit) {
              this.fetchRateSet()
            } else {
              redirect()
            }
          } else {
            this.handleSaveError(r)
          }
        } catch (e) {
          this.handleSaveError()
        }

        this.setState({ loadingForm: false })
      }
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Rate Set saved successfully.')
  }

  handleSaveError = (r) => {
    notify.error('Unable to save successfully', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to save rate set successfully. Please try again later.')
  }

  scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { code } = params
    return code !== 'add' && code !== 'new'
  }

  getId = () => {
    const { item } = this.state

    return item && item.id ? item.id : 'add'
  }

  getCode = () => {
    const { match } = this.props
    const { params } = match
    const { code } = params
    return code
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSetEdit))
