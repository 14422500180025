import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/funders'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getRef (id) {
    return request(`${url}/ref/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  getAllAvailableCats (values) {
    return request(`${url}/current-categories`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  listAllFunders () {
    return request(`${url}/list/all`)
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  exportFunder () {
    return requestFile(`${url}/export/all`)
  }
}
