import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/participants'

export default {
  add (values) {
    return request(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  },
  getLimit (id) {
    return request(`${url}/limit`)
  },
  get (id) {
    return request(`${url}/${id}`)
  },
  getRef (id) {
    return request(`${url}/ref/${id}`)
  },
  getDetail (id) {
    return request(`${url}/detail/ref/${id}`)
  },
  remove (id) {
    return request(`${url}/${id}`, {
      method: 'DELETE',
      headers
    })
  },
  save (id, values) {
    return request(`${url}/${id}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify(values)
    })
  },
  listByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  listClientBudget (id) {
    return request(`${url}/list/budget/${id}`)
  },
  listAllClients () {
    return request(`${url}/list/all`)
  },
  exportClient () {
    return requestFile(`${url}/export/all`)
  }
}
