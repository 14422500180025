import Permissions from '../permissions'

export default [
  {
    name: 'Participants',
    desc: 'Manage all participants',
    icon: 'team',
    link: '/participants',
    enable: true,
    permission: Permissions.PARTICIPANT.INFO.LIST
  },
  {
    name: 'Add Participant',
    desc: 'Add new participant',
    icon: 'usergroup-add',
    link: '/participants/add',
    permission: Permissions.PARTICIPANT.INFO.CREATE
  }
]
