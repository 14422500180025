import { diff } from 'json-diff'
import { formatter } from './'
import { authService, logService } from '../services'
import moment from 'moment'

export default {
  /** new for pm */
  updateCustomIdf (genre, genreId, changes) {
    if (changes !== '') {
      saveLog(genre, genreId, 'update custom identifier', changes)
    }
  },
  addProvider (id, changes) {
    saveLog('provider', id, 'add', changes, true)
  },
  updateProvider (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('provider', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  deleteProvider (id, changes) {
    saveLog('provider', id, 'delete', changes, true)
  },
  addProviderFile (id, changes) {
    saveLog('provider', id, 'file add', changes)
  },
  updateProviderFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('provider', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeProviderFile (id, changes) {
    saveLog('provider', id, 'file delete', changes)
  },
  addFunder (id, changes) {
    saveLog('funder', id, 'add', changes, true)
  },
  updateFunder (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('funder', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  deleteFunder (id, changes) {
    saveLog('funder', id, 'delete', changes, true)
  },
  addClient (id, changes) {
    saveLog('participant', id, 'add', changes, true)
  },
  updateClient (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'update',  changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  deleteClient (id, changes) {
    saveLog('participant', id, 'delete', changes, true)
  },
  addClientProvider (id, changes) {
    saveLog('participant', id, 'provider add', changes, true)
  },
  updateClientProvider (id, before, after, exclude = [], fields = [], additional, prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'provider update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  removeClientProvider (id, changes) {
    saveLog('participant', id, 'provider delete', changes, true)
  },
  addClienPlan (id, changes) {
    saveLog('participant', id, 'plan add', changes, true)
  },
  updateClientPlan (id, before, after, exclude = [], fields = [], additional, prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'plan update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  removeClientPlan (id, changes) {
    saveLog('participant', id, 'plan delete', changes, true)
  },
  addClientTask (id, changes) {
    saveLog('participant', id, 'task add', changes)
  },
  updateClientTask (id, before, after, exclude = [], fields = [], additional, prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'task update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeClientTask (id, changes) {
    saveLog('participant', id, 'task delete', changes)
  },
  addClientTaskJob (id, changes) {
    saveLog('participant', id, 'task job add', changes)
  },
  updateClientTaskJob (id, before, after, exclude = [], fields = [], additional, prefix) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'task job update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeClientTaskJob (id, changes) {
    saveLog('participant', id, 'task job delete', changes)
  },
  cancelClientTaskJob (id, changes) {
    saveLog('participant', id, 'task job cancel', changes)
  },
  addClientFile (id, changes) {
    saveLog('participant', id, 'file add', changes)
  },
  updateClientFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('participant', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeClientFile (id, changes) {
    saveLog('participant', id, 'file delete', changes)
  },
  addEmployee(id, changes) {
    saveLog('employee', id, 'add', changes, true)
  },
  updateEmployee (id, before, after, exclude, fields, additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('employee', id, 'update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), true)
    }
  },
  deleteEmployee (id, changes) {
    saveLog('employee', id, 'delete', changes, true)
  },
  addCredit (id, changes) {
    saveLog('credit', id, 'add', changes)
  },
  updateCredit (id, changes) {
    saveLog('credit', id, 'update', changes)
  },
  deleteCredit (id, changes) {
    saveLog('credit', id, 'delete', changes)
  },
  addTask (id, changes) {
    saveLog('task', id, 'add', changes)
  },
  updateTask (id, before, after, exclude = [], fields = [], additional, prefix, target, targetId) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('task', id, 'update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), false, target, targetId)
    }
  },
  removeTask (id, changes) {
    saveLog('task', id, 'delete', changes)
  },
  addTaskFile (id, changes) {
    saveLog('task', id, 'file add', changes)
  },
  updateTaskFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('task', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeTaskFile (id, changes) {
    saveLog('task', id, 'file delete', changes)
  },
  addTaskJob (id, changes, target, targetId) {
    saveLog('task', id, 'job add', changes, false, target, targetId)
  },
  updateTaskJob (id, before, after, exclude = [], fields = [], additional, prefix, target, targetId) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('task', id, 'job update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), false, target, targetId)
    }
  },
  removeTaskJob (id, changes, target, targetId) {
    saveLog('task', id, 'job delete', changes, false, target, targetId)
  },
  cancelTaskJob (id, changes, target, targetId) {
    saveLog('task', id, 'job cancel', changes, false, target, targetId)
  },
  addTaskJobFile (id, changes) {
    saveLog('task', id, 'job file add', changes)
  },
  updateTaskJobFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('task', id, 'job file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeTaskJobFile (id, changes) {
    saveLog('task', id, 'job file delete', changes)
  },
  addJob (id, changes, target, targetId) {
    saveLog('task-job', id, 'add', changes, false, target, targetId)
  },
  updateJob (id, before, after, exclude = [], fields = [], additional, prefix, target, targetId) {
    const changes = generateChanges(before, after, exclude, fields)
    // console.log('log update client sb cat changes', changes)
    if (changes !== '' || additional !== '') {
      saveLog('task-job', id, 'update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''), false, target, targetId)
    }
  },
  removeJob (id, changes, target, targetId) {
    saveLog('task-job', id, 'delete', changes, false, target, targetId)
  },
  cancelJob (id, changes, target, targetId) {
    saveLog('task-job', id, 'cancel', changes, false, target, targetId)
  },
  addJobFile (id, changes) {
    saveLog('task-job', id, 'file add', changes)
  },
  updateJobFile (id, before, after, exclude = [], fields = [], additional = '', prefix = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes !== '' || additional !== '') {
      saveLog('task-job', id, 'file update', (prefix ? `${prefix} - ` : '') + changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  removeJobFile (id, changes) {
    saveLog('task-job', id, 'file delete', changes)
  },
  updateReportRecipientSetting (id, before, after, exclude, fields, additional = '') {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes || additional) {
      saveLog('report-recipient-setting', id, 'update', changes + (additional ? (changes ? ', ' : '') + additional : ''))
    }
  },
  updateSettingOther (id, before, after, exclude, fields) {
    const changes = generateChanges(before, after, exclude, fields)

    if (changes) {
      saveLog('setting-other', id, 'update', changes)
    }
  },
  generateItemChanges (item, values, excludes = [], fields = []) {
    return generateChanges(item, values, excludes, fields)
  }
}

function saveLog (genre, id, action, changes, isTrigger = false, target = null, targetId = null) {
  const currentUser = authService.getCurrentUser()
  const { id: userId, name } = currentUser

  logService.add({ genre, genre_id: id, action, member: name, member_id: userId, changes, target, target_id: targetId, is_trigger: isTrigger })
}

async function saveLogAsync (genre, id, action, changes) {
  const currentUser = authService.getCurrentUser()
  const { id: userId, name } = currentUser

  await logService.add({ genre, genre_id: id, action, member: name, member_id: userId, changes })
}

function generateChanges (item, values, excludes = [], fields = []) {
  const result = diff(item, values)
  let changes = ''

  // console.log('generate changes', result, item, values)

  if (result) {
    for (const key of Object.keys(result)) {
      // console.log('generate changes 2', key)

      // result for added field: { `${key}__added`: ${value} }
      // result for updated field: { `${key}: { __old: ${oldValue}, __new: ${newValue} } }

      const value = result[key]
      const newKey = key.replace(/__added/g, '')
      const isExclude = excludes.findIndex(item => item === newKey)
      const isFound = fields.findIndex(item => item.key === newKey)
      // console.log('isExclude', key, isExclude)
      if (isExclude < 0) {
        // console.log('isValue', value, value !== null, value !== undefined, key.hasOwnProperty('__added'))
        if ((value !== null && value !== undefined) && key.indexOf('__added') > -1) {
          const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(newKey.replace(/_/g, ' '))

          changes += `${label} set to "${isDate(value) ? (showAsDateTime(newKey) ? formatDateTime(value) : formatDate(value)) : value}", `
        } else if ((value !== null && value !== undefined) && value.hasOwnProperty('__new')) {
          const label = isFound >= 0 ? fields[isFound].label : formatter.capitalize(key.replace(/_/g, ' '))

          // check if the value is date
          if ((value.__old !== null && value.__old !== undefined) && isDate(value.__old)) {
            // skip if no changes on date
            if (showAsDateTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatDateTime(value.__old)}" to "${formatDateTime(value.__new)}", `
              }
            } else if (showAsTime(key)) {
              if (!moment(value.__old).isSame(moment(value.__new))) {
                changes += `${label} from "${formatTime(value.__old)}" to "${formatTime(value.__new)}", `
              }
            } else {
              if (!moment(value.__old).isSame(moment(value.__new), 'day')) {
                changes += `${label} from "${formatDate(value.__old)}" to "${formatDate(value.__new)}", `
              }
            }
          } else {
            if ((value.__old !== null && value.__old !== undefined) && (value.__new !== null && value.__new !== undefined)) {
              if (value.__old.toString() !== value.__new.toString()) {
                changes += `${label} from "${value.__old}" to "${value.__new}", `
              }
            } else if ((value.__old !== null && value.__old !== undefined) && (value.__new !== null && value.__new !== undefined)) {
              // changes += `${label} to "${isDate(value.__new) ? formatDate(value.__new) : value.__new}", `
              changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__new !== null && value.__new !== undefined) {
              changes += `${label} to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__new !== null && value.__new !== undefined) {
              changes += `${label} from NULL to "${isDate(value.__new) ? (showAsDateTime(key) ? formatDateTime(value.__new) : formatDate(value.__new)) : value.__new}", `
            } else if (value.__old !== null && value.__old !== undefined) {
              changes += `${label} from "${isDate(value.__old) ? (showAsDateTime(key) ? formatDateTime(value.__old) : formatDate(value.__old)) : value.__old}" to NULL, `
            }
          }
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}

function generateNewSet (values, excludes = []) {
  let changes = ''
  for (const key of Object.keys(values)) {
    const value = values[key]
    const isExclude = excludes.findIndex(item => item === key)
    if (isExclude < 0) {
      if (value !== null && value !== undefined) {
        // check if the value is date
        if (isGMTDate(value)) {
          changes += `${formatter.capitalize(key.replace(/_/g, ' '))} to "${formatDate(value)}", `
        } else if (value !== false) {
          changes += `${formatter.capitalize(key.replace(/_/g, ' '))} to "${value}", `
        }
      }
    }
  }

  return changes.length > 0 ? changes.substr(0, changes.length - 2) : ''
}
/*
function isDate (date) {
  return date ? (date.toString().indexOf('Z') === date.toString().length - 1 || date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}
 */
function isDate (date) {
  if (date && isNaN(date) && date.length >= 10) {
    const dateCheck = new RegExp(/^\d{4}-\d{2}-\d{2}$/)
    return dateCheck.exec(date.substr(0, 10)) !== null ? true : false
  }
  return false
}

function isGMTDate (date) {
  return date ? (date.toString().indexOf('GMT') > -1) && moment(date).isValid() : false
}

function formatDate (value) {
  const formatted = moment(value).format('DD/MM/YYYY')

  return formatted !== 'Invalid date' ? formatted : ''
}

function formatDateTime (value) {
  const formatted = moment(value).format('DD/MM/YYYY hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsDateTime (key) {
  return key === 'job_start_date' || key === 'job_end_date'
}

function formatTime (value) {
  const formatted = moment(value).format('hh:mm A')

  return formatted !== 'Invalid date' ? formatted : ''
}

function showAsTime (key) {
  return key === 'job_start_time' || key === 'job_end_time'
}
