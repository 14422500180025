import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_PAYROLLS,
  FETCHING_PAYROLLS,
  PAYROLLS_FETCHED,
  FETCH_PAYROLL_TIMESHEETS,
  FETCHING_PAYROLL_TIMESHEETS,
  PAYROLL_TIMESHEETS_FETCHED,
  FETCH_PAYROLL_TIMESHEET_SUM,
  FETCHING_PAYROLL_TIMESHEET_SUM,
  PAYROLL_TIMESHEET_SUM_FETCHED
} from '../actions/payroll'
// import payrollService from '../../services/payroll'

function * fetchPayrolls ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_PAYROLLS, loading })
    // const payrolls = yield payrollService.listByPage(currentPage, pageSize, filter, sort, searchText)
    // yield put({ type: PAYROLLS_FETCHED, payrolls, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLLS, loading: false })
    console.error(e)
  }
}

function * fetchTimesheets ({ loading, currentPage, pageSize, filter, sort, searchText, view }) {
  try {
    yield put({ type: FETCHING_PAYROLL_TIMESHEETS, loading })
    // const timesheets = yield payrollService.listTimesheetByPage(currentPage, pageSize, filter, sort, searchText, view)
    // yield put({ type: PAYROLL_TIMESHEETS_FETCHED, timesheets, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLL_TIMESHEETS, loading: false })
    console.error(e)
  }
}

function * fetchTimesheetSummary ({ id, view }) {
  try {
    yield put({ type: FETCHING_PAYROLL_TIMESHEET_SUM, loading: true })
    // const timesheetSummary = yield payrollService.getTimesheetSummary(id, view)
    // yield put({ type: PAYROLL_TIMESHEET_SUM_FETCHED, timesheetSummary, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_PAYROLL_TIMESHEET_SUM, loading: false })
    console.error(e)
  }
}

function * watchPayroll () {
  yield takeEvery(FETCH_PAYROLLS, fetchPayrolls)
  yield takeEvery(FETCH_PAYROLL_TIMESHEETS, fetchTimesheets)
  yield takeEvery(FETCH_PAYROLL_TIMESHEET_SUM, fetchTimesheetSummary)
}

export default watchPayroll
