import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchEmployees } from '../../../states/actions/employee'
import { EmployeeMenu, Permissions } from '../../../constants'
import { apiHostname } from '../../../config'
import { exportFile, formatter, queryString, validator } from '../../../util'
import { employeeService } from '../../../services'

// UI
import { Button, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Icon from 'antd/lib/icon'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Tooltip from 'antd/lib/tooltip'

import AddEmployeeModal from '../AddEmployeeModal'

const pageSize = 40
const Option = Select.Option

export class Employee extends Component {
  constructor (props) {
    super(props)
    const { employees: { list, total }, loading } = props
    this.state = {
      allMembers: [],
      currentPage: 1,
      filter: { active: true },
      list,
      loading,
      loadingMember: false,
      searching: false,
      searchText: '',
      sort: {},
      suburbs: [],
      total,
      isGenerating: false,
      showAddModal: false
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.onSelectStatus = this.onSelectStatus.bind(this)
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { employees: { list, total }, loading } = nextProps
    const state = { ...prevState, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount () {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchEmployees({ currentPage, filter, loading, searchText, sort })
  }

  onSearchName (value) {
    const { filter, loading, sort } = this.state

    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchEmployees({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectStatus (value) {
    const { currentPage, filter, searchText, sort } = this.state

    if (value === 'active' || value === 'inactive') {
      filter.active = (value === 'active')
    }

    this.fetchEmployees({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async export () {
    const { filter } = this.state

    this.setState({ isGenerating: true })
    // window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
    const r = await exportFile.fetchExport('employee')
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 2000)
  }

  renderEmployee (item) {
    const { id, active, first_name: firstName, last_name: lastName, gender } = item

    return (
      <div className={`list-item ${active ? '' : 'list-item-dim'}`} key={id}>
        <Row className='list-content'>
          <Col md={6}>
            <img alt='emp-list-gender' src={process.env.PUBLIC_URL + '/img/' + (item.gender ? (item.gender.toLowerCase() === 'male' ? 'male.png' : item.gender.toLowerCase() === 'female' ? 'female.png' : 'user.png') : 'user.png')} className='avatar' />
          </Col>

          <Col md={18}>
            <div className='name'>{firstName || ''}{lastName ? ` ${lastName}` : ''}</div>
          </Col>
        </Row>
      </div>
    )
  }

  render () {
    const { allMembers, currentPage, isGenerating, loadingMember, list, total, searching, showAddModal } = this.state
    const { loading } = this.props

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={EmployeeMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Support Coordinators'>
            { this.hasAccess(Permissions.EMPLOYEE.INFO.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null }

            { this.hasAccess(Permissions.EMPLOYEE.INFO.CREATE)
              ? <div onClick={() => this.showAddEmployeeModal(true)} className={`btn ${loadingMember ? 'btn-ghost' : ''}`}>Add</div>
              : null }
          </Page.Header>

          <Page.Filter>
            { this.hasAccess(Permissions.EMPLOYEE.INFO.LIST)
              ? <Row gutter={8}>
                <Col lg={8}>
                  <ControlLabel>Name, Acc Ref ID, Suburb</ControlLabel>
                  <SearchInput placeholder='Search SCs' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
                </Col>
                <Col lg={8}>
                  <ControlLabel>Status</ControlLabel>
                  <Select defaultValue='active' style={{ width: 120 }} onChange={this.onSelectStatus}>
                    <Option value='active'>Active</Option>
                    <Option value='inactive'>Inactive</Option>
                  </Select>
                </Col>
              </Row>
              : null }
          </Page.Filter>

          <div className='employees'>
            <Skeleton loading={loading} active avatar>
              {list.map((item) => {
                const {
                  id,
                  ref_id: refId
                } = item

                return (
                  this.hasAccess(Permissions.EMPLOYEE.INFO.READ)
                  ? <Link to={`/employees/${refId}/info`} key={id}>
                    { this.renderEmployee(item) }
                  </Link>
                  : this.renderEmployee(item)
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} Employee${total === 1 ? '' : 's'}`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />

          <AddEmployeeModal
            allMembers={allMembers}
            visible={showAddModal}
            onUpdate={(r) => this.onUpdateMember(r)}
            onClose={() => this.showAddEmployeeModal(false)}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchEmployees({ currentPage, filter, searchText, sort })
  }

  fetchEmployees = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.EMPLOYEE.INFO.LIST)) return

    try {
      const { fetchEmployees } = this.props
      this.setState({ currentPage })
      fetchEmployees({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load employees successfully. Please try again later.')
    }

    this.fetchAllMembers()
  }

  fetchAllMembers = async () => {
    if (!this.hasAccess(Permissions.EMPLOYEE.INFO.CREATE)) return

    this.setState({ loadingMember: true })

    const list = await employeeService.getReadyMembers()

    if (validator.isNotEmptyArray(list)) {
      this.setState({ allMembers: list, loadingMember: false })
    } else {
      this.setState({ loadingMember: false })
    }
  }

  showAddEmployeeModal = (showAddModal) => {
    this.setState({ showAddModal })
  }

  onUpdateMember = async (r) => {
    const { history } = this.props
    this.fetchEmployees({})

    if (r && r.ref_id) {
      setTimeout(() => {
        history.push(`/employees/${r.ref_id}/info`)
      }, 500)
    }
  }
}

const mapDispatchToProps = {
  fetchEmployees
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Employee)
