import React, { Component } from 'react'
import PropTypes from 'prop-types'
import formatter from '../../util/formatter'

import './styles.css'

export default class Panel extends Component {
  render () {
    const { children, className, color, disabled = false, subtitle, title, extra = null, type, isExpandable = true, expandableFunction = null } = this.props

    const onClick = type === 'expandable' ? expandableFunction : null
    const isShowChildren = type === 'expandable' ? isExpandable : true

    return (
      <div
        className={formatter.toClassName(['wd-panel', className])}
        style={type === 'custom'
          ? {}
          : {
            border: type === 'warn' ? '1px solid rgba(255,0,0,0.4)' : '0'
          }
        }
      >
        <div
          className={`wd-panel-title ${isShowChildren ? `${disabled ? ' disabled-unshrink' : ''}` : `shrink ${disabled ? ' disabled-shrink' : ''}`}`} // disabled
          style={{
            display: title ? '' : 'none',
            cursor: type === 'expandable' ? 'pointer' : 'default'
          }}
          onClick={onClick}
        >
          <div className='wd-panel-left-title' style={{float: 'left'}}>
            <div style={{float: 'left'}}>{ title }</div>
            <div style={{float: 'right'}}>{ extra }</div>
          </div>
          <div style={{float: 'right'}}>{ subtitle }</div>
        </div>

        <div className={isShowChildren ? `wd-panel-body ${disabled ? 'disabled' : ''}` : ''} style={{display: isShowChildren ? '' : 'none'}}>
          { children }
        </div>
      </div>
    )
  }
}

Panel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  subtitle: PropTypes.node,
  title: PropTypes.node
}
