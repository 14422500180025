import React, { Component } from 'react'
import Moment from 'moment'
import { settingFileService } from '../../../../services'
import { formatter, log, validator } from '../../../../util'

import { Button, Loading, SideModal } from '../../../../components'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

export class AddFileCatModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      moduleItem: {},
      item: {},
      isEdit: false,
      loading: false,
      spinLoading: false,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { item = {}, moduleItem = {} } = nextProps
    const isEdit = !!item.id
    return { ...prevState, item, isEdit, moduleItem }
  }

  handleSubmit = async () => {
    const { form, onUpdate } = this.props
    const { moduleItem, item, isEdit = false } = this.state
    const { resetFields, validateFieldsAndScroll } = form
    const that = this

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })

        let r = null

        if (isEdit) {
          r = await settingFileService.saveFileCat(item.id, values)
        } else {
          values.module_id = moduleItem.moduleId
          values.module_type = moduleItem.moduleType

          r = await settingFileService.addFileCat(values)
        }

        this.setState({ loading: false })

        if (onUpdate) {
          if (r && r.id) {
            resetFields()
          }
          onUpdate(isEdit, r)
        }
      }
    })
  }

  render () {
    const { visible, form, onClose } = this.props
    const { isEdit, item, loading, spinLoading } = this.state
    const { getFieldDecorator, resetFields } = form
    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 6 },
      wrapperCol: { sm: 14, md: 14, lg: 14 }
    }

    const title = isEdit ? 'Edit File Category' : 'Add File Category'

    return (
      <SideModal
        key={`sidecat_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => {
          resetFields()
          onClose()
        }}
        buttons={[
          <Loading loading={loading} blur>
            <Button key='ok' type='primary' onClick={() => this.handleSubmit()}> {isEdit ? 'Update' : 'Add'}</Button>
          </Loading>
        ]}
      >
        <Loading loading={loading} blur>
          <Form>
            <Spin spinning={spinLoading}>
              <FormItem label='Name' hasFeedback>
                {getFieldDecorator('name', {
                  initialValue: item.name || '',
                  rules: [
                    { required: true, message: 'Please enter name' },
                    { whitespace: true, message: 'Please enter name' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem label='Description' hasFeedback>
                {getFieldDecorator('description', {
                  initialValue: item.description || ''
                })(
                  <Input />
                )}
              </FormItem>

              <FormItem label='Enable'>
                {getFieldDecorator('active', {
                  initialValue: isEdit ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Spin>
          </Form>
        </Loading>
      </SideModal>
    )
  }
}

export default Form.create()(AddFileCatModal)
