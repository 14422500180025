import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { clientService, employeeService, funderService, settingGeneralService, taskService, taskJobService } from '../../../services'
import { fetchingClients, setRefreshActivityLog } from '../../../states/actions'
import { Permissions, TaskPriority } from '../../../constants'
import moment from 'moment-timezone'
import { auth, common, formatter, log, trigger, validator } from '../../../util'
import { cloneDeep, isEqual } from 'lodash'

// UI
import { List, Loading, Page, Pager, Panel, SideModal } from '../../../components'
import notify from '../../../components/Notification'
import Button from 'antd/lib/button'
import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import './styles.css'

import AddJobModal from '../Job/AddJobModal'
import TaskFile from '../File'
import TaskActivity from '../ActivityLog'

const { Item: FormItem } = Form
const { confirm } = Modal
const TabPane = Tabs.TabPane
const { TextArea } = Input
const Option = Select.Option

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const sideBySidePrivateLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const TabList = [
  { tabId: 1, path: '/info' },
  { tabId: 2, path: '/files' },
  { tabId: 3, path: '/logs' }
]

const dateFormat = 'DD/MM/YYYY'

class Task extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)

    this.state = {
      clientRefId: null,
      clientInfo: {},
      clientBudgetList: [],
      currentBudgetInfo: {},
      employeeList: [],
      funderInfo: {},
      funderList: [],
      serviceList: [],
      statusList: [],
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      isDueDateTriggered: false,
      isDataJobLoaded: false, // flag to control when sid is available in query and when job modal is opened, this flag will be true so it wont open again when job list is refreshed
      item: {},
      itemOri: {},
      jobList: [],
      jobRefId: null,
      loading: false,
      loadingClient: false,
      loadingEmployee: false,
      loadingFunder: false,
      loadingJobs: false,
      loadingSettings: false,
      loadingUpdate: false,
      selectedItem: {},
      selectedRenderItem: {}, // object to store the job pending to open when sid is available
      showJobModal: false,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location } = nextProps
    const { cid, sid } = common.getQueryStringSearchParams(location.search)

    const state = {
      ...prevState,
      clientRefId: prevState.clientRefId ? prevState.clientRefId : cid ? parseInt(cid) : null,
      jobRefId: prevState.jobRefId ? prevState.jobRefId : sid && validator.isNumberText(sid) ? parseInt(sid) : sid === 'add' ? 'add' : null
    }

    return state
  }

  componentDidMount () {
    const isEdit = this.isEdit()

    if (isEdit) {
      this.fetchTask()
    } else {
      this.fetchFunders()
      this.fetchClient()
      this.fetchEmployee()
    }
    this.fetchSetting()
  }

  render () {
    const { history, match } = this.props
    const { currentTab, clientInfo, clientRefId, item, list, loading, loadingClient } = this.state
    const isInfoTab = currentTab === '1'
    const isItem = item && item.id
    const taskId = this.getId()
    const isValidClient = this.isEdit() || (!this.isEdit() && ((!loadingClient && clientInfo && clientInfo.id) || loadingClient))

    const headerTitle = (!this.isEdit()
      ? `New Task${!loadingClient && clientInfo && clientInfo.id ? ` for ${clientInfo.fullname}` : ''}`
      : loading
        ? <div className='client-panel-header-skeleton' style={{ width: 200 }} />
        : loadingClient
          ? `Task`
          : `Task ${clientInfo.fullname ? ` for ${clientInfo.fullname}` : ''}${item.title ? ` - "${item.title}"` : ''}`)

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={
            isValidClient
            ? <Link to={`/participants/${clientInfo.ref_id}/tasks`}>
              { headerTitle }
            </Link>
            : <div>{ headerTitle }</div>
          }>
            { (!this.isEdit() && isValidClient && this.hasAccess(Permissions.PARTICIPANT.TASKS.CREATE)) ||
            (this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.TASKS.UPDATE))
              ? <div className='btn' onClick={loading ? () => {} : this.handleSave}>
                Save
              </div>
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='task-panel'>
            { isValidClient
              ? <Skeleton loading={loading}>
                  <div className='body'>
                    <Tabs
                      defaultActiveKey={currentTab}
                      onChange={this.handleTabChange}
                    >
                      <TabPane tab='Task Info' key='1'>
                        { this.infoTab() }
                      </TabPane>
                      { this.isEdit() && this.hasAccess(Permissions.TASKJOB.INFO.READ)
                        ? <TabPane tab='Files' key='2'>
                          <TaskFile key={`frstab${currentTab}`} taskId={taskId} taskInfo={item} history={this.props.history} />
                        </TabPane>
                        : null }
                      { this.isEdit() && this.hasAccess(Permissions.TASKJOB.INFO.READ)
                        ? <TabPane tab='Activity Log' key='3'>
                          <TaskActivity key={`actab${currentTab}`} taskId={taskId} history={this.props.history} />
                        </TabPane>
                        : null }
                    </Tabs>
                </div>
              </Skeleton>
              : <div className='body'>
                <div>No valid participant info. Please add a new task from participant page.</div>
              </div> }
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      clientBudgetList,
      currentTab,
      clientInfo,
      currentBudgetInfo,
      employeeList,
      funderInfo,
      item,
      isDueDateTriggered,
      jobList,
      list,
      loading,
      loadingClient,
      loadingJobs,
      loadingFunder,
      loadingSettings,
      loadingUpdate,
      showJobModal,
      selectedItem,
      serviceList,
      statusList
    } = this.state
    const isEdit = this.isEdit()
    const id = this.getId()
    const isJobButtonAvailable = this.getIsJobButtonAvailable()

    const jobColumns = [
      {
        title: 'ID',
        width: 2,
        render: ({ id_numbering, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.capitalize(id_numbering, false)}</div>
      },
      {
        title: 'Created At',
        width: 2,
        render: ({ created_at, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.toShortDate(created_at)}<br />{formatter.toShortTime(created_at)}</div>
      },
      {
        title: 'Job Date',
        width: 2,
        render: ({ job_date, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{formatter.toShortDate(job_date)}</div>
      },
      {
        title: 'Job Duration',
        width: 2.5,
        render: ({ job_duration, job_duration_hrs, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>
          <div>{`${formatter.toDayHourText(job_duration)}`}</div>
          <div>{`(${formatter.toFloatDecimal(job_duration_hrs)} hr${parseFloat(job_duration_hrs) === 1 ? '' : 's'})`}</div>
        </div>
      },
      {
        title: 'Support Coordinator',
        width: 2.5,
        render: ({ employee_ref_id, employee_fullname, is_cancelled }) => <a href={`/employees/${employee_ref_id}/info`} rel='noopener noreferrer' target='_blank'><div className={is_cancelled ? 'disabled' : ''}>{employee_fullname}</div></a>
      },
      {
        title: 'Service',
        width: 2.5,
        render: ({ service_name, is_cancelled }) => <div className={is_cancelled ? 'disabled' : ''}>{service_name}</div>
      },
      {
        title: 'Summary',
        width: 5,
        render: ({ summary, is_cancelled }) => <div style={{fontSize: '9px'}} className={is_cancelled ? 'disabled' : ''}>{summary}</div>
      },
      {
        title: 'Task Status',
        width: 3,
        render: ({ task_status_name, task_status_color }) => {
          return (
            <div className='job-status' style={{backgroundColor: task_status_color || '#1d2c47'}}>
              {task_status_name}
            </div>
          )
        }
      },
      {
        title: 'Job Status',
        width: 2.5,
        render: ({ claim_status_name, claim_status_color }) => {
          return (
            <div className='job-status' style={{backgroundColor: claim_status_color || '#1d2c47'}}>
              {claim_status_name}
            </div>
          )
        }
      },
      {
        title: 'Payroll?',
        width: 1,
        render: ({ is_payroll_updated }) => is_payroll_updated
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#BBB', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { !item.budget_id
            ? <div style={{ fontSize: '16px', color: '#FF0000' }}>
              <Tooltip mouseLeaveDelay={0} title='No active plan is available. This job will not be listed in Billings.'>
              <Icon type='warning' theme='filled' />
            </Tooltip>
            </div>
            : null }
          { this.hasAccess(Permissions.PARTICIPANT.TASKS.READ)
            ? <div style={{ color: '#D66E00', cursor: 'pointer' }} onClick={() => this.showAddJobModal(true, item)}>
              <Tooltip mouseLeaveDelay={0} title='Edit'>
                <Icon type='form' />
              </Tooltip>
            </div>
            : null }

        </div>
      }
    ]

    return (
      <div>
        <Form>
          <Skeleton loading={isEdit ? loading : (loading || loadingClient || loadingFunder)}>
            <Loading loading={loadingUpdate} blur>
              <Panel
                title='Info'
                subtitle={isEdit
                  ? <div className='job-status' style={{backgroundColor: item.current_status_color || '#1d2c47'}}>
                    {item.current_status_name}
                  </div>
                  : null}
              >
                <Row>
                  <Col lg={24}>
                    <FormItem {...formItemLayout} label='Title'>
                      {getFieldDecorator('title', {
                        initialValue: item.title || null,
                        rules: [
                          { whitespace: true, message: 'Please enter Title' },
                          { required: true, message: 'Please enter Title' }
                        ]
                      })(
                        <TextArea rows={2} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={24}>
                    <FormItem {...formItemLayout} label='Objective'>
                      {getFieldDecorator('task', {
                        initialValue: item.task || null,
                        rules: [
                          { whitespace: true, message: 'Please enter Objective' },
                          { required: true, message: 'Please enter Objective' }
                        ]
                      })(
                        <TextArea rows={4} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Priority'>
                      {getFieldDecorator('priority', {
                        initialValue: item.priority,
                        rules: [
                          { required: true, message: 'Please select Priority' }
                        ]
                      })(
                        <Select
                          showSearch
                          filterOption={(input, option) =>
                            this.filterOptions(input, option)}
                        >
                          { TaskPriority.map((s, idx) => {
                              return <Option key={`tskprty${s.value}`} value={s.value}>{s.label}</Option>
                            }) }
                        </Select>
                      )}
                    </FormItem>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormItem {...sideBySideFormItemLayout} label='Due Date?'>
                      <Switch
                        style={{marginRight: '20px'}}
                        checked={isDueDateTriggered}
                        onChange={this.handleDueDateChange}
                        checkedChildren='Yes'
                        unCheckedChildren='No'
                      />
                      {getFieldDecorator('due_date', {
                        initialValue: item.due_date ? moment(item.due_date) : null,
                        rules: [
                          { required: isDueDateTriggered, message: ' '},
                          { validator: this.validateDueDate }
                        ]
                      })(
                        <DatePicker disabled={!isDueDateTriggered} format={dateFormat} />
                      )}
                    </FormItem>
                  </Col>
                </Row>
              </Panel>
              { this.isEdit()
                ? <Panel
                  title='Jobs'
                  subtitle={
                    <div className='row-button'>
                      { isJobButtonAvailable
                        ? <div className='btn btn-ghost' onClick={() => this.fetchTaskJob()}>
                          Refresh
                        </div>
                        : null }
                      { isJobButtonAvailable
                        ? <div className='btn' onClick={() => this.showAddJobModal(true, { task_id: id })}>
                          Add Job
                        </div>
                        : null }
                    </div>
                  }
                >
                  <Skeleton loading={loadingJobs}>
                    { validator.isNotEmptyArray(jobList)
                      ? <div className='task-job-list'>
                        <List cols={jobColumns} rows={jobList} />
                        <Pager
                          size={jobList.length}
                          total={jobList.length}
                          totalText={`Total ${jobList.length} job${jobList.length === 1 ? '' : 's'}`}
                          current={1}
                          // onChange={(e) => this.changePage(e)}
                          style={{ marginTop: '15px' }}
                        />
                      </div>
                      : <div className='alert'>No job is added yet.</div>}
                  </Skeleton>
                </Panel>
                : null }
            </Loading>
          </Skeleton>
        </Form>
        <AddJobModal
          clientInfo={clientInfo}
          funderInfo={funderInfo}
          clientBudgetList={clientBudgetList}
          currentBudgetInfo={currentBudgetInfo}
          employeeList={employeeList}
          statusList={statusList}
          serviceList={serviceList}
          taskInfo={item}
          item={selectedItem}
          visible={showJobModal}
          onClose={() => this.showAddJobModal(false)}
          onComplete={() => this.onCompleteJobModal()}
        />
      </div>
    )
  }

  configureAllLoadedTimeout = () => {
    const { jobRefId, selectedRenderItem } = this.state

    if (jobRefId && ((selectedRenderItem && selectedRenderItem.id) || (selectedRenderItem && !selectedRenderItem.id && selectedRenderItem.task_id))) {
      setTimeout(() => {
        const isJobButtonAvailable = this.getIsJobButtonAvailable()

        if (isJobButtonAvailable) {
          this.showAddJobModal(true, selectedRenderItem)
        } else {
          this.configureAllLoadedTimeout()
        }
      }, 500)
    }
  }

  // isUpdate = true: only update the details of task, no need to refetch task jobs and client/emp/funder details again
  fetchTask = async (isUpdate = false) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.TASKS.READ)) {
      return
    }

    const refId = this.getRefId()

    try {
      if (!isUpdate) {
        this.setState({ loading: true })
      }

      const ts = await taskService.getRef(refId)

      if (ts && ts.id) {
        this.setState({
          loading: false,
          isDueDateTriggered: ts && ts.due_date,
          item: ts,
          itemOri: cloneDeep(ts)
        }, () => {
          if (!isUpdate) {
            this.fetchTaskJob()
            this.fetchClient(ts.client_ref_id)
            this.fetchEmployee()
            this.fetchFunders()
          }
        })
      } else {
        this.handleLoadError(ts)
        this.setState({ loading: false })
      }
    } catch (e) {
      this.handleLoadError(e)
      this.setState({ loading: false })
    }

    this.scrollToTop()
  }

  fetchTaskJob = async () => {
    const { item } = this.state

    if (item && item.id) {
      try {
        this.setState({ loadingJobs: true })

        const jobs = await taskJobService.listAllJobs(item.id)

        if (validator.isNotEmptyArray(jobs)) {
          this.setState({
            jobList: jobs,
            loadingJobs: false
          }, () => {
            const { isDataJobLoaded, jobRefId } = this.state
            // jobRefId is integer-rised on gsrp
            if (!isDataJobLoaded && jobRefId) {
              const job = jobs.find(e => e.ref_id === jobRefId)

              if (job && job.id) {
                this.setState({ selectedRenderItem: job }, () => {
                  this.configureAllLoadedTimeout()
                })
              } else if (jobRefId === 'add') {
                this.setState({ selectedRenderItem: { task_id: item.id } }, () => {
                  this.configureAllLoadedTimeout()
                })
              }

              this.setState({ isDataJobLoaded: true })
            } else {
              this.setState({ isDataJobLoaded: true })
            }
          })
        } else {
          this.setState({ loadingJobs: false })
        }
      } catch (e) {
        this.setState({ loadingJobs: false })
      }
    }
  }

  fetchClient = async (refId = null, isUpdateBackground = false) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.READ)) {
      return
    }

    const { clientRefId } = this.state

    const id = refId || clientRefId

    if (id) {
      try {
        this.setState({ clientRefId: id, loadingClient: isUpdateBackground ? false : true })

        const c = await clientService.getDetail(id)

        if (c && c.id) {
          const currentBudgetInfo = this.checkDateInPlanPeriod(new Date(), c.budgets)
          this.setState({
            clientInfo: c,
            clientRefId: c.ref_id,
            clientBudgetList: c.budgets || [],
            currentBudgetInfo: currentBudgetInfo || {},
            loadingClient: false
          })
        } else {
          this.setState({ loadingClient: false })
        }
      } catch (e) {
        console.log('fetch client error', e)
        this.setState({ loadingClient: false })
      }
    }
  }

  fetchEmployee = async () => {
    try {
      this.setState({ loadingEmployee: true })

      const c = await employeeService.listAllEmployees()

      if (validator.isNotEmptyArray(c)) {
        this.setState({ employeeList: c, loadingEmployee: false })
      } else {
        this.setState({ loadingEmployee: false })
      }
    } catch (e) {
      this.setState({ loadingEmployee: false })
    }
  }

  fetchFunders = async () => {
    if (!this.hasAccess(Permissions.FUNDER.INFO.LIST)) {
      return
    }

    try {
      this.setState({ loadingFunder: true })

      const f = await funderService.listAllFunders()

      if (f && validator.isNotEmptyArray(f)) {
        const { item } = this.state
        // TODO: NDIS only mode filtering
        const ff = f.filter(e => e.ref_id === 1)
        this.setState({
          funderList: ff || [],
          funderInfo: item && item.funder_id ? (ff.find(e => e.id === item.funder_id) || {}) : validator.isNotEmptyArray(ff) ? ff[0] : {},
          loadingFunder: false
        })
      } else {
        this.setState({ loadingFunder: false })
      }
    } catch (e) {
      this.setState({ loadingFunder: false })
    }
  }

  fetchSetting = async () => {
    try {
      this.setState({ loadingSettings: true })

      const filter = {}
      filter.identifier = {
        $or: [
          { condition: '=', value: 'sc-supp-coords' },
          { condition: '=', value: 'sc-task-status' },
        ]
      }
      filter.active = { condition: '=', value: true }

      const settings = await settingGeneralService.listByPage(1, 0, filter)

      this.setState({
        serviceList: settings.list.filter(item => item.identifier === 'sc-supp-coords'),
        statusList: settings.list.filter(item => item.identifier === 'sc-task-status' && item.value !== 'new'),
        loadingSettings: false
      })
    } catch (e) {
      this.setState({ loadingSettings: false })
    }
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getIsJobButtonAvailable = () => {
    const { loading, loadingClient, loadingFunder, loadingSettings, loadingJobs } = this.state

    return !loading && !loadingClient && !loadingFunder && !loadingSettings && !loadingJobs
  }

  handleDueDateChange = (value) => {
    this.setState({ isDueDateTriggered: value })
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/tasks/${refId}${tab.path}`)
    }
  }

  handleSave = async (e) => {
    e.preventDefault()
    const { loading, loadingUpdate } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''

    if (loading || loadingUpdate) {
      return
    }

    this.setState({ loadingUpdate: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          try {
            const { clientInfo, funderList, isDueDateTriggered, item } = this.state
            const funder = validator.isNotEmptyArray(funderList) ? funderList[0] : null

            if (funder && funder.id) {
              if (!isDueDateTriggered) {
                values.due_date = null
              }

              if (this.isEdit()) {
                const r1 = await taskService.save(item.id, values)

                if (r1 && r1.id) {
                  const prevItem = cloneDeep(this.state.itemOri)
                  const currItem = cloneDeep(values)

                  log.updateClientTask(
                    clientInfo.id,
                    prevItem,
                    currItem,
                    [],
                    [
                      { key: 'title', label: 'Title' },
                      { key: 'task', label: 'Objective' },
                      { key: 'due_date', label: 'Due Date' },
                    ],
                    extraLog
                  )
                  log.updateTask(
                    r1.id,
                    prevItem,
                    currItem,
                    [],
                    [
                      { key: 'title', label: 'Title' },
                      { key: 'task', label: 'Objective' },
                      { key: 'due_date', label: 'Due Date' },
                    ],
                    extraLog
                  )

                  this.handleSaveSuccessful()
                }
              } else {
                values.funder_id = funder.id
                values.client_id = clientInfo.id

                const r2 = await taskService.add(values)

                if (r2 && r2.id) {
                  const { id, ref_id } = r2

                  const logText = `New Task for ${clientInfo.fullname} - Title: "${values.title}", Objective: "${values.task}", Priority: "${values.priority}"${isDueDateTriggered ? `, Due date is assigned and set as ${formatter.toShortDate(values.due_date)}.` : `, Due date is not assigned.`}`

                  log.addClientTask(clientInfo.id, logText)
                  log.addTask(r2.id, logText)

                  this.handleSaveSuccessful()
                  window.location.replace(`/tasks/${ref_id}/info`)
                } else {
                  this.handleSaveError(r2)
                }
              }
            } else {
              this.handleSaveError({ message: 'No valid funder is available' })
            }
          } catch (e) {
            console.log('task save error', e)
            this.handleSaveError(e)
          }
        }

        this.setState({ loadingUpdate: false })
      })
    })
  }

  handleLoadError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to get task successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to get task successfully', `${e.message}`)
    } else {
      notify.error('Unable to get task successfully', 'Unable to retrieve the task info. Please try again later.')
    }
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Task saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Task successfully. Please try again later.')
    }
  }

  showAddJobModal = (showJobModal, item = {}) => {
    this.setState({
      showJobModal,
      selectedItem: item,
      selectedRenderItem: {},
      isDataJobLoaded: true // clear selectedRenderItem and indicate isDataJobLoaded true here to make sure auto popup only happen once
    }, () => {
      if (showJobModal === false) {
        // if there is "?sid=add" i.e. it has new job tag on url, when close the job modal, redirect url to normal url
        const { jobRefId, currentTab } = this.state
        const refId = this.getRefId()
        const tab = TabList.find(e => e.tabId === parseInt(currentTab)) || TabList[0]

        if (jobRefId === 'add') {
          this.props.history.replace(`/tasks/${refId}${tab.path}`)
        }
      }
    })
  }

  validateDueDate = (rule, value, callback) => {
    const { isDueDateTriggered, clientBudgetList } = this.state

    const plan = this.checkDateInPlanPeriod(value)
    if (!isDueDateTriggered) {
      callback()
    } else {
      if (plan && plan.id) {
        callback()
      } else {
        callback(new Error(`Due Date is not within any available plan periods`))
      }
    }
  }

  checkDateInPlanPeriod = (value, budgetList = undefined) => {
    const { clientBudgetList, funderList } = this.state
    const funder = validator.isNotEmptyArray(funderList) ? funderList[0] : null
    const date = moment.isMoment(value) ? value : moment(value)
    let budget = null

    const bgList = budgetList ? budgetList : clientBudgetList

    if (funder && funder.id) {
      const bgts = bgList.filter(e => e.funder_id === funder.id)

      for (let i = 0; i < bgts.length; i++) {
        const cb = bgts[i]
        const std = moment.isMoment(cb.period_start_date) ? cb.period_start_date : moment(cb.period_start_date)
        const etd = moment.isMoment(cb.period_end_date) ? cb.period_end_date : moment(cb.period_end_date)


        if (std.isSameOrBefore(date) && etd.isSameOrAfter(date)) {
          budget = cb
          break
        }
      }
    }


    return budget
  }

  onCompleteJobModal = async () => {
    const { clientInfo, currentTab, jobRefId } = this.state
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(currentTab)) || TabList[0]

    if (jobRefId) {
      this.props.history.replace(`/tasks/${refId}${tab.path}`)
    }
    this.fetchTask(true)
    this.fetchTaskJob()
    this.fetchClient(clientInfo.ref_id, true)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Task))
