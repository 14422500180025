import React, { Component } from 'react'
import moment from 'moment-timezone'
import { clientBudgetItemService, funderService, providerService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'
import { cloneDeep, isEqual } from 'lodash'

import { Button, Loading, SideModal } from '../../../components'
import { FileUploadMsg, FundingManagedTypes, Permissions } from '../../../constants'
import notify from '../../../components/Notification'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'
import Upload from 'antd/lib/upload'
import { apiHostname } from '../../../config'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const RATE_ERROR_MSG_NO_CAT = `Please select support item.`
const RATE_ERROR_MSG_NO_RATE_FOUND = `No rate is found.`
const RATE_ERROR_MSG_NO_START_DATE = `No Plan Start Date selected.`
const RATE_ERROR_MSG_NO_RATE = `No rate set available for the Support Item during selected date.`
const RATE_ERROR_MSG_NO_END_DATE = `No Plan End Date selected. The rate may different once the Booking Rate Period ends.`
const RATE_ERROR_MSG_OVER_END_DATE = `The Plan End Date selected is beyond of Booking Rate Period. The rate may different once the Booking Rate Period ends.`
const RATE_ERROR_MSG_OVER_START_DATE = `The Plan Start Date selected is beyond of Booking Rate Period. The rate may different before the Booking Rate Period starts.`

const FILE_NO_REPORT_FILE = `You need to attach a file to complete the update.`

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const DefaultFileItem = {
  fileList: [],
  fileUploadedItem: {},
  uploadErrorMsg: ''
}

const DefaultUploadConfig = {
  method: 'POST',
  action: `${apiHostname}/private/api/files/upload`,
  name: 'file',
  headers: { Authorization: `Bearer ${auth.getCurrentToken()}` },
  multiple: false
}

const FILE_PLAN = 'filePlan'
const FILE_SVC = 'fileSvc'
const FILE_IMP_REPORT = 'fileImpReport'
const FILE_PP_REPORT = 'filePPReport'

export class AddBudgetItemModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allBudgets: [],
      currentCategories: [],
      initialisedStartDate: null,
      isItemLoaded: false,
      isCategoryListUpdated: false,
      isTBDTriggered: false,
      item: {},
      loadingBudget: false,
      loadingCats: false,
      loadingCurrentCats: false,
      loadingRate: false,
      loadingUpdate: false,
      isEdit: false,
      isBookingDetailNotRequired: false,
      rateSetErrorMsg: [],
      visible: false,
      // file status on different categories of file
      files: {
        [FILE_PLAN]: Object.assign({}, DefaultFileItem),
        [FILE_SVC]: Object.assign({}, DefaultFileItem),
        [FILE_IMP_REPORT]: Object.assign({}, DefaultFileItem),
        [FILE_PP_REPORT]: Object.assign({}, DefaultFileItem)
      },
      isImpReportToggle: false,
      isPPReportToggle: false,
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { clientId = '', categoryList = [], budgetList = [], initialisedStartDate = null, item = {}, loadingCats, visible } = nextProps

    const isEdit = !!item.id
    // item must have funder_id and do the filtering on budgetList
    const newBudgetList = budgetList.filter(e => item.funder_id ? item.funder_id === e.funder_id : false)

    let isItemLoaded = prevState.isItemLoaded
    let isTBDTriggered = prevState.isTBDTriggered
    let isImpReportToggle = prevState.isImpReportToggle
    let isPPReportToggle = prevState.isPPReportToggle
    let isBookingDetailNotRequired = prevState.isBookingDetailNotRequired

    if (prevState.isItemLoaded === false && visible === true) {
      isItemLoaded = true
      isTBDTriggered = item.is_tbd || false
      isImpReportToggle = item.is_imp_report_done || false
      isPPReportToggle = item.is_pp_report_done || false
      isBookingDetailNotRequired = item.fund_managed_type !== 'ndia-managed'
    }

    return {
      ...prevState,
      allBudgets: newBudgetList,
      initialisedStartDate,
      item,
      isEdit,
      isItemLoaded,
      isImpReportToggle,
      isPPReportToggle,
      isTBDTriggered,
      isBookingDetailNotRequired,
      loadingCats: loadingCats,
      currentCategories: prevState.isCategoryListUpdated ? prevState.currentCategories : categoryList, // if category list is updated within the modal, ignore the change from outside
      rateSetErrorMsg: prevState.isCategoryListUpdated ? prevState.rateSetErrorMsg : [],
      visible
    }
  }

  // fetchAvailableCats only executes when modal internally triggered. default cats loading is brought from parent view
  // so flag loadingCats triggered from getDerivedStateFromProps but loadingCurrentCats is triggered locally
  fetchAvailableCats = async (funderId, startDate = moment(new Date())) => {
    const { form } = this.props

    if (funderId && startDate) {
      this.setState({ loadingCurrentCats: true })

      const values = {
        funder_id: funderId,
        start_date: startDate
      }
      const r = await funderService.getAllAvailableCats(values)

      if (r && validator.isArray(r)) {
        this.setState({ currentCategories: r, loadingCurrentCats: false, isCategoryListUpdated: true }, () => {
          // clear the cat id if current categories is not in the list
          const catId = form.getFieldValue('cat_id')

          if (r.findIndex(e => e.category_id === catId) < 0) {
            form.setFieldsValue({ ['cat_id']: undefined })
          }
        })
      } else {
        this.setState({ loadingCurrentCats: false, isCategoryListUpdated: true })
      }
    }
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  getCurrentHourRate = () => {
    const { form } = this.props
    const { currentCategories } = this.state

    this.setState({ isCategoryListUpdated: true })

    const catId = form.getFieldValue('cat_id')
    const startDate = form.getFieldValue('period_start_date')
    const endDate = form.getFieldValue('period_end_date')

    let errorMsg = []
    let isProceed = true

    if (!catId) {
      errorMsg.push(RATE_ERROR_MSG_NO_CAT)
      isProceed = false
    }

    if (!startDate) {
      errorMsg.push(RATE_ERROR_MSG_NO_START_DATE)
      isProceed = false
    }

    if (!endDate) {
      errorMsg.push(RATE_ERROR_MSG_NO_END_DATE)
    }

    if (isProceed) {
      const cat = currentCategories.find(e => e.category_id === catId)

      if (cat && cat.category_id) {
        const rsd = moment(cat.start_date)
        const red = moment(cat.end_date)

        form.setFieldsValue({ rate_value: cat.value })

        if (startDate) {
          const msd = moment.isMoment(startDate) ? startDate.clone() : moment(startDate)

          if (msd.isBefore(rsd)) {
            errorMsg.push(RATE_ERROR_MSG_OVER_START_DATE)
          }
        }

        if (endDate) {
          const med = moment.isMoment(endDate) ? endDate.clone() : moment(endDate)

          if (med.isAfter(red)) {
            errorMsg.push(RATE_ERROR_MSG_OVER_END_DATE)
          }
        }
      } else {
        errorMsg.push(RATE_ERROR_MSG_NO_RATE_FOUND)
      }
    }

    this.setState({ rateSetErrorMsg: errorMsg })
  }

  handleChangeFundType = (value) => {
    let { isBookingDetailNotRequired } = this.state
    if (value === 'ndia-managed') {
      isBookingDetailNotRequired = false
    } else {
      isBookingDetailNotRequired = true
    }

    this.setState({ isBookingDetailNotRequired })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant Plan saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Participant Provider successfully. Please try again later.')
    }
  }

  handleSaveValidateError = () => {
    notify.error('Unable to save', FILE_NO_REPORT_FILE)
  }

  handleStartDateChange = (e) => {
    const { item } = this.state
    this.fetchAvailableCats(item.funder_id, e)
  }

  handleTogglePlanStatus = (e, type) => {
    const { files } = this.state
    let isImpReportToggle = this.state.isImpReportToggle
    let isPPReportToggle = this.state.isPPReportToggle

    if (type === FILE_IMP_REPORT) {
      isImpReportToggle = e
      if (e === true) {
        files[FILE_IMP_REPORT].uploadErrorMsg = FILE_NO_REPORT_FILE
      } else {
        files[FILE_IMP_REPORT].uploadErrorMsg = ''
        files[FILE_IMP_REPORT].fileList = []
        files[FILE_IMP_REPORT].fileUploadedItem = {}
      }
    } else if (type === FILE_PP_REPORT) {
      isPPReportToggle = e
      if (e === true) {
        files[FILE_PP_REPORT].uploadErrorMsg = FILE_NO_REPORT_FILE
      } else {
        files[FILE_PP_REPORT].uploadErrorMsg = ''
        files[FILE_PP_REPORT].fileList = []
        files[FILE_PP_REPORT].fileUploadedItem = {}
      }
    }

    this.setState({ files, isImpReportToggle, isPPReportToggle })
  }

  handleToggleTBD = (e) => {
    this.setState({ isTBDTriggered: e })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant Plan saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Participant Plan successfully. Please try again later.')
    }
  }

  onClose = () => {
    const { form, onClose } = this.props
    const { resetFields } = form

    this.setState({ isCategoryListUpdated: false, isItemLoaded: false, isTBDTriggered: false })
    resetFields()
    this.resetFiles()
    onClose()
  }

  onSubmit = async (e) => {
    const { clientId, form, onUpdate } = this.props
    const { validateFieldsAndScroll } = form
    const { files, isEdit, isImpReportToggle, isPPReportToggle, isTBDTriggered, item, currentCategories, loadingUpdate } = this.state
    const { resetFields } = form

    if (loadingUpdate) return

    this.setState({ loadingUpdate: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        const r = this.validateToggleSubmit()
        if (!errors && r === true) {
          try {
            let r = null

            values.is_tbd = isTBDTriggered
            values.client_id = clientId
            values.funder_id = item.funder_id
            values.is_imp_report_done = isImpReportToggle
            values.is_pp_report_done = isPPReportToggle

            if (isEdit) {
              const filesData = {
                [FILE_PLAN]: {
                  file: files[FILE_PLAN].fileUploadedItem,
                  fileName: files[FILE_PLAN].fileUploadedItem ? files[FILE_PLAN].fileUploadedItem.fileName : null,
                  uid: files[FILE_PLAN].fileUploadedItem ? files[FILE_PLAN].fileUploadedItem.uid : null,
                  label: values.file_ndis_label || null
                },
                [FILE_SVC]: {
                  file: files[FILE_SVC].fileUploadedItem,
                  fileName: files[FILE_SVC].fileUploadedItem ? files[FILE_SVC].fileUploadedItem.fileName : null,
                  uid: files[FILE_SVC].fileUploadedItem ? files[FILE_SVC].fileUploadedItem.uid : null,
                  label: values.file_svc_label || null
                },
                [FILE_IMP_REPORT]: {
                  file: files[FILE_IMP_REPORT].fileUploadedItem,
                  fileName: files[FILE_IMP_REPORT].fileUploadedItem ? files[FILE_IMP_REPORT].fileUploadedItem.fileName : null,
                  uid: files[FILE_IMP_REPORT].fileUploadedItem ? files[FILE_IMP_REPORT].fileUploadedItem.uid : null,
                  label: values.file_imp_label || null
                },
                [FILE_PP_REPORT]: {
                  file: files[FILE_PP_REPORT].fileUploadedItem,
                  fileName: files[FILE_PP_REPORT].fileUploadedItem ? files[FILE_PP_REPORT].fileUploadedItem.fileName : null,
                  uid: files[FILE_PP_REPORT].fileUploadedItem ? files[FILE_PP_REPORT].fileUploadedItem.uid : null,
                  label: values.file_pp_label || null
                }
              }
              delete values.file_ndis_label
              delete values.file_svc_label
              delete values.file_imp_label
              delete values.file_pp_label

              values.files = filesData
              r = await clientBudgetItemService.save(item.id, values)
            } else {
              r = await clientBudgetItemService.add(values)
            }

            if (r && r.id) {
              // if detail updated but file update contains of error, the r will return with id and errors together
              if (r.errors) {
                this.handleSaveError(r.errors)

                // fetch the reset-ed flag from ctx.body due to error(s) occured when update file
                if (r.resultSet) {
                  values.is_imp_report_done = r.resultSet.is_imp_report_done
                  values.is_pp_report_done = r.resultSet.is_pp_report_done
                }
              } else {
                this.handleSaveSuccessful()
              }

              const funderName = item.funder_fullname
              const currentCat = currentCategories.find(e => e.category_id === values.cat_id)
              const catName = currentCat && currentCat.category_id ? currentCat.category_name : ''
              const currentManaged = FundingManagedTypes.find(e => e.value === values.fund_managed_type)
              const managedName = currentManaged && currentManaged.value ? currentManaged.label : ''

              let newContacts = ''
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_first_name || ''}`
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_org_name || ''}`
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_contact || ''}`
              newContacts += `${newContacts ? ' | ' : ''}${values.cmsc_email || ''}`

              if (isEdit) {
                const prefix = `Plan "${formatter.toShortDate(item.period_start_date)} - ${formatter.toShortDate(item.period_end_date)}" (${funderName})`

                const isCatItemChange = item.cat_id !== values.cat_id
                const isManagedTypeChange = item.fund_managed_type !== values.fund_managed_type

                let fileLogText = ''

                let additional = ''
                additional += `${isCatItemChange ? `${additional ? ', ' : ''}Support Item from "${item.cat_name}" to "${catName}"` : ''}`
                additional += `${isManagedTypeChange ? `${additional ? ', ' : ''}SC Fund from "${item.fund_managed_type_name}" to "${managedName}"` : ''}`

                // remove files update on log if there is errors return. The errors return by file upload api in server part.
                if (r.errors) {
                  // no file updates to log
                } else if (values.files) {
                  if (values.files[FILE_PLAN]) {
                    fileLogText += `${fileLogText ? `, ` : ''}File for NDIS Plan set to "${values.files[FILE_PLAN].fileName}" with label "${values.files[FILE_PLAN].label}"`
                  }

                  if (values.files[FILE_SVC]) {
                    fileLogText += `${fileLogText ? `, ` : ''}File for Service Agreement set to "${values.files[FILE_SVC].fileName}" with label "${values.files[FILE_SVC].label}"`
                  }

                  if (values.files[FILE_IMP_REPORT]) {
                    fileLogText += `${fileLogText ? `, ` : ''}File for Implementation Report set to "${values.files[FILE_IMP_REPORT].fileName}" with label "${values.files[FILE_IMP_REPORT].label}"`
                  }

                  if (values.files[FILE_PP_REPORT]) {
                    fileLogText += `${fileLogText ? `, ` : ''}File for Progress Report set to "${values.files[FILE_PP_REPORT].fileName}" with label "${values.files[FILE_PP_REPORT].label}"`
                  }

                  additional += `${additional ? ', ' : ''}${fileLogText}`
                }

                delete values.files

                log.updateClientPlan(
                  clientId,
                  item,
                  values,
                  [
                    'client_id',
                    'created_at',
                    'updated_at',
                    'funder_id',
                    'funder_fullname',
                    'cat_id',
                    'cat_name',
                    'fund_managed_type',
                    'fund_managed_type_name'
                  ],
                  [
                    { key: 'period_start_date', label: 'Plan Start Date' },
                    { key: 'period_end_date', label: 'Plan End Date' },
                    { key: 'rate_value', label: 'Hourly Rate' },
                    { key: 'allocated_hrs', label: 'Allocated Hours' },
                    { key: 'is_tbd', label: 'TBD?' },
                    { key: 'actual_hrs', label: 'Usable Hours' },
                    { key: 'booking_number', label: 'Service Booking Number' },
                    { key: 'budget_value', label: 'Service Booking Amount' },
                    { key: 'cmsc_first_name', label: 'Contact Name' },
                    { key: 'cmsc_first_name', label: 'Contact Name' },
                    { key: 'cmsc_org_name', label: 'Contact Name' },
                    { key: 'cmsc_contact', label: 'Contact No' },
                    { key: 'cmsc_email', label: 'Contact Email' },
                    { key: 'is_imp_report_done', label: 'Implementation Report Done' },
                    { key: 'is_pp_report_done', label: 'Progress Report Done' },
                  ],
                  isCatItemChange ? `Support Item from ${item.cat_name} to ${catName}.` : '',
                  additional,
                  prefix
                )
              } else {
                let logText = `Funder "${funderName}" - "${formatter.toShortDate(values.period_start_date)} - ${formatter.toShortDate(values.period_end_date)}", selected Support Item as "${catName}", Hourly Rate as "${values.rate_value}", Plan Allocated Hours as "${values.allocated_hrs}", ${values.is_tbd ? `Plan is still TBD` : `Usable Hours as "${values.actual_hrs}", Service Booking Number as "${values.booking_number}", Service Booking Amount as "${values.budget_value}"`}, SC Fund as "${managedName}"${newContacts ? `, Contacts: ${newContacts}` : ''}`

                log.addClienPlan(clientId, logText)
              }

              onUpdate()
              this.onClose()
            } else {
              if (r && r.errors) {
                this.handleSaveError(r)
              } else {
                this.handleSaveError()
              }
            }
          } catch (e) {
            console.log('plan save error', e)
            this.handleSaveError(e)
          }
        }

        this.setState({ loadingUpdate: false })
      })
    })
  }

  validateAmount = (rule, value, callback) => {
    const { isTBDTriggered } = this.state
    const { form } = this.props
    const field = rule && rule.field ? rule.field : null
    const amtText = field === 'rate_value'
      ? 'Hourly Rate'
      : field === 'budget_value'
        ? 'Service Booking Amount'
        : ''

    if (isTBDTriggered && field === 'budget_value') {
      callback()
    } else if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Please enter ${amtText}`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${amtText} value must be valid`))
    } else {
      callback()
    }
  }

  resetFiles = () => {
    const files = {
      [FILE_PLAN]: Object.assign({}, DefaultFileItem),
      [FILE_SVC]: Object.assign({}, DefaultFileItem),
      [FILE_IMP_REPORT]: Object.assign({}, DefaultFileItem),
      [FILE_PP_REPORT]: Object.assign({}, DefaultFileItem)
    }

    this.setState({ files })
  }

  validateDate = (rule, value, callback) => {
    const { form } = this.props
    const { item, allBudgets } = this.state

    const sd1 = rule.field === 'period_start_date' ? value.clone() : form.getFieldValue('period_start_date')
    const ed1 = rule.field === 'period_end_date' ? value.clone() : form.getFieldValue('period_end_date')

    if (sd1 && ed1) {
      if (sd1.isAfter(ed1)) {
        callback(new Error('End date is before start date.'))
      }
    }

    const overlapping = this.isDateOverlapping(value)

    if (overlapping.result === true) {
      callback(new Error(`Date is overlapping with set "${overlapping.period}"`))
    }

    callback()
  }

  validateDigit = (rule, value, callback) => {
    const { isTBDTriggered } = this.state
    const { form } = this.props
    const field = rule && rule.field ? rule.field : null
    const amtText = field === 'allocated_hrs'
      ? 'Allocated Hours'
      : field === 'actual_hrs'
        ? 'Usable Hours'
        : ''

    if (isTBDTriggered && field !== 'allocated_hrs') {
      callback()
    } else if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Please enter ${amtText}`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${amtText} value must be valid`))
    } else {
      callback()
    }
  }

  validateToggleSubmit = () => {
    let result = true
    const { files, item, isImpReportToggle, isPPReportToggle } = this.state

    if (isImpReportToggle) {
      if (item.file_imp_id) {
        result = result && true
      } else if (files[FILE_IMP_REPORT].fileUploadedItem.uid) {
        result = result && true
      } else {
        files[FILE_IMP_REPORT].uploadErrorMsg = FILE_NO_REPORT_FILE
        result = result && false
      }
    }

    if (isPPReportToggle) {
      if (item.file_pp_id) {
        result = result && true
      } else if (files[FILE_PP_REPORT].fileUploadedItem.uid) {
        result = result && true
      } else {
        files[FILE_PP_REPORT].uploadErrorMsg = FILE_NO_REPORT_FILE
        result = result && false
      }
    }

    if (!result) {
      this.handleSaveValidateError()
    }

    return result
  }

  isDateOverlapping (value) {
    const { item, allBudgets } = this.state

    let r = { result: null }

    if (validator.isNotEmptyArray(allBudgets)) {
      for (let i = 0; i < allBudgets.length; i++) {
        const p = allBudgets[i]

        if (p.id === item.id) {
          continue
        } else if (p.id && p.active) {
          const date = moment.isMoment(value) ? value.clone() : moment(value)
          const std = moment.isMoment(p.period_start_date) ? p.period_start_date : moment(p.period_start_date)
          const etd = moment.isMoment(p.period_end_date) ? p.period_end_date : moment(p.period_end_date)

          if (std.isSameOrBefore(date) && etd.isSameOrAfter(date)) {
            return {
              result: true,
              period: `${moment(p.period_start_date).format('DD/MM/YYYY')} - ${moment(p.period_end_date).format('DD/MM/YYYY')}`
            }
          }
        }
      }
    }

    r.result = false
    return r
  }

  hasAccess = (accessLevel) => {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { visible, clientId, form, onClose } = this.props
    const { isEdit, initialisedStartDate, isImpReportToggle, isPPReportToggle, isTBDTriggered, isBookingDetailNotRequired, files, item, loadingBudget, loadingCats, loadingCurrentCats, loadingRate, loadingUpdate, allBudgets, rateSetErrorMsg, currentCategories } = this.state
    const { getFieldDecorator, getFieldValue, resetFields } = form

    const title = isEdit ? 'Edit Plan' : 'Add Plan'
    const catId = form.getFieldValue('cat_id') || item.cat_id

    return (
      <SideModal
        style={{width: '600px'}}
        key={`sidebudget_${isEdit ? item.id : 'add'}`}
        showModal={visible}
        title={title}
        onClose={() => this.onClose()}
        buttons={
          <Loading loading={loadingUpdate} blur key='btnrcs'>
            { this.hasAccess([Permissions.PARTICIPANT.FUNDINGPLAN.CREATE, Permissions.PARTICIPANT.FUNDINGPLAN.UPDATE])
              ? <Button key='pmrok' type='primary' onClick={(e) => this.onSubmit(e)}> {isEdit ? 'Update' : 'Add'}</Button>
              : null }
          </Loading>
        }
      >
        <Loading loading={loadingUpdate} blur>
          <Form layout='vertical' className='budget-form'>
            <FormItem label='Funder'>
              {getFieldDecorator('funder_fullname', {
                initialValue: item.funder_fullname,
              })(
                <Input readOnly />
              )}
            </FormItem>

            <FormItem label='Plan Start Date'>
              {getFieldDecorator('period_start_date', {
                initialValue: item.period_start_date ? formatter.toMoment(item.period_start_date) : initialisedStartDate,
                rules: [
                  { required: true, message: 'Please enter start date' },
                  { validator: this.validateDate }
                ]
              })(
                <DatePicker
                  defaultPickerValue={moment(new Date())}
                  format={dateFormat}
                  onChange={this.handleStartDateChange}
                />
              )}
            </FormItem>

            <FormItem label='Plan End Date'>
              {getFieldDecorator('period_end_date', {
                initialValue: item.period_end_date ? formatter.toMoment(item.period_end_date) : null,
                rules: [
                  { required: true, message: 'Please enter end date' },
                  { validator: this.validateDate }
                ]
              })(
                <DatePicker defaultPickerValue={moment(new Date())} format={dateFormat} />
              )}
            </FormItem>

            <Spin spinning={loadingCats || loadingCurrentCats}>
              <FormItem label='Support Item'>
                {getFieldDecorator('cat_id', {
                  initialValue: item.cat_id || null,
                  rules: [
                    { required: true, message: 'Please select support item' }
                  ]
                })(
                  <Select
                    showSearch
                    style={{ width: '100%' }}
                    placeholder='Select support item'
                    optionFilterProp='children'
                    notFoundContent='No available support items'
                    filterOption={(input, option) => this.filterOptions(input, option)}
                    disabled={isEdit || loadingCats}
                  >
                    { currentCategories.map((item) => (
                        <Option key={`ccats${item.category_id}`} value={item.category_id}>
                          { `${item.category_identifier ? `[${item.category_identifier}] ` : ''}${item.category_name}` }
                        </Option>)) }
                  </Select>
                )}
              </FormItem>

              <FormItem label={<div>
                <span>Hourly Rate</span>
                <span><Button style={{marginLeft: '10px'}} onClick={() => this.getCurrentHourRate()}>{catId && isEdit ? 'Reset' : 'Get Rate'}</Button></span>
              </div>}
              >
                { validator.isNotEmptyArray(rateSetErrorMsg)
                  ? <div className='error-msg'>
                    { rateSetErrorMsg.map((e, idx) => (
                      <div key={`rsmfs${idx}`}>{e}</div>
                    ))}
                  </div>
                  : null }
                { getFieldDecorator('rate_value', {
                  initialValue: item.rate_value || null,
                  rules: [
                    { validator: this.validateAmount }
                  ]
                })(
                  <Input addonBefore={'$'} style={{width: '170px', textAlign: 'right'}} />
                )}
              </FormItem>
            </Spin>

            <FormItem label='Plan Allocated Hours'>
              { getFieldDecorator('allocated_hrs', {
                initialValue: item.allocated_hrs || null,
                rules: [
                  { required: true, message: ' ' },
                  { validator: this.validateDigit }
                ]
              })(
                <Input style={{width: '170px', textAlign: 'right'}} />
              )}
            </FormItem>

            { isTBDTriggered
              ? <div className='error-msg' key={`tbsfsm`}>{`Disabled fields triggered by TBC will be cleared when saved/updated.`}</div>
              : null }

            <FormItem label={<span>
              <span>Usable Hours</span>
              <span style={{marginLeft: '10px'}}>
                <Switch
                  checkedChildren='TBC'
                  unCheckedChildren='Plan'
                  checked={isTBDTriggered}
                  defaultChecked={isTBDTriggered}
                  onChange={this.handleToggleTBD}
                />
              </span>
            </span>}>
              { getFieldDecorator('actual_hrs', {
                initialValue: item.actual_hrs || null,
                rules: [
                  { required: !isTBDTriggered, message: ' ' },
                  { validator: this.validateDigit }
                ]
              })(
                <Input disabled={isTBDTriggered} style={{width: '170px', textAlign: 'right'}} />
              )}
            </FormItem>

            <FormItem label='SC Fund'>
              {getFieldDecorator('fund_managed_type', {
                initialValue: item.fund_managed_type || null,
                rules: [
                  { required: true, message: 'Please select SC Fund type' }
                ]
              })(
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder='Select SC Fund type'
                  optionFilterProp='children'
                  notFoundContent='No available types'
                  filterOption={(input, option) => this.filterOptions(input, option)}
                  onChange={this.handleChangeFundType}
                >
                  { FundingManagedTypes.map((item) => (
                    <Option key={`fmtbrc${item.value}`} value={item.value}>
                      { item.label }
                    </Option>)) }
                </Select>
              )}
            </FormItem>

            { !isBookingDetailNotRequired
              ? <FormItem label='Service Booking Number'>
                { getFieldDecorator('booking_number', {
                  initialValue: item.booking_number || null,
                  rules: [
                    { required: !isTBDTriggered, message: 'Please enter booking number' }
                  ]
                })(
                  <Input disabled={isTBDTriggered} />
                )}
              </FormItem>
              : null }

            { !isBookingDetailNotRequired
              ? <FormItem label='Service Booking Amount'>
                { getFieldDecorator('budget_value', {
                  initialValue: item.budget_value || null,
                  rules: [
                    { required: !isTBDTriggered, message: ' ' },
                    { validator: this.validateAmount }
                  ]
                })(
                  <Input addonBefore={'$'} disabled={isTBDTriggered} />
                )}
              </FormItem>
              : null }

            <div className='cmsc'>
              <FormItem label='Planner'>
                {getFieldDecorator('cmsc_first_name', {
                  initialValue: isEdit ? item.cmsc_first_name : null,
                  rules: [
                    { min: 1, message: 'Contact name must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact name must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Contact Name' }
                  ]
                })(
                  <Input placeholder='Name' />
                )}

              </FormItem>

              <FormItem>
                {getFieldDecorator('cmsc_org_name', {
                  initialValue: isEdit ? item.cmsc_org_name : null,
                  rules: [
                    { min: 1, message: 'Organisation name must be between 1 and 256 characters' },
                    { max: 256, message: 'Organisation name must be between 1 and 256 characters' },
                    { whitespace: true, message: 'Please enter Organisation Name' }
                  ]
                })(
                  <Input placeholder='Organisation' />
                )}

              </FormItem>

              <FormItem>
                {getFieldDecorator('cmsc_contact', {
                  initialValue: isEdit ? item.cmsc_contact : null,
                  rules: [
                    { min: 1, message: 'Contact contact must be between 1 and 128 characters' },
                    { max: 128, message: 'Contact contact must be between 1 and 128 characters' },
                    { whitespace: true, message: 'Please enter Contact contact ' }
                  ]
                })(
                  <Input placeholder='Contact' />
                )}
              </FormItem>

              <FormItem>
                {getFieldDecorator('cmsc_email', {
                  initialValue: isEdit ? item.cmsc_email : null,
                  rules: [
                    { type: 'email', message: 'Please provide a valid Email' }
                  ]
                })(
                  <Input placeholder='Email' />
                )}
              </FormItem>

              { isEdit
                ? <div className='files'>
                  <div className='title-span'>
                    <FormItem label='Documents' />
                  </div>

                  {/** NDIS Plan File */}
                  { isEdit && !item.is_overdue
                    ? <div>
                      <FormItem label='NDIS Plan' />
                      { item.file_plan_id
                        ? <Tooltip
                          mouseLeaveDelay={0}
                          title={`Download ${item.file_plan_label ? `"${item.file_plan_label}" ` : ''}${item.file_plan_name ? ` - ${item.file_plan_name}` : ''}`}
                        >
                          <Button style={{backgroundColor: '#FF0000'}} onClick={() => this.handleDownload(item.file_plan_url)}>
                            <Icon type='download' /> { 'Download' }
                          </Button>
                        </Tooltip>
                        : <div>
                          { files[FILE_PLAN].fileUploadedItem.uid
                            ? <div className='input-span'>
                              <FormItem>
                                {getFieldDecorator('file_ndis_label', {
                                  initialValue: isEdit ? item.file_ndis_label : null,
                                  rules: [
                                    { required: true, message: 'Please enter NDIS Plan file label' }
                                  ]
                                })(
                                  <Input placeholder='Label of NDIS Plan file' />
                                )}
                              </FormItem>
                            </div>
                            : null }
                          <Upload
                            { ...DefaultUploadConfig }
                            onRemove={(file) => this.fileRemove(file, FILE_PLAN)}
                            onChange={(file) => this.fileChange(file, FILE_PLAN)}
                            beforeUpload={(file) => this.fileSet(file, FILE_PLAN)}
                            fileList={files[FILE_PLAN].fileList}
                          >
                            <Button>
                              <Icon type='upload' /> { files[FILE_PLAN].fileUploadedItem.uid ? `Change File` : `Select File` }
                            </Button>
                          </Upload>

                          { files[FILE_PLAN].uploadErrorMsg
                            ? <div className='error-msg'>{files[FILE_PLAN].uploadErrorMsg}</div>
                            : null }
                        </div> }
                    </div>
                    : null }

                  {/** Service Agreement */}
                  { isEdit && !item.is_overdue
                    ? <div className='title-span'>
                      <FormItem label='Service Agreement' />
                      { item.file_svc_id
                        ? <Tooltip
                          mouseLeaveDelay={0}
                          title={`Download ${item.file_svc_label ? `"${item.file_svc_label}" ` : ''}${item.file_svc_name ? ` - ${item.file_svc_name}` : ''}`}
                        >
                          <Button style={{backgroundColor: '#FF0000'}} onClick={() => this.handleDownload(item.file_svc_url)}>
                            <Icon type='download' /> { 'Download' }
                          </Button>
                        </Tooltip>
                        : <div>
                          { files[FILE_SVC].fileUploadedItem.uid
                            ? <div className='input-span'>
                              <FormItem>
                                {getFieldDecorator('file_svc_label', {
                                  initialValue: isEdit ? item.file_svc_label : null,
                                  rules: [
                                    { required: true, message: 'Please enter Service Agreement file label' }
                                  ]
                                })(
                                  <Input placeholder='Label of Service Agreement file' />
                                )}
                              </FormItem>
                            </div>
                            : null }
                          <Upload
                            { ...DefaultUploadConfig }
                            onRemove={(file) => this.fileRemove(file, FILE_SVC)}
                            onChange={(file) => this.fileChange(file, FILE_SVC)}
                            beforeUpload={(file) => this.fileSet(file, FILE_SVC)}
                            fileList={files[FILE_SVC].fileList}
                          >
                            <Button>
                              <Icon type='upload' /> { files[FILE_SVC].fileUploadedItem.uid ? `Change File` : `Select File` }
                            </Button>
                          </Upload>

                          { files[FILE_SVC].uploadErrorMsg
                            ? <div className='error-msg'>{files[FILE_SVC].uploadErrorMsg}</div>
                            : null }
                        </div> }
                    </div>
                    : null }

                  {/** Implementation Report */}
                  <div className={item.is_overdue ? '' : 'title-span'}>
                    <FormItem label='Implementation Report' />

                    <FormItem>
                      {getFieldDecorator('is_imp_report_done', {
                        initialValue: isImpReportToggle,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          disabled={item.is_overdue}
                          onChange={(e) => this.handleTogglePlanStatus(e, FILE_IMP_REPORT)}
                          checkedChildren='Completed'
                          unCheckedChildren='Not Done'
                        />
                      )}
                    </FormItem>

                    { item.is_overdue
                      ? null
                      : item.file_imp_id && isImpReportToggle
                        ? <Tooltip
                          mouseLeaveDelay={0}
                          title={`Download ${item.file_imp_label ? `"${item.file_imp_label}" ` : ''}${item.file_imp_name ? ` - ${item.file_imp_name}` : ''}`}
                        >
                          <Button style={{backgroundColor: '#FF0000'}} onClick={() => this.handleDownload(item.file_imp_url)}>
                            <Icon type='download' /> { 'Download' }
                          </Button>
                        </Tooltip>
                        : <div>
                          { files[FILE_IMP_REPORT].fileUploadedItem.uid
                            ? <div className='input-span'>
                              <FormItem>
                                {getFieldDecorator('file_imp_label', {
                                  initialValue: isEdit ? item.file_imp_label : null,
                                  rules: [
                                    { required: true, message: 'Please enter Implementation Report file label' }
                                  ]
                                })(
                                  <Input placeholder='Label of Implementation Report file' />
                                )}
                              </FormItem>
                            </div>
                            : null }
                          { isImpReportToggle
                            ? <Upload
                              { ...DefaultUploadConfig }
                              onRemove={(file) => this.fileRemove(file, FILE_IMP_REPORT)}
                              onChange={(file) => this.fileChange(file, FILE_IMP_REPORT)}
                              beforeUpload={(file) => this.fileSet(file, FILE_IMP_REPORT)}
                              fileList={files[FILE_IMP_REPORT].fileList}
                            >
                              <Button>
                                <Icon type='upload' /> { files[FILE_IMP_REPORT].fileUploadedItem.uid ? `Change File` : `Select File` }
                              </Button>
                            </Upload>
                            : null }

                          { files[FILE_IMP_REPORT].uploadErrorMsg
                            ? <div className='error-msg'>{files[FILE_IMP_REPORT].uploadErrorMsg}</div>
                            : null }
                        </div> }
                  </div>

                  {/** Progress Report */}
                  <div className='title-span'>
                    <FormItem label='Progress Report' />

                    <FormItem>
                      {getFieldDecorator('is_pp_report_done', {
                        initialValue: isPPReportToggle,
                        valuePropName: 'checked'
                      })(
                        <Switch
                          disabled={item.is_overdue}
                          onChange={(e) => this.handleTogglePlanStatus(e, FILE_PP_REPORT)}
                          checkedChildren='Completed'
                          unCheckedChildren='Not Done'
                        />
                      )}
                    </FormItem>

                    { item.is_overdue
                      ? null
                      : item.file_pp_id && isImpReportToggle
                        ? <Tooltip
                          mouseLeaveDelay={0}
                          title={`Download ${item.file_pp_label ? `"${item.file_pp_label}" ` : ''}${item.file_pp_name ? ` - ${item.file_pp_name}` : ''}`}
                        >
                          <Button style={{backgroundColor: '#FF0000'}} onClick={() => this.handleDownload(item.file_pp_url)}>
                            <Icon type='download' /> { 'Download' }
                          </Button>
                        </Tooltip>
                        : <div>
                          { files[FILE_PP_REPORT].fileUploadedItem.uid
                            ? <div className='input-span'>
                              <FormItem>
                                {getFieldDecorator('file_pp_label', {
                                  initialValue: isEdit ? item.file_pp_label : null,
                                  rules: [
                                    { required: true, message: 'Please enter Progress Report file label' }
                                  ]
                                })(
                                  <Input placeholder='Label of Progress Report file' />
                                )}
                              </FormItem>
                            </div>
                            : null }

                          { isPPReportToggle
                            ? <Upload
                              { ...DefaultUploadConfig }
                              onRemove={(file) => this.fileRemove(file, FILE_PP_REPORT)}
                              onChange={(file) => this.fileChange(file, FILE_PP_REPORT)}
                              beforeUpload={(file) => this.fileSet(file, FILE_PP_REPORT)}
                              fileList={files[FILE_PP_REPORT].fileList}
                            >
                              <Button>
                                <Icon type='upload' /> { files[FILE_PP_REPORT].fileUploadedItem.uid ? `Change File` : `Select File` }
                              </Button>
                            </Upload>
                            : null }

                          { files[FILE_PP_REPORT].uploadErrorMsg
                            ? <div className='error-msg'>{files[FILE_PP_REPORT].uploadErrorMsg}</div>
                            : null }
                        </div> }

                  </div>
                </div>
                : null }

                <div className='section-block'/>
            </div>
          </Form>
        </Loading>
      </SideModal>
    )
  }

  fileChange = (info, type) => {
    if (info && info.file) {
      const f = info.file
      const { percent, response: r = null, status, uid } = f
      if (percent === 100 && r && status && status === 'done') {
        const data = {
          fileName: r.filePath ? r.filePath.filename : '',
          fileUrl: r.fileUrl,
          filePath: r.filePath ? r.filePath.path : '',
          uid: uid
        }

        const { files } = this.state
        files[type] = { ...files[type], fileUploadedItem: data, uploadErrorMsg: '' }
        this.setState({ files })
      }
    }
  }

  fileSet = (file, type) => {
    const { files } = this.state

    let result = false
    if (file && validator.isValidUploadType(file.type)) {
      files[type] = { ...files[type], fileList: [file], uploadErrorMsg: '' }
      result = true
    } else {
      files[type] = { ...files[type], uploadErrorMsg: FileUploadMsg.UploadMsgWrongFormatDOC  }
    }

    this.setState({ files })
    return result
  }

  fileRemove = (file, type) => {
    const { files } = this.state
    const fileList = files[type].fileList

    const fileIndex = fileList.indexOf(file)

    if (fileIndex > -1) {
      let newFileList = fileList.slice()
      newFileList.shift()

      files[type].fileList = newFileList
      files[type].fileUploadedItem = {}

      this.setState({ files })
    }
  }

  handleDownload = (url) => {
    window.location.href = url
  }

  hasAccess = (accessLevel) => {
    return auth.hasAccess(accessLevel)
  }
}

export default Form.create()(AddBudgetItemModal)
