
// import ClientList from './containers/Client/List'
// import ClientPage from './containers/Client/Page'
import Dashboard from './containers/Dashboard'

// Participant
import ClientList from './containers/Client/List'
import ClientPage from './containers/Client/Page'

// Employee
import EmployeeList from './containers/Employee/List'
import EmployeePage from './containers/Employee/Page'

// // Credit
// import CreditList from './containers/Credit/List'
// import CreditPage from './containers/Credit/Page'

// Funder
import FunderList from './containers/Funder/List'
import FunderPage from './containers/Funder/Page'

// // Invoice
// import InvoiceList from './containers/Invoice/List'
// import InvoicePage from './containers/Invoice/Page'
// import InvoiceManage from './containers/Invoice/Manage'
// import InvoiceExport from './containers/Invoice/Export'

// Provider
import ProviderList from './containers/Provider/List'
import ProviderPage from './containers/Provider/Page'

// Billing
import BillingExport from './containers/Billings/Export'
import BillingList from './containers/Billings/List'
import BillingManage from './containers/Billings/Manage'
// import FunderPage from './containers/Funder/Page'

// Report
import ReportClientPlanDetails from './containers/Report/ClientPlanDetails'
import ReportClientPlanExpiry from './containers/Report/ClientPlanExpiry'
import ReportClientReportDue from './containers/Report/ClientPlanReportDue'
import ReportDayCountLastJob from './containers/Report/DayCountLastJob'
import ReportTaskDue from './containers/Report/TaskDueReport'
import ReportCustomGenerate from './containers/Report/GenerateRequest'
import ReportRecipientSetting from './containers/Report/RecipientSetting'

// Task
import TaskPage from './containers/Task/Page'

// Settings
import Setting from './containers/Setting'
import SettingContacts from './containers/Setting/Contacts'
import SettingCustomIdf from './containers/Setting/CustomIdentifier'
import SettingFile from './containers/Setting/File'
import SettingProfile from './containers/Setting/Profile'
import SettingRateSet from './containers/Setting/RateSet'
import SettingRateSetEdit from './containers/Setting/RateSet/RateSetEdit'
import SettingReason from './containers/Setting/Reasons'
// import SettingReasonEdit from './containers/Setting/Reasons/Page'
// import SettingImportInvoice from './containers/Setting/InvoiceImport'
// import SettingRateSet from './containers/Setting/RateSet'
// import SettingRateCategoryEdit from './containers/Setting/RateSet/CategoriesEdit'
// import SettingRateSetEdit from './containers/Setting/RateSet/RateSetEdit'
import SettingServices from './containers/Setting/Services'
import SettingTask from './containers/Setting/Task'
import SettingOther from './containers/Setting/Other'

export const routes = [
  {
    component: Dashboard,
    exact: true,
    icon: 'dashboard.svg',
    menu: true,
    name: 'Dashboard',
    path: '/'
  },
  {
    component: Setting,
    exact: true,
    icon: 'admin.svg',
    name: 'Admins',
    path: '/settings'
  },
  {
    component: ClientList,
    name: 'Participant',
    path: '/participants',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Participant',
    path: '/participants/:id',
    exact: true
  },
  {
    component: ClientPage,
    name: 'Participant',
    path: '/participants/:id/:type',
    exact: true
  },
  {
    component: EmployeeList,
    name: 'Employee',
    path: '/employees',
    exact: true
  },
  {
    component: EmployeePage,
    name: 'Employee',
    path: '/employees/:id',
    exact: true
  },
  {
    component: EmployeePage,
    name: 'Employee',
    path: '/employees/:id/:type',
    exact: true
  },
  {
    component: FunderList,
    name: 'Funder',
    path: '/funders',
    exact: true
  },
  {
    component: FunderPage,
    name: 'Funder',
    path: '/funders/:id',
    exact: true
  },
  {
    component: FunderPage,
    name: 'Funder',
    path: '/funders/:id/:type',
    exact: true
  },
  {
    component: ProviderList,
    name: 'Providers',
    path: '/providers',
    exact: true
  },
  {
    component: ProviderPage,
    name: 'Providers',
    path: '/providers/:id',
    exact: true
  },
  {
    component: ProviderPage,
    name: 'Providers',
    path: '/providers/:id/:type',
    exact: true
  },
  {
    component: BillingExport,
    name: 'Billings',
    path: '/billings/redownload/:code',
    exact: true
  },
  {
    component: BillingList,
    name: 'Billings',
    path: '/billings',
    exact: true
  },
  {
    component: BillingManage,
    name: 'Billings',
    path: '/billings/manage/:type/:code',
    exact: true
  },
  {
    component: ReportDayCountLastJob,
    name: 'ReportDayCountLastJob',
    path: '/reports/ppt-day-counts',
    exact: true
  },
  {
    component: ReportClientPlanDetails,
    name: 'ReportClientPlanDetails',
    path: '/reports/ppt-plan-details',
    exact: true
  },
  {
    component: ReportClientPlanExpiry,
    name: 'ReportClientPlanExpiry',
    path: '/reports/ppt-plan-expiry',
    exact: true
  },
  {
    component: ReportClientReportDue,
    name: 'ReportClientReportDue',
    path: '/reports/ppt-report-due',
    exact: true
  },
  {
    component: ReportTaskDue,
    name: 'ReportTaskDue',
    path: '/reports/task-due',
    exact: true
  },
  {
    component: ReportCustomGenerate,
    name: 'ReportCustomGenerate',
    path: '/reports/custom-generate',
    exact: true
  },
  {
    component: ReportRecipientSetting,
    name: 'ReportRecipientSetting',
    path: '/reports/setting',
    exact: true
  },
  {
    component: TaskPage,
    name: 'Tasks',
    path: '/tasks/:id',
    exact: true
  },
  {
    component: TaskPage,
    name: 'Tasks',
    path: '/tasks/:id/:type',
    exact: true
  },
  {
    component: SettingContacts,
    name: 'Setting Contacts Export',
    path: '/settings/contacts',
    exact: true
  },
  {
    component: SettingCustomIdf,
    name: 'Setting Custom Identifier',
    path: '/settings/custom-identifier',
    exact: true
  },
  {
    component: SettingFile,
    name: 'Setting Files',
    path: '/settings/files',
    exact: true
  },
  {
    component: SettingProfile,
    name: 'Setting Profile',
    path: '/settings/profile',
    exact: true
  },
  {
    component: SettingOther,
    name: 'Setting Other',
    path: '/settings/others',
    exact: true
  },
  {
    component: SettingRateSet,
    name: 'Setting Rate Set',
    path: '/settings/rate-set',
    exact: true
  },
  {
    component: SettingRateSet,
    name: 'Setting Rate Set',
    path: '/settings/rate-set/:type',
    exact: true
  },
  {
    component: SettingRateSetEdit,
    name: 'Setting Rate Set Edit',
    path: '/settings/rate-set/edit/:code',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons',
    exact: true
  },
  {
    component: SettingReason,
    name: 'Setting Reason',
    path: '/settings/reasons/:type',
    exact: true
  },
  {
    component: SettingServices,
    name: 'Setting Services',
    path: '/settings/services',
    exact: true
  },
  {
    component: SettingServices,
    name: 'Setting Services',
    path: '/settings/services/:type',
    exact: true
  },
  {
    component: SettingTask,
    name: 'Setting Task',
    path: '/settings/tasks',
    exact: true
  },
]
