/* global google */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, employeeService, settingGeneralService, settingReasonService, logService } from '../../../services'
import { fetchingEmployees, setRefreshActivityLog } from '../../../states/actions'
import { AustralianStates } from '../../../constants'
import { formatter, log, trigger, validator, uploader } from '../../../util'
import Moment from 'moment-timezone'
import { cloneDeep, isEqual } from 'lodash'

// UI
import { CustomIdentifierList, Loading, List, Page, Panel, FileTypeUpload } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'
import Row from 'antd/lib/row'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import DatePicker from 'antd/lib/date-picker'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'

import ActivityLog from '../ActivityLog'

import './styles.css'
import { apiHostname } from '../../../config'

const { confirm, error } = Modal
const { Item: FormItem } = Form
const Option = Select.Option
const TabPane = Tabs.TabPane
const { TextArea } = Input

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'
const funderModule = 'funder'

const TabList = [
  { tabId: 1, path: '/info' },
  // { tabId: 2, path: '/custom-identifier' },
  { tabId: 8, path: '/logs' }
]

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 4 },
  wrapperCol: { sm: 14, md: 14, lg: 19 }
}
const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

const longItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 8 },
  wrapperCol: { sm: 18, md: 18, lg: 16 }
}

const specialFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 11 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

const sideBySideExtraFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 10 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class Employee extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      item: {},
      itemOri: {},
      isInactiveUpdate: false,
      loading: false,
      latitude: null,
      longitude: null,
      showSave: false,
      showEdit: true,
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      currentSubTab: '1',
      showInactiveModal: false,

      // settings
      genders: [],
      heights: [],
      languages: [],
      reasons: [],
      relationships: [],
      serviceProviders: [],
      visaStatus: [],
      settings: []
    }
    this.googleAddress = null
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this)
  }

  componentDidMount () {
    if (this.isEdit()) {
      this.fetchEmployee()
    }

    this.fetchSettings()
    this.fetchReasons()

    this.googleAddress = new google.maps.places.Autocomplete(
      this.addressInput,
      { types: ['geocode'] }
    )
    this.googleAddress.addListener('place_changed', this.handlePlaceChanged)
  }

  handlePlaceChanged () {
    const place = this.googleAddress.getPlace()
    let suburb = ''
    let postcode = ''
    let state = ''

    for (var i = 0; i < place.address_components.length; i++) {
      var addressType = place.address_components[i].types[0]
      if (addressType === 'locality') {
        suburb = place.address_components[i]['long_name']
      } else if (addressType === 'postal_code') {
        postcode = place.address_components[i]['long_name']
      } else if (addressType === 'administrative_area_level_1') {
        state = place.address_components[i]['long_name']
      }
    }

    this.setState({ latitude: place.geometry.location.lat(), longitude: place.geometry.location.lng() })
    this.props.form.setFieldsValue({ suburb, address: place.formatted_address, postcode, state })
  }

  render () {
    const { match, history } = this.props
    const { currentTab, currentSubTab, item, loading, showSave, showEdit, showFundManagerTab } = this.state
    const employeeId = this.getId()
    const isInfoTab = currentTab === '1' && currentSubTab === '1'
    const isItem = item && item.id

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header title={!this.isEdit()
              ? 'Invalid Page'
              : loading
                ? <div className='client-panel-header-skeleton' style={{ width: 200 }} />
                : showEdit
                  ? `${item.first_name}${item.last_name ? ` ${item.last_name}` : ''} (View Only)`
                  : showSave
                    ? `${item.first_name}${item.last_name ? ` ${item.last_name}` : ''} (Edit Mode)`
                    : 'Support Coordinator'}>

            { this.isEdit() && isItem && this.hasAccess(Permissions.EMPLOYEE.INFO.DELETE)
              ? showSave && isInfoTab
                ? (
                  <div className='btn btn-ghost' onClick={this.handlePreDelete} style={{ marginRight: 20 }}>
                    Delete
                  </div>
                )
                : null
              : null }

            { showEdit && this.isEdit() && isItem && this.hasAccess(Permissions.EMPLOYEE.INFO.UPDATE) && isInfoTab
              ? (
                <div className='btn' onClick={this.handleEditButton}>
                Edit
                </div>
              )
              : null }

            {( (showSave && this.isEdit() && isItem && this.hasAccess(Permissions.PARTICIPANT.INFO.UPDATE))) && isInfoTab
              ? (
                <div className='btn' onClick={loading ? () => {} : this.handleSave}>
                Save
                </div>)
              : null }

            <div className='btn' onClick={history.goBack}>Back</div>
          </Page.Header>

          <div className='employee-panel'>
            { this.isEdit() && this.hasAccess(Permissions.EMPLOYEE.INFO.READ)
              ? <div className='employee-panel-header'>
                { loading
                  ? <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <div className='employee-panel-header-skeleton-avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='employee-panel-header-skeleton' />
                    </Col>
                    <Col lg={5}>
                      <div className='employee-panel-header-skeleton' />
                    </Col>
                    <Col lg={7}>
                      <div className='employee-panel-header-skeleton' />
                    </Col>
                  </Row>
                  : <Row>
                    <Col lg={3} style={{ textAlign: 'center' }}>
                      <img alt='emp-gender' src={process.env.PUBLIC_URL + '/img/' + (item.gender ? (item.gender.toLowerCase() === 'male' ? 'male.png' : item.gender.toLowerCase() === 'female' ? 'female.png' : 'user.png') : 'user.png')} className='avatar' />
                    </Col>
                    <Col lg={5}>
                      <div className='employee-panel-header-label'>Name</div>
                      <div className='employee-panel-header-value'>{item.first_name}{item.last_name ? ` ${item.last_name}` : ''}<div className='employee-panel-header-subvalue'>{ item.acc_ref }</div></div>
                    </Col>
                  </Row> }
              </div>
              : <div className='employee-panel-body'>This is an invalid page</div>
            }

            { this.isEdit()
              ? <div className='employee-panel-body'>
                <Tabs
                  defaultActiveKey={currentTab}
                  onChange={this.handleTabChange}
                >
                  <TabPane tab='Support Coordinator Info' key='1'>
                    <Tabs
                      type='card'
                      defaultActiveKey={currentSubTab}
                      onChange={this.handleSubTabChange}
                    >
                      <TabPane tab='Info' key='1'>
                        { this.infoTab() }
                      </TabPane>
                      { this.isEdit() && this.hasAccess(Permissions.EMPLOYEE.INFO.READ)
                        ? <TabPane tab='Custom Identifier' key='2'>
                          <CustomIdentifierList key={`cftab${currentTab}`} genreId={employeeId} genre={'employee'} history={this.props.history} permission={Permissions.EMPLOYEE.INFO.UPDATE} />
                        </TabPane>
                        : null }
                    </Tabs>
                  </TabPane>
                  { this.isEdit() && this.hasAccess(Permissions.EMPLOYEE.INFO.READ)
                    ? <TabPane tab='Activity Log' key='8'>
                      <ActivityLog key={`actab${currentTab}`} employeeId={employeeId} history={this.props.history} />
                    </TabPane>
                    : null }
                </Tabs>
              </div>
              : null }
          </div>

        </Page.Content>
      </Page.Body>
    )
  }

  infoTab = () => {
    const { form } = this.props
    const {
      currentAge,
      loading,
      isInactiveUpdate,
      item,
      genders,
      heights,
      languages,
      relationships,
      visaStatus,
      showInactiveModal
    } = this.state

    const { getFieldDecorator } = form

    const isEdit = this.isEdit()

    return <div><Form>
      <Loading loading={loading} blur>
        <Panel title='Support Coordinator Information'>
          <Row>
            { isEdit
              ? <Col lg={12}>
                <FormItem {...sideBySideFormItemLayout} label='Acct Ref'>
                  {getFieldDecorator('acc_ref', {
                    initialValue: item.acc_ref
                  })(
                    <Input readOnly />
                  )}
                </FormItem>
              </Col>
              : null }
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Active'>
                {getFieldDecorator('active', {
                  initialValue: item.active !== undefined ? item.active : true,
                  valuePropName: 'checked'
                })(
                  <Switch
                    onChange={this.handleActiveChange}
                    checkedChildren='Yes'
                    unCheckedChildren='No'
                  />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Name' hasFeedback>
                {getFieldDecorator('first_name', {
                  initialValue: item.first_name,
                  rules: [
                    { min: 2, message: 'First Name must be between 2 and 128 characters' },
                    { max: 128, message: 'First Name must be between 2 and 128 characters' },
                    { required: true, message: 'Please enter first name' },
                    { whitespace: true, message: 'Please enter first name' }
                  ]
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Gender'>
                {getFieldDecorator('gender', {
                  initialValue: item.gender,
                  // rules: [
                  //   { required: true, message: 'Please select gender.' }
                  // ]
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
                    {
                      genders.map((gender) => {
                        return <Option key={`gendes-${gender.id}`} value={gender.value}>{gender.name}</Option>
                      })
                    }
                  </Select>
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <Row>
                <Col lg={19}>
                  <FormItem {...sideBySideExtraFormItemLayout} label='Date Of Birth'>
                    {getFieldDecorator('dob', {
                      initialValue: item.dob ? Moment(item.dob) : null,
                      // rules: [
                      //   { required: true, message: 'Please select dob.' }
                      // ]
                    })(
                      <DatePicker format={dateFormat} onChange={this.handleDOBChange} />
                    )}
                  </FormItem>
                </Col>
                <Col lg={5}>
                  <div className='client_age'>
                    {currentAge} years old
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Email' hasFeedback>
                {getFieldDecorator('email', {
                  initialValue: item.email,
                  rules: [
                    {
                      type: 'email',
                      message: 'Please provide a valid Email'
                    }
                  ]
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>
            {/* <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Height'>
                {getFieldDecorator('height', {
                  initialValue: item.height
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => this.filterOptions(input, option) }>
                    { heights.map((height) => {
                        return <Option key={`hendes-${height.id}`} value={height.value}>{height.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>
            </Col> */}
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Visa Status'>
                {getFieldDecorator('visa_status_id', {
                  initialValue: item.visa_status_id
                })(
                  <Select
                    showSearch
                    filterOption={(input, option) => this.filterOptions(input, option) }>
                    { visaStatus.map((vs) => {
                        return <Option key={`visxts-${vs.id}`} value={vs.id}>{vs.name}</Option>
                      }) }
                  </Select>
                )}
              </FormItem>
            </Col>
          </Row>

          <Row>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Mobile Number'>
                {getFieldDecorator('mobile_phone', {
                  initialValue: item.mobile_phone,
                  rules: [
                    { min: 2, message: 'Mobile Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Mobile Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Phone Number'>
                {getFieldDecorator('phone', {
                  initialValue: item.phone,
                  rules: [
                    { min: 2, message: 'Phone Number must be between 2 and 128 characters' },
                    { max: 128, message: 'Phone Number must be between 2 and 128 characters' },
                    { whitespace: true, message: 'Please enter phone number' }
                  ]
                })(
                  <Input />
                )}
              </FormItem>
            </Col>
          </Row>

          <FormItem {...formItemLayout} label='Address' hasFeedback>
            {getFieldDecorator('address', {
              initialValue: item.address || null,
              rules: [
                { min: 2, message: 'Address must be between 2 and 128 characters' },
                { max: 128, message: 'Address must be between 2 and 128 characters' },
                { whitespace: true, message: 'Please enter address' }
              ]
            })(
              <input type='text-area' rows={2} ref={ref => this.addressInput = ref} className='address' />
            )}
          </FormItem>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Suburb'>
                {getFieldDecorator('suburb', {
                  initialValue: item.suburb || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='State'>
                {getFieldDecorator('state', {
                  initialValue: item.state || null
                })(
                  <Select placeholder='Please select a state' disabled>
                    {
                      AustralianStates.map((states) => (
                        <Option key={`state${states.value}`} value={states.value}>{states.name}</Option>
                      ))
                    }
                  </Select>
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12} style={{ display: 'none' }}>
            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Postcode'>
                {getFieldDecorator('postcode', {
                  initialValue: item.postcode || null
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

            <Col lg={12}>
              <FormItem {...sideBySideFormItemLayout} label='Country'>
                {getFieldDecorator('country', {
                  initialValue: 'Australia'
                })(
                  <Input disabled />
                )}
              </FormItem>
            </Col>

          </Row>

          <Row gutter={12}>
            <FormItem {...formItemLayout} label='Unit/Building (Optional)' hasFeedback>
              {getFieldDecorator('unit_building', {
                initialValue: item.unit_building,
                rules: [
                  { min: 2, message: 'Unit/Building must be between 2 and 128 characters' },
                  { max: 128, message: 'Unit/Building must be between 2 and 128 characters' },
                  { whitespace: true, message: 'Please enter unit/building info' }
                ]
              })(
                <Input placeholder='Please Enter Unit No/Building Name' />
              )}
            </FormItem>
          </Row>
        </Panel>

        <Panel title='Support Coordinator Ability'>
          <Row>
            <Col lg={24}>
              <FormItem {...formItemLayout} label='Preferred Languages'>
                {getFieldDecorator('preference.languages', {
                  initialValue: item.preference && validator.isNotEmptyArray(item.preference.languages) ? item.preference.languages : []
                })(
                  <Select
                    mode='multiple'
                    showSearch
                    filterOption={(input, option) => this.filterOptions(input, option)}
                  >
                    {
                      languages.map((language) => {
                        return <Option key={`lang${language.id}`} value={language.id}>{language.name}</Option>
                      })
                    }
                  </Select>
              )}
              </FormItem>
            </Col>
          </Row>
          <Row>
            <Col lg={24}>
              <FormItem {...formItemLayout} label='Private Notes'>
                {getFieldDecorator('private_notes', {
                  initialValue: item.private_notes
                })(
                  <TextArea rows={4} />
                )}
              </FormItem>
            </Col>
          </Row>
        </Panel>
      </Loading>
    </Form>
    </div>
  }

  fetchEmployee = async () => {
    if (!this.hasAccess(Permissions.EMPLOYEE.INFO.READ)) {
      return
    }

    try {
      const refId = this.getRefId()
      let id = null

      this.setState({ loading: true })
      const item = await employeeService.getRef(refId)

      if (item && item.id) {
        id = item.id
        this.handleAgeUpdate(item.dob)
        this.setState({
          item,
          itemOri: cloneDeep(item),
          loading: false
        })
      } else {
        notify.error('Unable to load successfully', 'Unable to load Support Coordinator successfully. Please try again later.')
      }
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load Support Coordinator successfully. Please try again later.')
    }
  }

  fetchReasons = async () => {
    const filter = {}
    filter.type = { condition: '=', value: 'inactive-employee' }

    const reasons = await settingReasonService.listReasonItemByPage(1, 0, filter)

    if (reasons && validator.isNotEmptyArray(reasons.list)) {
      this.setState({ reasons: reasons.list })
    } else {
      this.setState({ reasons: [] })
    }
  }

  fetchSettings = async () => {
    const filter = {}
    filter.identifier = {
      $or: [
        { condition: '=', value: 'language' },
        { condition: '=', value: 'gender' },
        { condition: '=', value: 'height' },
        { condition: '=', value: 'relationship' },
        { condition: '=', value: 'visa-status' }
      ]
    }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)
    this.setState({
      settings: settings.list,
      languages: settings.list.filter(item => item.identifier === 'language'),
      genders: settings.list.filter(item => item.identifier === 'gender'),
      heights: settings.list.filter(item => item.identifier === 'height'),
      relationships: settings.list.filter(item => item.identifier === 'relationship'),
      visaStatus: settings.list.filter(item => item.identifier === 'visa-status')
    })
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleActiveChange = (value) => {
    const { form } = this.props
    const { setFieldsValue } = form

    if (this.isEdit()) {
      if (!value) {
        this.handleInactiveReason(true)
      } else {
        this.resetInactiveReasons()
      }

      this.setState({ isInactiveUpdate: !value })
    }
  }

  handleAgeUpdate = (value) => {
    const empDate = Moment(value)
    const currYear = Moment()
    const ageNum = currYear.diff(empDate, 'years')

    this.setState({ currentAge: ageNum })
  }

  handleDOBChange = (e) => {
    this.handleAgeUpdate(e)
  }

  handleInactiveReason = (showInactiveModal, isForceReset = false) => {
    const { form } = this.props
    const { item } = this.state
    this.setState({ showInactiveModal }, () => {
      if (isForceReset) {
        form.setFieldsValue({ active: item.active })
      }
    })
  }

  handleSubTabChange = (index) => {
    this.setState({currentSubTab: index})
  }

  handleTabChange = (index) => {
    const refId = this.getRefId()
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})

    if (tab && tab.tabId) {
      this.props.history.replace(`/employees/${refId}${tab.path}`)
    }
  }

  handlePreDelete = () => {
    const { loading } = this.state
    if (loading) return

    const { handleDelete } = this
    const id = this.getId()

    confirm({
      title: 'Delete this Support Coordinator?',
      content: 'THIS ACTION CANNOT UNDO! Press Ok to continue, Cancel to return',
      async onOk () {
        handleDelete(id)
      },
      onCancel () {
      }
    })
  }

  handleDelete = async (id) => {
    const { fetchingEmployees, history } = this.props

    this.setState({ loading: true })

    const r = await employeeService.remove(id)

    if (r && r.id) {
      notify.success('Deleted successfully', 'Support Coordinator deleted successfully.')
      log.deleteEmployee(id, 'Support Coordinator is deleted.')
      window.location.replace('/employees')
      history.replace('/employees')
      fetchingEmployees(true)
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete Support Coordinator successfully. Please try again later.')
    }

    this.setState({ loading: false })
  }

  handleEditButton = () => {
    this.setState({ showSave: true, showEdit: false })
  }

  handleSave = (e) => {
    e.preventDefault()
    const { loading } = this.state
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    let extraLog = ''

    if (loading) {
      return
    }

    this.setState({ loading: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          const { fetchingEmployees, history } = this.props
          const { item, latitude, longitude, loading, visaStatus } = this.state

          if (longitude && latitude) {
            values.longitude = longitude
            values.latitude = latitude
          }

          try {
            if (this.isEdit()) {
              if (values.active === false) {
                if (values.exited_reason && values.exited_reason !== '') {
                  extraLog += `Inactive Reason "${values.exited_reason}" ${values.exited_reason_note ? `, Inactive Note "${values.exited_reason_note}"` : ''}`
                }
              } else {
                values.exited_reason = null
                values.exited_reason_note = null
              }

              const r = await employeeService.save(item.id, values)

              if (r && r.id) {
                //--- update employee log construction starts
                const prevItem = cloneDeep(this.state.itemOri)
                const currItem = cloneDeep(values)

                const prevPref = prevItem.preference
                const currPref = currItem.preference

                delete prevItem.preference
                delete currItem.preference

                const prefChanges = this.getPreferenceChange(
                  prevPref,
                  currPref,
                  [
                    'languages',
                  ],
                  [
                    'Preferred Languages'
                  ])

                extraLog += prefChanges ? `${extraLog ? ', ' : ''} ${prefChanges}` : ''

                if (prevItem.visa_status_id !== currItem.visa_status_id) {
                  const prevVisa = prevItem.visa_status_name || ''
                  const currVisa = visaStatus.find(e => e.id === currItem.visa_status_id)
                  extraLog += `${extraLog ? ', ' : ''} Visa Status changed from "${prevVisa}" to "${currVisa ? currVisa.name : ''}"`
                }

                log.updateEmployee(
                  r.id,
                  prevItem,
                  currItem,
                  ['preferences', 'exited_reason', 'exited_reason_note', 'visa_status_id'],
                  [],
                  extraLog
                )
                //--- update employee log construction ends

                this.handleSaveSuccessful()
                fetchingEmployees(true)
                await this.fetchEmployee()
              } else {
                this.handleSaveError()
              }
            }

            this.props.setRefreshActivityLog(true)
          } catch (e) {
            console.log('emp save error', e)
            this.handleSaveError()
          }
        }

        this.setState({ loading: false })
      })
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Support Coordinator saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save Support Coordinator successfully. Please try again later.')
  }

  resetInactiveReasons = () => {
    const { form } = this.props
    form.setFieldsValue({ exited_reason: undefined, exited_reason_note: undefined })

    this.setState({ isInactiveUpdate: false })
  }

  validateEmailMultiple = (rule, value, callback, isRequired = false) => {
    const MSG_VALID_EMAIL = 'Please provide a valid email'
    const MSG_ENTER_EMAIL = 'Please enter email'

    if (value === null || value === undefined || value === '' || value.trim() === '') {
      if (isRequired) {
        callback(new Error(MSG_ENTER_EMAIL))
      } else {
        callback()
      }
    } else if (value && value.length > 0 ) {
      if (!validator.isEmailMultiple(value)) {
        callback(new Error(MSG_VALID_EMAIL))
      } else {
        callback()
      }
    } else {
      callback()
    }
  }

  getPreferenceChange = (prevPref, currPref, fields = [], fieldsName = []) => {
    let changeText = ''
    const { settings } = this.state

    if (fields.length === fieldsName.length) {
      for (let i = 0; i < fields.length; i++) {
        const field = fields[i]
        const prev = prevPref[field]
        const curr = currPref[field]

        if (!isEqual(prev, curr)) {
          let prevText = []
          let currText = []
          if (validator.isArray(prev)) {
            for (let j = 0; j < prev.length; j++) {
              const s = settings.find(e => e.id === prev[j])
              if (s && s.id) {
                prevText.push(s.name)
              }
            }
          }

          if (validator.isArray(curr)) {
            for (let j = 0; j < curr.length; j++) {
              const s = settings.find(e => e.id === curr[j])
              if (s && s.id) {
                currText.push(s.name)
              }
            }
          }

          changeText += `${changeText ? ', ' : ''}${fieldsName[i]} from "${prevText.join(', ')}" to "${currText.join(', ')}"`
        }
      }
    }

    return changeText
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }

  getId = () => {
    const { item } = this.state
    return item && item.id ? item.id : ''
  }

  getRefId = () => {
    const { match } = this.props
    const { params } = match
    const { id = '' } = params
    return id
  }
}

const mapDispatchToProps = {
  fetchingEmployees,
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Employee }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(Employee))
