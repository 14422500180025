import jwt from 'jsonwebtoken'
import { localStorage, sessionStorage, notification, validator } from '../util'
import authService from '../services/auth'

const headers = { 'Content-Type': 'application/json' }
const url = '/api'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  setCurrentToken (token) {
    return localStorage.setItem('token', token)
  },
  getCurrentRedirectToken () {
    return localStorage.getItem('redirect-token')
  },
  setCurrentRedirectToken (token) {
    return localStorage.setItem('redirect-token', token)
  },
  getCurrentUser () {
    try {
      const tokenUser = localStorage.getItem('user-token')
      const user = jwt.verify(tokenUser, this.getCurrentToken())

      return user
    } catch (e) {
      authService.forceSignOut()
      console.log('pms cu err', e)
      return {}
    }
  },
  getCurrentUserId () {
    try {
      const tokenUser = localStorage.getItem('user-token')
      const user = jwt.verify(tokenUser, this.getCurrentToken())

      return user && user.id ? user.id : null
    } catch (e) {
      authService.forceSignOut()
      console.log('pms cu err', e)
      return {}
    }
  },
  setCurrentUser (user) {
    const token = this.getCurrentToken()
    const tokenUser = jwt.sign(user, token)
    localStorage.setItem('user-token', tokenUser)
  },
  hasAccess (permission) {
    /** TODO: amend on hasAccess once updated the access right */
    // return true
    try {
      const pms = localStorage.getItem('permissions', '')
      if (permission && pms) {
        const permissions = jwt.verify(pms, this.getCurrentToken())

        if (validator.isNotEmptyArray(permission)) {
          if (validator.isNotEmptyArray(permissions)) {
            return permission.some(e => permissions.indexOf(e) > -1)
          } else {
            return false
          }
        } else {
          return validator.isNotEmptyArray(permissions) ? permissions.indexOf(permission) > -1 : false
        }
      }

      return false
    } catch (e) {
      // console.log('pms err', e)
      return false
    }
  },
  setPermission (permission) {
    try {
      const token = this.getCurrentToken()
      const pms = JSON.stringify(permission)
      const tokenPms = jwt.sign(pms, token)
      localStorage.setItem('permissions', tokenPms)
    } catch (e) {
      // console.log('pms error', e)
    }
  },
  isSignedIn () {
    const token = localStorage.getItem('token')
    return token && token !== 'undefined' && token.length > 0
  },
}
