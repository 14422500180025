import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { cloneDeep, debounce } from 'lodash'
import { clientBudgetItemService, funderService, settingFileService, logService, settingGeneralService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Button, ControlLabel, FileUpload, Loading, List, Notification, Panel, Pager, SectionTitle, SideModal, SearchInput, Spin } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, log, uploader, validator } from '../../../util'
import { apiHostname } from '../../../config'

import AddBudgetItemModal from '../AddBudgetItemModal'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane
const notify = Notification

const Option = Select.Option
const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

export class ClientBudget extends Component {
  constructor (props) {
    super(props)
    this.state = {
      budgets: { list: [], total: 0 },
      currentCategoryList: [],
      currentFunder: {},
      funderList: [],
      initialisedStartDate: null,
      loadingCats: false,
      loadingFunder: false,
      loadingList: false,
      loadingUpdate: false,
      budgetInfo: {},
      showBudgetModal: false,
    }
  }

  componentDidMount () {
    this.fetchAllFunders()
    this.fetchBudget()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchBudget(nextProps.clientId)
      }
    }
  }

  render () {
    const { form, clientId } = this.props
    const { budgets, currentCategoryList, funderList, initialisedStartDate, loadingCats, loadingFunder, loadingList, loadingUpdate, budgetInfo, showBudgetModal } = this.state

    const columns = [
      {
        title: '',
        width: 1,
        render: ({ is_current }) => is_current ? <Icon style={{color: '#4fbc85', fontSize: '15px'}} type='clock-circle' theme='filled' /> : null
      },
      {
        title: 'Start',
        width: 1,
        render: ({ period_start_date }) => <div>{formatter.toShortDate(period_start_date)}</div>
      },
      {
        title: 'End',
        width: 1,
        render: ({ period_end_date }) => <div>{formatter.toShortDate(period_end_date)}</div>
      },
      // {
      //   title: 'Allocated',
      //   width: 1,
      //   render: ({ allocated_hrs }) => <div>{`${allocated_hrs} Hrs`}</div>
      // },
      {
        title: 'Usable',
        width: 1.5,
        render: ({ allocated_hrs, actual_hrs, is_tbd }) => <span>
          {`${is_tbd ? 'TBC' : `${actual_hrs} Hrs`}`}
          <span style={{cursor: 'help', marginLeft: '5px'}}>
            <Tooltip mouseLeaveDelay={0} title={`Allocated Hours: ${allocated_hrs} Hrs`}>
              <Icon type={'info-circle'} style={{fontSize: '11pt', color: '#aaa'}} />
            </Tooltip>
          </span>
        </span>
      },
      {
        title: 'Balance',
        width: 1.5,
        render: ({ remaining_hrs, is_tbd }) => <div>{`${is_tbd ? 'TBC' : `${formatter.toFloatDecimal(remaining_hrs)} Hrs`}`}</div>
      },
      {
        title: 'SC Fund',
        width: 3,
        render: ({ fund_managed_type_name }) => <div>{`${fund_managed_type_name || ''}`}</div>
      },
      {
        title: 'Contact',
        width: 4,
        render: ({ cmsc_first_name, cmsc_last_name, cmsc_org_name, cmsc_contact, cmsc_email }) => {
          const isShowPopover = cmsc_contact || cmsc_first_name || cmsc_org_name || cmsc_email
          return (<span>
            {cmsc_first_name}&nbsp;&nbsp;
            { isShowPopover
              ? <span style={{cursor: 'help'}}>
                <Popover
                content={<div>
                  {cmsc_org_name ? <div>{cmsc_org_name}<br /></div> : null}
                  {cmsc_contact ? <div>{cmsc_contact}<br /></div> : null}
                  {cmsc_email ? <div>{cmsc_email}<br /></div> : null}
                </div>}
                title={`${cmsc_first_name || ''}`}>
                <Icon type='info-circle' />
              </Popover></span>
              : null }
            </span>)
        }
      },
      {
        title: 'Implement Report',
        width: 1,
        render: ({ is_imp_report_done }) => is_imp_report_done
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Progress Report',
        width: 1,
        render: ({ is_pp_report_done }) => is_pp_report_done
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.READ) || this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.UPDATE)
            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
              <div onClick={() => this.showBudgetItemModal(true, item)}>
                <Icon type='form' />
              </div>
            </Tooltip>
            : null }
          { this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.DELETE)
            ? <Tooltip mouseLeaveDelay={0} title='Delete'>
              <Popconfirm
                title='Confirm to remove this plan?'
                onConfirm={() => this.removePlan(item)}
                okText='Yes'
                cancelText='No'
              >
                <Icon type='delete' />
              </Popconfirm>
            </Tooltip>
            : null }
        </div>
      }
    ]

    return (
      <Panel title={'NDIS Plan'}
        subtitle={ this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.CREATE) && !loadingFunder
          ? <div className='btn' onClick={() => this.showBudgetItemModal(true)}>
              Add
          </div>
          : null
        }
      >
        <Skeleton loading={loadingList} active>
          <List cols={columns} rows={budgets.list} />

          <AddBudgetItemModal
            budgetList={budgets.list}
            clientId={clientId}
            initialisedStartDate={initialisedStartDate}
            item={budgetInfo}
            loadingCats={loadingCats}
            categoryList={currentCategoryList}
            visible={showBudgetModal}
            onClose={() => this.showBudgetItemModal(false)}
            onUpdate={() => this.onUpdateBudget()}
          />
        </Skeleton>
      </Panel>
    )
  }

  fetchAllFunders = async () => {
    if (!this.hasAccess(Permissions.FUNDER.INFO.LIST)) return

    this.setState({ loadingFunder: true })

    const r = await funderService.listAllFunders()

    if (r && validator.isArray(r)) {
      const ndisFunder = r.find(e => e.ref_id === 1)
      this.setState({ funderList: r, currentFunder: ndisFunder, loadingFunder: false }, () => {
        if (ndisFunder && ndisFunder.id) {
          this.fetchAvailableCats(ndisFunder.id)
        }
      })
    } else {
      this.setState({ loadingFunder: false })
    }
  }

  fetchBudget = async (cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({ loadingList: true })

    if (!clientId) return

    const r = await clientBudgetItemService.pageAll(clientId)

    if (r && r.list) {
      this.setState({ budgets: r, loadingList: false }, () => {
        this.getInitialisedStartDate()
      })
    } else {
      this.setState({ loadingList: false })
    }
  }

  fetchAvailableCats = async (funderId, startDate = moment(new Date())) => {
    if (funderId && startDate) {
      this.setState({ loadingCats: true })

      const values = {
        funder_id: funderId,
        start_date: startDate
      }
      const r = await funderService.getAllAvailableCats(values)

      if (r && validator.isArray(r)) {
        this.setState({ currentCategoryList: r, loadingCats: false })
      } else {
        this.setState({ loadingCats: false })
      }
    }
  }

  getInitialisedStartDate = () => {
    const { budgets } = this.state
    let initialisedStartDate = null

    const bdgt = cloneDeep(budgets.list)

    // sort with latest period end date on first element
    bdgt.sort((a, b) => {
      if (a.period_end_date > b.period_end_date) {
        return -1
      }

      if (a.period_end_date > b.period_end_date) {
        return 1
      }

      return 0
    })

    if (validator.isNotEmptyArray(bdgt)) {
      initialisedStartDate = moment(bdgt[0].period_end_date).add(1, 'day')
    } else {
      initialisedStartDate = moment(new Date())
    }

    this.setState({ initialisedStartDate })
  }

  handleRemoveSuccessful = () => {
    notify.success('Deleted successfully', 'Participant Plan deleted successfully.')
  }

  handleRemoveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to delete successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to delete successfully', `${e.message}`)
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete Participant Plan successfully. Please try again later.')
    }
  }

  removePlan = async (item) => {
    const { loadingList } = this.state

    if (loadingList) return

    this.setState({ loadingList: true }, async () => {
      const r = await clientBudgetItemService.remove(item.id)

      if (r && r.id) {
        this.handleRemoveSuccessful()
        this.fetchBudget()
      } else {
        this.handleRemoveError(r)
      }
    })
  }

  showBudgetItemModal = (showBudgetModal, budgetInfo = {}) => {
    const { funderList, loadingFunder } = this.state

    let funder = {}
    if (showBudgetModal === true) {
      if (budgetInfo) {
        if (budgetInfo.id) {
          funder = funderList.find(e => budgetInfo.funder_id === e.id)
        } else {
          funder = funderList.find(e => e.ref_id === 1)
          budgetInfo.funder_id = funder && funder.id ? funder.id : null
          budgetInfo.funder_fullname = funder && funder.id ? funder.fullname : null
        }
      }

      this.fetchAvailableCats(budgetInfo.funder_id, budgetInfo.period_start_date || undefined)
    }


    this.setState({ showBudgetModal, budgetInfo })
  }

  onUpdateBudget = () => {
    this.fetchBudget()
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientBudget))
