import React, { Component } from 'react'
import moment from 'moment-timezone'
import { employeeService } from '../../../services'
import { auth, formatter, log, validator } from '../../../util'
import { isEqual } from 'lodash'

import { Button, Loading, SideModal } from '../../../components'
import { Permissions } from '../../../constants'
import notify from '../../../components/Notification'
// import Button from 'antd/lib/button'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Radio from 'antd/lib/radio'
import Modal from 'antd/lib/modal'
import Select from 'antd/lib/select'
import Steps from 'antd/lib/steps'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tooltip from 'antd/lib/tooltip'

import './styles.css'

const { Item: FormItem } = Form
const { confirm, warning } = Modal
const Option = Select.Option
const Step = Steps.Step
const RadioButton = Radio.Button
const RadioGroup = Radio.Group
const { TextArea } = Input

const dateFormat = 'DD/MM/YYYY'
const dbFormat = 'YYYY-MM-DD HH:mm:ss'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 14 }
}

export class AddEmployeeModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allMembers: [],
      loadingUpdate: false,
      visible: false
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { allMembers = [], visible } = nextProps

    return {
      ...prevState,
      allMembers,
      visible
    }
  }

  filterOptions = (input, option) => {
    const text = option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Support Coordinator saved successfully.')
  }

  handleSaveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to save successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to save successfully', `${e.message}`)
    } else {
      notify.error('Unable to save successfully', 'Unable to save Support Coordinator successfully. Please try again later.')
    }
  }

  onClose = () => {
    const { loadingUpdate } = this.state
    const { form, onClose } = this.props
    const { resetFields } = form

    if (loadingUpdate) return

    resetFields()
    onClose()
  }

  onSubmit = async (e) => {
    const { form, onUpdate } = this.props
    const { validateFieldsAndScroll } = form
    const { allMembers, loadingUpdate } = this.state
    const { resetFields } = form

    if (loadingUpdate) return

    this.setState({ loadingUpdate: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          try {
            const r = await employeeService.add(values)

            if (r && r.id) {
              this.handleSaveSuccessful()

              const member = allMembers.find(e => e.id === values.member_id)

              if (member && member.id) {
                const logText = `New Support Coordinator "${member.name}" added from current Admin User.`
                log.addEmployee(r.id, logText)
              }

              onUpdate(r)
              this.onClose()
            } else {
              if (r && r.errors) {
                this.handleSaveError(r)
              } else {
                this.handleSaveError()
              }
            }
          } catch (e) {
            console.log('emp save error', e)
            this.handleSaveError(e)
          }
        }

        this.setState({ loadingUpdate: false })
      })
    })
  }

  render () {
    const { form } = this.props
    const { allMembers, loadingUpdate, visible } = this.state
    const { getFieldDecorator, getFieldValue, resetFields } = form

    const title = 'Add New Support Coordinator'

    return (
      <Modal
        visible={visible}
        width={700}
        title={title}
        onOk={(e) => this.onSubmit(e)}
        onCancel={() => this.onClose()}
        footer={[
          <Button key='ademp-close' ghost feedback={loadingUpdate} onClick={(e) => this.onClose(e)}>Cancel</Button>,
          <Button key='ademp-ok' type='primary' feedback={loadingUpdate} onClick={(e) => this.onSubmit(e)}>Apply</Button>
        ]}
      >
        <Form>
          <div className='add-emp-modal'>
            <div className='title'>Select a Admin User to add as a Support Coordinator.</div>
            <div className='block'/>
            <FormItem style={{width: '60%'}}>
              {getFieldDecorator('member_id', {
                initialValue: null,
                rules: [
                  { required: true, message: 'Please select a user.' }
                ]
              })(
                <Select
                  showSearch
                  notFoundContent={<div>No available admin users.</div>}
                  filterOption={(input, option) => this.filterOptions(input, option)}
                >
                  { allMembers.map((mbr, idx) => {
                      return <Option key={`acmx-${mbr.id}`} value={mbr.id}>
                        {`${mbr.name || ''}${mbr.role_name ? ` - ${mbr.role_name}` : '' }`}
                      </Option>
                    }) }
                </Select>
              )}
            </FormItem>
          </div>
        </Form>
      </Modal>
    )
  }

  hasAccess = (accessLevel) => {
    return auth.hasAccess(accessLevel)
  }
}

export default Form.create()(AddEmployeeModal)
