import Permissions from '../permissions'

export default [
  {
    name: 'Funder List',
    desc: 'Manage all funders',
    icon: 'project',
    link: '/funders',
    enable: true,
    permission: Permissions.FUNDER.INFO.LIST
  }
]
