import Permissions from '../permissions'

export default [
  {
    name: 'Participants',
    icon: 'usergroup-add',
    link: '/participants',
    permission: Permissions.PARTICIPANT.INFO.LIST
  },
  {
    name: 'Providers',
    icon: 'project',
    link: '/providers',
    permission: Permissions.PROVIDER.INFO.LIST
  },
  {
    name: 'Billings',
    icon: 'dollar',
    link: '/billings',
    permission: Permissions.BILLINGS.INFO.LIST
  },
  {
    name: 'Settings',
    icon: 'setting',
    link: '/settings',
    permission: Permissions.SETTING.PROFILE.LIST
  }
]
