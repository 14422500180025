import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link } from 'react-router-dom'
import { scRateService } from '../../../../services'
import { authService } from '../../../../services'
import { auth, formatter, validator } from '../../../../util'

// UI
import { Button, Page, Pager, Panel, SideModal, List, ListDiv } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import Tooltip from 'antd/lib/tooltip'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

const pageSize = 20
const { Item: FormItem } = Form

const settingTitle = 'Rate Set'

const toTableWidth = (width) => {
  return { width: `${(100 * width / 24).toFixed(2)}%` }
}

const listRow = ({row, cols, index, subrows, subcols}) => {
  return (<tr className={'wd-list-row'} key={`trrow${index}`} style={{ borderCollapse: subrows ? 'collapse' : '2px' }}>
    {cols.map((col, idx) => (
      <td style={toTableWidth(col.width)} className='wd-list-col' key={`tdrow${idx}`}>
        {col.render ? (
          col.render(row, index)
        ) : col.key ? (
          row[col.key]
        ) : ''}
      </td>
    ))}
    { subrows
      ? row[subrows] && validator.isNotEmptyArray(row[subrows])
        ? row[subrows].map((subrow, index) => {
          return subcols && subcols.map((subcol, i) => {
            return <Col key={i} span={subcol.width} className='wd-list-div-col' style={{ justifyContent: subcol.align || 'flex-start' }}>
              <div style={{ paddingLeft: i === 0 ? '15px' : '0px' }}>
                {subcol.render ? (
                  subcol.render(subrow)
                ) : subcol.key ? (
                  subrow[subcol.key]
                ) : ''}
              </div>
            </Col>
            })
        })
        : <tr><Col key={'tremp'} span={10}>No Subset Available.</Col></tr>
      : null }
  </tr>)
}

export class SettingRateSet extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      modalShow: false,
      selectedItem: {},
      list: [],
      loading: false,
      loadingForm: false,
      total: 0
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchRates({ currentPage })
  }

  fetchRates = async ({ loading = false, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.RATESETS.LIST)) return

    try {
      this.setState({ currentPage, loading: true })
      const list = await scRateService.listByPage(currentPage, pageSize)
      this.setState({ list: list.list, loading: false, total: list.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load rate sets successfully. Please try again later.')
    }
  }

  handleEdit (id, name, value, active) {
    this.setState({ selectedItem: { id, name, value, active } })
    this.showModal()
  }

  async handleDelete (id) {
    // const res = await settingGeneralService.remove(id)

    // if (res) {
    //   message.success('Deleted successfully', `${settingType} deleted successfully`)
    //   this.fetchSettings({ currentPage: 1 })
    // }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, list, loading, loadingForm, modal, selectedItem, total } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 18,
        render: ({ name }) => <div className={`rs-title`}>{name}</div>
      },
      {
        title: 'Enable',
        width: 4,
        render: ({ active }) => active
        ? <div style={{ color: '#4fbc85', fontSize: '12pt' }}><Icon type='check-circle' theme='filled' /></div>
        : <div style={{ color: '#ccc', fontSize: '12pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { this.hasAccess(Permissions.SETTING.RATESETS.READ)
            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
              <Link to={`/settings/rate-set/edit/${item.code}`}>
                <div>
                  <Icon type='form' />
                </div>
              </Link>
            </Tooltip>
            : null }
          {/* <Tooltip mouseLeaveDelay={0} title='Remove Item'>
            <Popconfirm
              title='Confirm to remove this rate set?'
              onConfirm={() => this.removeItem(item)}
              okText='Yes'
              cancelText='No'
            >
              <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
            </Popconfirm>
          </Tooltip> */}
        </div>
      },
    ]

    const subcolumns = [
      {
        title: 'Name',
        width: 11,
        subkey: 'rs_name',
        render: (name, index) => {
          return <div className={`${index === 0 ? 'rs-first' : 'rs-norm'}`}>{name}</div>
        }
      },
      {
        title: 'Period',
        width: 6,
        render: (data, index) => {
          const d1 = data['rs_start_date'][index]
          const d2 = data['rs_end_date'][index]
          return <div className={`${index === 0 ? 'rs-first' : 'rs-norm'}`}>{formatter.toShortDate(d1)} - {formatter.toShortDate(d2)}</div>
        }
      },
      {
        title: '',
        width: 1,
      },
      {
        title: 'Enable',
        width: 4,
        subkey: 'rs_active',
        render: (active) => active
        ? <div style={{ color: '#777', fontSize: '11pt' }}><Icon type='check-circle' /></div>
        : <div style={{ color: '#ccc', fontSize: '11pt' }}><Icon type='close-circle' /></div>
      },
      {
        title: '',
        width: 2,
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <ListDiv cols={columns} rows={list} subcols={subcolumns} subrows='rs_name' />
            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} rate set${total === 1 ? '' : 's'}`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchSettings({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingRateSet))
