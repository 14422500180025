import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/report/scheduler'

export default {
  listSchedulerReportByPage (page, size, filter, sorting, text) {
    return request(`${url}/list/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  createScheduleReport (values) {
    return request(`${url}/new`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
