import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientBudgetItemService, settingFileService, fileService, logService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment'

// UI
import { Button, ControlLabel, FileUpload, Loading, List, Notification, Panel, Pager, SectionTitle, SearchInput, SideModal } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
// import Notification from 'antd/lib/notification'
import { auth, formatter, log, uploader, validator } from '../../../util'
import { apiHostname } from '../../../config'

import './styles.css'
import AddFileModal from '../AddFileModal'

const { Item: FormItem } = Form
const { TextArea } = Input
const Option = Select.Option
const TabPane = Tabs.TabPane
const notify = Notification
const { confirm } = Modal

const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

const EmptyEntry = {
  id: null,
  name: '--- NO PLAN PERIOD ---'
}

export class ClientFiles extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      fileList: { list: [], total: 0 },
      budgetList: [],
      categoriesList: [],
      subCategoriesList: [],
      currentSubCategoriesList: [],
      loading: false,
      loadingBudget: false,
      loadingList: false,
      loadingUpdate: false,
      showAddFileModal: false,
      modalFileItem: {},
      filter: {},
      filterParam: '',
      sort: {},
      searchText: ''
    }
  }

  componentDidMount () {
    const { currentPage, filter, searchText, sort } = this.state
    this.fetchBudget()
    this.fetchFiles({ currentPage, filter, searchText, sort })
    this.fetchMainCats()
    this.fetchSubCats()
  }

  componentWillReceiveProps (nextProps) {
    const { currentPage, filter, searchText, sort } = this.state
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchBudget(nextProps.clientId)
        this.fetchFiles({ currentPage, filter, searchText, sort }, nextProps.clientId)
      }
    }
  }

  render () {
    const { form, clientId, clientInfo = {} } = this.props
    const { getFieldDecorator } = form
    const { budgetList, categoriesList, subCategoriesList, currentPage, fileList, loading, loadingBudget, loadingList, loadingUpdate, modalFileItem, showAddFileModal } = this.state

    const listColumns = [
      {
        title: 'Main Category',
        width: 4,
        key: 'main_cat_name'
      },
      {
        title: 'Sub Category',
        width: 4,
        key: 'sub_cat_name'
      },
      {
        title: 'Label',
        width: 6,
        render: ({ label, name }) => {
          return (
            <div>
              <div>{label}</div>
              <div style={{ color: '#a5a5a5', fontSize: '8pt' }}>{name ? `[${formatter.toStandardFileName(name)}]` : ''}</div>
            </div>
          )
        }
      },
      {
        title: 'Issuance Date',
        width: 3,
        render: ({ issuance_date }) => formatter.toShortDate(issuance_date)
      },
      {
        title: 'Expiry Date',
        width: 3,
        render: ({ expiry_date }) => formatter.toShortDate(expiry_date)
      },
      {
        title: 'Associate to Plan?',
        width: 1,
        render: ({ is_target_enabled, sc_budget_period_start_date, sc_budget_period_end_date }) => is_target_enabled
          ? <div style={{cursor: 'help'}}>
            <Tooltip
                mouseLeaveDelay={0}
                title={`Plan Period: ${formatter.toShortDate(sc_budget_period_start_date)} - ${formatter.toShortDate(sc_budget_period_end_date)}`}
              >
              <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
            </Tooltip>
          </div>
          : null
      },
      {
        title: 'Enabled',
        width: 1,
        render: ({ active }) => active
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#BBB', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { this.hasAccess(Permissions.PARTICIPANT.FILES.UPDATE)
            ? <Tooltip mouseLeaveDelay={0} title='Edit Details'>
              <div onClick={() => this.showAddFileModal(true, item)} style={{ marginRight: 15 }}>
                <Icon type='form' />
              </div>
            </Tooltip>
            : null }
          { this.hasAccess(Permissions.PARTICIPANT.FILES.READ)
            ? <Tooltip mouseLeaveDelay={0} title={`Download ${formatter.toStandardFileName(item.name)}`}>
              <div onClick={this.handleDownload(item.url)} style={{ cursor: 'pointer' }}>
                <Icon type='file-text' />
              </div>
            </Tooltip>
            : null }
          { this.hasAccess(Permissions.PARTICIPANT.FILES.DELETE)
            ? <Tooltip mouseLeaveDelay={0} title='Delete File'>
              <Popconfirm
                title={`Are you sure you want to delete ${item.label}?`}
                onConfirm={() => this.deleteFile(item)}
                okText='Yes'
                cancelText='No'
              >
                <Icon type='delete' style={{ marginTop: '2px', marginRight: 15 }} />
              </Popconfirm>
            </Tooltip>
            : null }
        </div>
      }
    ]


    return (
      <Loading loading={loading} blur>
        <Spin spinning={loadingUpdate}>
        <Panel title={'Files'}
          subtitle={this.hasAccess(Permissions.PARTICIPANT.FILES.CREATE)
            ? <div className='btn'
              onClick={() => this.showAddFileModal(true)}>
              Add File
            </div>
            : null }
        >
          <div>
            { this.hasAccess(Permissions.PARTICIPANT.FILES.LIST)
              ? <div>
                <Row gutter={8}>
                  <Col lg={8}>
                    <ControlLabel>Label, Main Category, Sub Category</ControlLabel>
                    <SearchInput placeholder='Search' onChange={(v) => this.onSearchName(v)} />
                  </Col>
                  <Col lg={16} style={{display: 'flex', flexiDirection: 'horizontal', justifyContent: 'flex-end'}}>
                  </Col>
                </Row>

                <Row gutter={8} style={{ marginTop: 13 }}>
                  <Col lg={6}>
                    <ControlLabel>Mandatory</ControlLabel>
                    <Radio.Group onChange={(e) => this.filterFileMandatory(e)} defaultValue=''>
                      <Radio.Button value=''>All</Radio.Button>
                      <Radio.Button value='true'>Mandatory</Radio.Button>
                      <Radio.Button value='false'>Non-Mandatory</Radio.Button>
                    </Radio.Group>
                  </Col>

                  <Col lg={5}>
                    <ControlLabel>Enabled</ControlLabel>
                    <Radio.Group onChange={(e) => this.filterFileActive(e)} defaultValue=''>
                      <Radio.Button value=''>All</Radio.Button>
                      <Radio.Button value='true'>Active</Radio.Button>
                      <Radio.Button value='false'>Inactive</Radio.Button>
                    </Radio.Group>
                  </Col>

                  <Col lg={6}>
                    <Spin spinning={loadingBudget}>
                      <ControlLabel>Plan Period</ControlLabel>
                      <Select style={{width: '100%'}} onChange={(e) => this.filterFilePeriod(e)} defaultValue={null}>
                        { budgetList.map(e => {
                          const period = e.period_start_date && e.period_end_date
                            ? `${formatter.toShortDate(e.period_start_date)} - ${formatter.toShortDate(e.period_end_date)}`
                            : !e.id
                              ? `${e.name}`
                              : 'Unknown'
                          return <Option key={`bgmt${e.id}`} value={e.id}>{period}</Option>
                          })}
                      </Select>
                    </Spin>
                  </Col>
                </Row>
              </div>
              : null }

            <div className='client-file-task-list'>
              <Skeleton loading={loadingList} active>
                <List cols={listColumns} rows={fileList.list} />

                <Pager
                  size={pageSize}
                  total={fileList.total}
                  totalText={`Total ${fileList.total} File${fileList.total === 1 ? '' : 's'}`}
                  current={currentPage}
                  onChange={this.changePage}
                  style={{ marginTop: '15px' }}
                />

              </Skeleton>
            </div>
          </div>
        </Panel>

        <AddFileModal
          budgetList={budgetList}
          clientId={clientId}
          clientInfo={clientInfo}
          key={`addfilemodal${modalFileItem && modalFileItem.id ? modalFileItem.id : '_new'}`}
          item={modalFileItem}
          categoriesList={categoriesList}
          subCategoriesList={subCategoriesList}
          onClose={() => this.showAddFileModal(false)}
          onUpdate={(isEdit, r) => this.updateFileSaved(isEdit, r)}
          visible={showAddFileModal}
        />
        </Spin>
      </Loading>
    )
  }

  onSearchName (value) {
    const { currentPage, filter, filterParam, sort } = this.state
    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')

      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { label: { condition: 'ilike', value: `%${words[i]}%` } },
              { main_cat_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { sub_cat_name: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchFiles({ currentPage, filter, searchText: (filter.$and ? '' : value), sort, filterParam })
    this.setState({ searchText: value })
  }

  fetchBudget = async (cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.FUNDINGPLAN.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({ loadingBudget: true })

    if (!clientId) return

    const r = await clientBudgetItemService.pageAll(clientId)

    if (r && r.list && validator.isArray(r.list)) {
      const list = r.list
      list.unshift(Object.assign({}, EmptyEntry))
      this.setState({ budgetList: list, loadingBudget: false })
    } else {
      this.setState({ loadingBudget: false })
    }
  }

  fetchFiles = async ({ currentPage = 1, filter = {}, searchText = '', sort }, cid) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.FILES.LIST)) return

    this.setState({loadingList: true})
    const clientId = cid || this.props.clientId
    if (!clientId) return

    filter.is_delete = false
    filter.genre = 'participant'
    filter.genre_id = clientId

    const list = await fileService.listByPage('participant', currentPage, pageSize, filter, sort, searchText)

    this.setState({
      fileList: list && validator.isArray(list.list) ? list : { list: [], total: 0 },
      currentPage,
      loadingList: false
    })
  }

  fetchMainCats = async () => {
    const r = await settingFileService.listFileCatByPage(1, 0, { active: true, module_type: 'participant' })
    this.setState({
      categoriesList: r && validator.isArray(r.list) ? r.list : []
    })
  }

  fetchSubCats = async () => {
    const r = await settingFileService.listFileCatSubByPage(1, 0, { active: true, module_type: 'participant' })
    this.setState({
      subCategoriesList: r && validator.isArray(r.list) ? r.list : []
    })
  }

  filterFileActive = (e) => {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.active = filterValue
    } else {
      delete filter.active
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort, filterParam: filterValue })
  }

  filterFileMandatory = (e) => {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e.target.value

    if (filterValue !== '') {
      filter.is_mandatory = filterValue
    } else {
      delete filter.is_mandatory
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort })
  }

  filterFilePeriod = (e) => {
    const { currentPage, filter, sort, searchText } = this.state
    const filterValue = e

    if (filterValue) {
      filter.target = 'sc-budget'
      filter.target_id = filterValue
      filter.is_target_enabled = true
    } else {
      delete filter.target
      delete filter.target_id
      delete filter.is_target_enabled
    }

    this.setState({ filter })
    this.fetchFiles({ currentPage, filter, searchText, sort })
  }

  showAddFileModal = (showAddFileModal, modalFileItem = {}, cb = () => {}) => {
    this.setState({ showAddFileModal, modalFileItem }, () => cb())
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchFiles({ currentPage, filter, searchText, sort })
  }

  deleteFile = async (item) => {
    const action = 'deleted'
    const action2 = 'delete'
    const { clientId } = this.props
    const { currentPage } = this.state
    this.setState({ loadingUpdate: true })
    const r = await fileService.remove(item.id)

    if (r && r.id) {
      notify.success(`${formatter.capitalize(action)} Successfully`, `Participant File ${action} successfully.`)

      log.removeClientFile(clientId, `File "${item.label} (${item.name})" is deleted.`)
    } else {
      if (r.invalid && r.errors) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `${formatter.toErrorMessage(r.errors)}`)
      } else {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} participant file successfully.`)
      }
      // console.log('r', r)
    }

    this.fetchFiles({currentPage})

    this.setState({ loadingUpdate: false })
  }

  updateFileSaved = (isEdit, r) => {
    const action = isEdit ? 'updated' : 'added'
    const action2 = isEdit ? 'update' : 'add'

    this.showAddFileModal(false, {}, () => {
      try {
        if (r && r.id) {
          notify.success(`${formatter.capitalize(action)} Successfully`, `Participant File ${action} successfully.`)
        } else {
          if (r.invalid && r.errors) {
            notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `${formatter.toErrorMessage(r.errors)}`)
          } else {
            notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} participant file successfully.`)
          }
          // console.log('r', r)
        }
      } catch (e) {
        notify.error(`Unable to ${formatter.capitalize(action2)} Successfully`, `Unable to ${action2} participant file successfully. Please try again later`)
      }

      const { filter, searchText, sort } = this.state
      this.fetchFiles({filter, searchText, sort})
    })
  }

  handleDownload = (url) => (e) => {
    window.location.href = url
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientFiles))
