import { requestFile, queryString } from '../../util'

const headers = { 'Content-Type': 'application/json' }

const url = '/private/api/settings/contacts-export'

export default {
  exportContact (type, fileType, values) {
    return requestFile(`${url}/file/${type}/${fileType}`, {
      method: 'POST',
      headers,
      body: JSON.stringify(values)
    })
  }
}
