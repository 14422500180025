import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, reportTypeService } from '../../../services'
import { auth, exportFile, formatter, validator } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import { Permissions, ReportType } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import '../styles.css'

const pageSize = 40

const ThresholdLowerPercentage = 25
const ThresholdUpperPercentage = 75
const ThresholdDayCount = 60

const title = `Participants' Reports Due`
const DefaultSort = { end_day_count: 1 }

class ReportClientReportDue extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: { list: [], total: 0 },
      isGenerating: false,
      isLoaded: false,
      loading: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort),
      view: ReportType.SC_CLIENT_IMP_REPORT_DUE
    }
  }

  componentDidMount () {
    this.fetchReport()
  }

  render () {
    const { currentPage, isGenerating, list, loading, loadingList, view } = this.state

    const impColumns = [
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Plan Start Date',
        width: 2,
        key: 'period_start_date',
        render: ({ period_start_date }) => <div>{ formatter.toShortDate(period_start_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Report Due',
        width: 2,
        key: 'imp_report_due_date',
        render: ({ imp_report_due_date }) => <div>{ formatter.toShortDate(imp_report_due_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'As Of Today',
        width: 6,
        key: 'imp_report_due_min_count',
        render: ({ imp_report_due_min_count }) => {
          const weekText = formatter.toWeekDayText(imp_report_due_min_count)
          const dayText = formatter.toDaysText(imp_report_due_min_count)
          const isToday = dayText === 'TODAY'
          const isOverdue = imp_report_due_min_count < 0

          return (
            <div className={isOverdue ? 'overmax-text' : ''}>{ `${weekText} ${isToday ? '' : `(${dayText})`} ${isToday ? 'OVERDUE' : isOverdue ? 'OVERDUE' : 'to go'}` }</div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
    ]

    const ppColumns = [
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Plan Start Date',
        width: 2,
        key: 'period_start_date',
        render: ({ period_start_date }) => <div>{ formatter.toShortDate(period_start_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Report Due',
        width: 2,
        key: 'pp_report_due_date',
        render: ({ pp_report_due_date }) => <div>{ formatter.toShortDate(pp_report_due_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'As Of Today',
        width: 6,
        key: 'pp_report_due_min_count',
        render: ({ pp_report_due_min_count }) => {
          const weekText = formatter.toWeekDayText(pp_report_due_min_count)
          const dayText = formatter.toDaysText(pp_report_due_min_count)
          const isToday = dayText === 'TODAY'
          const isOverdue = pp_report_due_min_count < 0

          return (
            <div className={isOverdue ? 'overmax-text' : ''}>{ `${weekText} ${isToday ? '' : `(${dayText})`} ${isToday ? 'OVERDUE' : isOverdue ? 'OVERDUE' : 'to go'}` }</div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>


        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess(Permissions.REPORT.CLIENT_REPORT_DUE.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null }
          </Page.Header>

          <Page.Filter>
            <Row gutter={8}>
              <Col lg={12}>
                <ControlLabel>View</ControlLabel>
                <Radio.Group disabled={loading || loadingList} defaultValue={view} onChange={(v) => this.onChangeView(v)}>
                  <Radio.Button value={ReportType.SC_CLIENT_IMP_REPORT_DUE}> Implementation Due </Radio.Button>
                  <Radio.Button value={ReportType.SC_CLIENT_PP_REPORT_DUE}> Progress Due </Radio.Button>
                </Radio.Group>
              </Col>
            </Row>
          </Page.Filter>

          <div style={{marginTop: '24px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { view === ReportType.SC_CLIENT_IMP_REPORT_DUE
                  ? <div className='report'>
                    <div className='report-title'>{`Participants' Implementation Due Report `} <span className='report-tag'>{list.total}</span></div>
                    <List cols={impColumns} rows={list.list} />

                    <Pager
                      size={pageSize}
                      total={list.total}
                      totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                      current={currentPage}
                      onChange={(e) => this.onChangePage(e)}
                      style={{ marginTop: '15px' }}
                    />
                  </div>
                  : view === ReportType.SC_CLIENT_PP_REPORT_DUE
                    ? <div className='report'>
                      <div className='report-title'>{`Participants' Progress Due Report `} <span className='report-tag'>{list.total}</span></div>
                      <List cols={ppColumns} rows={list.list} />

                      <Pager
                        size={pageSize}
                        total={list.total}
                        totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                        current={currentPage}
                        onChange={(e) => this.onChangePage(e)}
                        style={{ marginTop: '15px' }}
                      />
                    </div>
                    : <div className='overmax-text'>Invalid Report Type.</div>
                  }

              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onChangePage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  onChangeView = (e) => {
    this.setState({ view: e.target.value, isLoaded: false, loading: true, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  fetchReport = () => {
    const { currentPage, filter, isLoaded, loading, sort, view } = this.state
    this.fetchClientReport({ currentPage, filter, isLoaded, loading, sort, view })
  }

  fetchClientReport = async ({ currentPage, filter, isLoaded = true, loading, sort, view }) => {
    if (this.hasAccess(Permissions.REPORT.CLIENT_REPORT_DUE.LIST)) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const _filter = filter && typeof filter === 'object' ? { ...filter, client_active: true } : {}
        const list = await reportTypeService.listReport(view, _filter, sort)

        if (list) {
          this.setState({
            list,
            isLoaded: true,
            loadingList: false,
            loading: false
          })
        } else {
          notify.error(`Unable to get ${title} Report`, 'Please try again later.')
        }
      } catch (e) {
        notify.error(`Unable to get ${title} Report`, 'Please try again later.')
      }

      if (window) window.scrollTo({ top: 0 })
    }
  }

  handleSort = (sort, statusType) => {
    for (var s in sort) {
      if (sort[s] === 0) {
        delete sort[s]
      }
    }

    if (!validator.isNotEmptyObject(sort)) {
      sort = Object.assign({}, DefaultSort)
    }

    this.setState({ sort, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  export = async () => {
    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchReportExport(ReportType.SC_CLIENT_REPORT_DUE)
      setTimeout(() => {
        this.setState({ isGenerating: false })
      }, 2000)
    } catch (e) {
      this.setState({ isGenerating: false })
      notify.error(`Unable to download ${title} Report`, 'Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default ReportClientReportDue
