import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import authService from '../../../services/auth'
import { fetchClients } from '../../../states/actions/client'
import { ClientMenu, Permissions } from '../../../constants'
import { exportFile } from '../../../util'

// UI
import { Button, ControlLabel, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'

const pageSize = 40
const Option = Select.Option
const { Group: RadioGroup, Button: RadioButton } = Radio

export class Client extends Component {
  constructor(props) {
    super(props)
    const { clients: { list, total }, clientLimit, loading } = props
    this.state = {
      clientIds: [],
      clientLimit,
      currentPage: 1,
      filter: { active: true, enlist: 'own' },
      list,
      loading,
      searching: false,
      searchText: '',
      sort: {},
      suburbs: [],
      total,
      isGenerating: false
    }
    this.onSearchName = debounce(this.onSearchName, 500)
    this.onSelectStatus = this.onSelectStatus.bind(this)
    this.onSelectManagedStatus = this.onSelectManagedStatus.bind(this)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { clients: { list, total }, clientLimit, loading } = nextProps
    const state = { ...prevState, clientLimit, list, loading, total }
    if (list !== prevState.list) {
      state.searching = false
    }
    return state
  }

  componentDidMount() {
    const { currentPage, filter, loading, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, loading: true, searchText, sort })
  }

  onSearchName(value) {
    const { filter, loading, sort } = this.state

    this.setState({ searching: true })

    if (value.indexOf(' ') >= 0) {
      const words = value.split(' ')
      if (Array.isArray(words)) {
        filter.$and = []

        for (let i = 0; i < words.length; i++) {
          filter.$and.push({
            $or: [
              { first_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { last_name: { condition: 'ilike', value: `%${words[i]}%` } },
              { acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
              { ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
              { suburb: { condition: 'ilike', value: `%${words[i]}%` } }
            ]
          })
        }
      }
    } else {
      if (Array.isArray(filter.$and)) {
        delete filter.$and
      }
    }
    this.fetchClients({ currentPage: 1, filter, loading, searchText: (filter.$and ? '' : value), sort })
    this.setState({ searchText: value, currentPage: 1 })
  }

  onSelectStatus(value) {
    const { currentPage, filter, searchText, sort } = this.state

    if (value === 'active' || value === 'inactive') {
      filter.active = (value === 'active')
    }

    this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  onSelectManagedStatus(e) {
    const value = e.target.value
    const { currentPage, filter, searchText, sort } = this.state
    filter.enlist = value

    this.fetchClients({ currentPage, filter, loading: true, searchText: ((filter.$and ? '' : searchText)), sort })
    this.setState({ filter })
  }

  hasAccess(accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  async export() {
    const { filter } = this.state

    this.setState({ isGenerating: true })
    // window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
    const r = await exportFile.fetchExport('client')
    setTimeout(() => {
      this.setState({ isGenerating: false })
    }, 2000)
  }

  renderParticipant(item) {
    const { id, active, first_name: firstName, last_name: lastName, gender, suburb, ndis_number: ndisNumber } = item

    return (
      <div className={`list-item ${active ? '' : 'list-item-dim'}`} key={id}>
        <Row className='list-content'>
          <Col md={6}><img alt='' src={process.env.PUBLIC_URL + '/img/' + (gender && gender.toLowerCase() === 'male' ? 'male.png' : 'female.png')} className='avatar' /></Col>

          <Col md={18}>
            <div className='name'>{firstName || ''}{lastName ? ` ${lastName}` : ''}</div>
            {ndisNumber ? <div style={{ fontSize: '8pt' }}>{ndisNumber}</div> : null}
            <div style={{ marginTop: '3px', fontSize: '8pt', color: '#888' }}>{suburb}</div>
          </Col>

        </Row>
      </div>
    )
  }

  openClientLimitModal = () => {
    const { clientLimit } = this.state
    Modal.info({
      title: 'Client Limit Reached',
      content: (
        <div>
          <div>You have reached your subscription limit of {clientLimit ? clientLimit.max_count : 0} clients.</div>

          <div>Please contact Pumpkin IT on <b>03 9118 2052</b> or <b><a href="mailto:info@pumpkinit.com.au">info@pumpkinit.com.au</a></b> to add additional clients</div>
        </div>
      )
    })
  }

  render() {
    const { clientLimit, loading } = this.props
    const { currentPage, isGenerating, list, total, searching } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ClientMenu} />
        </Page.Left>
        <Page.Content full>
          <Page.Header title='Participants'>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null}

            {this.hasAccess(Permissions.PARTICIPANT.INFO.CREATE) && clientLimit && clientLimit.can_add
              ? <Link to='/participants/add'>
                <div className='btn'>Add</div>
              </Link>
              : <div className='btn' onClick={this.openClientLimitModal}>Add</div>}
          </Page.Header>

          <Page.Filter>
            {this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)
              ? <Row gutter={12}>
                <Col lg={8}>
                  <ControlLabel>Name, NDIS Number, Phone, Email, Suburb</ControlLabel>
                  <SearchInput placeholder='Search participants' onChange={(v) => this.onSearchName(v)} isSearching={searching} />
                </Col>
                <Col lg={3}>
                  <ControlLabel>Status</ControlLabel>
                  <Select defaultValue='active' style={{ width: '100%' }} onChange={this.onSelectStatus}>
                    <Option value='active'>Active</Option>
                    <Option value='inactive'>Inactive</Option>
                  </Select>
                </Col>
                <Col lg={8}>
                  <ControlLabel>Assignment</ControlLabel>
                  <RadioGroup defaultValue={'own'} onChange={this.onSelectManagedStatus}>
                    <RadioButton value={'own'}>{'My Participants'}</RadioButton>
                    <RadioButton value={'all'}>{'All'}</RadioButton>
                  </RadioGroup>
                </Col>
              </Row>
              : null}
          </Page.Filter>

          <div className='clients'>
            <Skeleton loading={loading} active>
              {list.map((item) => {
                const {
                  id,
                  ref_id: refId
                } = item

                return (
                  this.hasAccess(Permissions.PARTICIPANT.INFO.READ)
                    ? <Link to={`/participants/${refId}/info`} key={id}>
                      {this.renderParticipant(item)}
                    </Link>
                    : this.renderParticipant(item)
                )
              })}
            </Skeleton>
          </div>

          <Pager
            size={pageSize}
            total={total}
            totalText={`Total ${total} Participant${total === 1 ? '' : 's'}`}
            current={currentPage}
            onChange={this.changePage}
            style={{ marginTop: '15px' }}
          />
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchClients({ currentPage, filter, searchText, sort })
  }

  fetchClients = async ({ loading = true, currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.INFO.LIST)) return

    try {
      const { fetchClients } = this.props
      this.setState({ currentPage })
      fetchClients({ loading, currentPage, pageSize, filter, sort, searchText })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load clients successfully. Please try again later.')
    }
  }
}

const mapDispatchToProps = {
  fetchClients
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Client)
