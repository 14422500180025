import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'

// UI
import { Page } from '../../../components'
import { Permissions } from '../../../constants'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import './styles.css'

import Language from './Language'
import Relationship from './Relationship'
import VisaStatus from './Visa'

const pageSize = 20
const { Item: FormItem } = Form
const TabPane = Tabs.TabPane

const settingTitle = 'Profile'
const settingType = 'profile'

export class SettingProfile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      addLanguage: false,
      addRelationship: false,
      addVisa: false,
      currentPage: 1,
      activeTab: '1',
      filter: {},
      modal: {
        item: { link: {} },
        show: false
      },
      modalShow: false,
      searchText: '',
      selectedItem: { _id: null },
      sort: {}
    }
  }

  componentDidMount () {
    const { currentPage } = this.state

    // const tab = (new URLSearchParams(window.location.search)).get("t")
    // this.setState({ activeTab: tab === 'rateset' ? '2' : (tab === 'fundmanager' ? '5' : '1') })
  }

  hideModal = () => {
    const { form } = this.props
    const { modal } = this.state
    const { resetFields } = form
    resetFields()
    modal.item = { link: {} }
    modal.show = false
    this.setState({ modal, selectedItem: {} })
  }

  showModal = () => {
    const { modal } = this.state
    modal.show = true
    this.setState({ modal })
  }

  addLanguage = () => {
    this.setState({ addLanguage: true })
  }

  addRelationship = () => {
    this.setState({ addRelationship: true })
  }

  addVisa= () => {
    this.setState({ addVisa: true })
  }

  onTabChange = (key) => {
    this.setState({ activeTab: key })
  }

  hasAccess (accessLevel) {
    return authService.hasAccess(accessLevel)
  }

  render () {
    const { addLanguage, addRelationship, addVisa, activeTab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey='1' activeKey={activeTab} onChange={this.onTabChange}>
            { this.hasAccess(Permissions.SETTING.PLANGUAGE.LIST)
              ? <TabPane tab='Languages' key='1'>
                <div className='header-action'>
                  { this.hasAccess(Permissions.SETTING.PLANGUAGE.CREATE)
                    ? <div className='btn' onClick={() => this.addLanguage()}>
                      Add Language
                    </div>
                    : null }
                </div>
                <Language onAdd={addLanguage} onComplete={() => this.setState({ addLanguage: false })} />
              </TabPane>
              : null }

            { this.hasAccess(Permissions.SETTING.PRELATIONSHIP.LIST)
              ? <TabPane tab='Relationships' key='2'>
                <div className='header-action'>
                  { this.hasAccess(Permissions.SETTING.PRELATIONSHIP.CREATE)
                    ? <div className='btn' onClick={() => this.addRelationship()}>
                      Add Relationship
                    </div>
                    : null }
                </div>
                <Relationship onAdd={addRelationship} onComplete={() => this.setState({ addRelationship: false })} />
              </TabPane>
              : null }

            { this.hasAccess(Permissions.SETTING.PVISA.LIST)
              ? <TabPane tab='Visa Status' key='3'>
                <div className='header-action'>
                  { this.hasAccess(Permissions.SETTING.PVISA.CREATE)
                    ? <div className='btn' onClick={() => this.addVisa()}>
                      Add Visa Status
                    </div>
                    : null }
                </div>
                <VisaStatus onAdd={addVisa} onComplete={() => this.setState({ addVisa: false })} />
              </TabPane>
              : null }
          </Tabs>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingProfile))
