import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { Permissions } from '../../../constants'
import SettingMenu from '../../../constants/menu/setting'
import { authService, settingOtherService } from '../../../services'
import { auth, log, formatter, validator } from '../../../util'

// UI
import { DateTimePicker, Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'

import './styles.css'
import notify from '../../../components/Notification'

const { Item: FormItem } = Form

const settingTitle = 'Settings'

export class SettingOther extends Component {
  constructor (props) {
    super(props)
    this.state = {
      settings: {},
      loading: false,
      loadingForm: false
    }
  }

  componentDidMount () {
    this.fetchOtherSettings()
  }

  fetchOtherSettings = async () => {
    try {
      this.setState({ loading: true })
      const settings = await settingOtherService.list()
      this.setState({ settings: settings && settings.id ? settings : {}, loading: false })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  validateCount = (rule, value, callback) => {
    if (value === null || value === undefined || value === '' || parseFloat(value) === 0) {
      callback(new Error(`Please enter Day count`))
    } else if (!validator.isIntegerText(value)) {
      callback(new Error(`Day count value must be valid`))
    } else {
      callback()
    }
  }

  validateAmount = (rule, value, callback) => {
    const field = rule && rule.field ? rule.field : null
    const amtText = field === 'sc_auto_closed_margin'
      ? 'Auto Closed Margin'
      : ''

    if (value === null || value === undefined || value === '') {
      callback(new Error(`Please enter ${amtText}`))
    } else if (!validator.isCurrencyAmount(value)) {
      callback(new Error(`${amtText} value must be valid`))
    } else {
      callback()
    }
  }

  handleSave = () => {
    const { form } = this.props
    const { validateFields } = form
    const { settings } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        this.setState({ loadingForm: true })

        try {
          const data = {
            sc_auto_closed_margin: values.sc_auto_closed_margin
          }

          const r = await settingOtherService.save(data)

          if (r && r.id) {
            log.updateSettingOther(
              r.id,
              settings,
              values,
              ['client_acc_ref', 'provider_acc_ref', 'job_acc_ref', 'funder_acc_ref'],
              [
                { key: 'sc_auto_closed_margin', label: 'Job Auto Closed Margin' }
              ]
            )
            notify.success('Saved successfully', `${settingTitle} saved successfully.`)
          } else {
            notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          notify.error('Unable to save successfully', `Unable to save ${settingTitle} successfully. Please try again later.`)
          this.setState({ loadingForm: false })
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { loading, loadingForm, settings } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const formItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 8 },
      wrapperCol: { sm: 14, md: 14, lg: 12 }
    }

    const longFormItemLayout = {
      labelCol: { sm: 6, md: 6, lg: 4 },
      wrapperCol: { sm: 14, md: 14, lg: 18 }
    }

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title='Other Settings'>
            { this.hasAccess(Permissions.SETTING.OTHERS.UPDATE)
              ? <div className='btn' onClick={() => this.handleSave()}>
                Save
              </div>
              : null }
          </Page.Header>

          <div className='setting-other-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingForm}>
                <Form>
                  <Panel title='Accounting Ref'>
                    <Row>
                      <Col lg={12}>
                        <FormItem label='Participant' {...formItemLayout}>
                          {getFieldDecorator('client_acc_ref', {
                            initialValue: `${settings.client_prefix} ${settings.client_acc_ref}`,
                            rules: [
                              { required: true, message: 'Please enter participant acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={12}>
                        <FormItem label='Provider' {...formItemLayout}>
                          {getFieldDecorator('provider_acc_ref', {
                            initialValue: `${settings.provider_prefix} ${settings.provider_acc_ref}`,
                            rules: [
                              { required: true, message: 'Please enter provider acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormItem label='Task' {...formItemLayout}>
                          {getFieldDecorator('job_acc_ref', {
                            initialValue: `${settings.job_prefix} ${settings.job_acc_ref_id}`,
                            rules: [
                              { required: true, message: 'Please enter task acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={12}>
                        <FormItem label='Funder' {...formItemLayout}>
                          {getFieldDecorator('funder_acc_ref', {
                            initialValue: `${settings.funder_prefix} ${settings.funder_acc_ref}`,
                            rules: [
                              { required: true, message: 'Please enter funder acc ref' }
                            ]
                          })(
                            <Input disabled />
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                  </Panel>
                  <Panel title='Tasks & Jobs'>
                    <Row>
                      <Col lg={12}>
                        <div className='closed-margin'>
                          <FormItem
                            label='Auto Closed Margin'
                            extra='The amount range of difference between received amount and subtotal that allow job item to be closed automatically. Set value to 0.00 to only allow job item auto closing when received amount is exactly same with subtotal.'
                            {...formItemLayout}
                          >
                            {getFieldDecorator('sc_auto_closed_margin', {
                              initialValue: settings.sc_auto_closed_margin,
                              rules: [
                                { validator: this.validateAmount }
                              ]
                            })(
                              <Input prefix='+/- $' />
                            )}
                          </FormItem>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                </Form>
              </Spin>
            </Skeleton>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingOther))
