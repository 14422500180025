import { request, requestFile, queryString } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/private/api/report/type'

export default {
  listReport (type, filter, sorting, text) {
    return request(`${url}/${type}/list${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  pageReport (type, page, size, filter, sorting, text) {
    return request(`${url}/${type}/page/${page}/size/${size}${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  },
  exportReport (type, filter, sorting, text) {
    return requestFile(`${url}/${type}/export${queryString.stringify({ filter: JSON.stringify(filter), sorting: JSON.stringify(sorting), text })}`)
  }
}
