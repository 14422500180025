import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { ExportType, Permissions } from '../../../constants'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'
import { auth, exportFile, log, formatter, validator } from '../../../util'

// UI
import { Button, DateTimePicker, Page, Panel } from '../../../components'
import Form from 'antd/lib/form'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Spin from 'antd/lib/spin'

import './styles.css'
import notify from '../../../components/Notification'

const { Item: FormItem } = Form

export class SettingContactsExport extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loadingExport: false
    }
  }

  componentDidMount () {
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  getExport = async (type, fileType) => {
    if (type && fileType) {
      try {
        this.setState({ loadingExport: true })
        const r = await exportFile.fetchExport(type, { fileType })

        setTimeout(() => {
          this.setState({ loadingExport: false })
        }, 4000)
      } catch (e) {
        notify.error('Unable to export', 'Unable to get export successfully. Please try again later.')
        this.setState({ loadingExport: false })
      }
    }
  }

  render () {
    const { loadingExport } = this.state
    const isAllowed = this.hasAccess(Permissions.SETTING.CONTACTS_EXPORT.LIST)

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={`Contacts Export`} />

          <div className='setting-list'>
            <Panel title={`General (Number / Name)`}>
              <Row gutter={12}>
                <Col lg={6}>
                  { isAllowed
                    ? <Button
                      key='gcps-csv'
                      feedback={loadingExport}
                      onClick={() => this.getExport(ExportType.TYPE_CONTACTS_GENERAL_SIMPLE, 'zip')}
                      >
                        Download Zipped CSV
                      </Button>
                    : null }
                </Col>
              </Row>
            </Panel>
          </div>

        </Page.Content>

      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingContactsExport))
