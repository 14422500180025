import Permissions from '../permissions'

export default [
  {
    title: 'Section 1',
    groups: [
      {
        subgroup: [
          {
            title: 'Reports',
            icon: 'snippets',
            permission: [
              Permissions.REPORT.DAY_COUNT_LAST_JOB.LIST,
              Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST,
              Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST,
              Permissions.REPORT.CLIENT_REPORT_DUE.LIST,
              Permissions.REPORT.TASK_DUE.LIST,
              Permissions.REPORT.SCHEDULER.LIST,
              Permissions.REPORT.RECIPIENT_SETTING.LIST
            ],
            menu: [
              {
                name: 'Day Counts Since Last Job',
                link: '/reports/ppt-day-counts',
                permission: Permissions.REPORT.DAY_COUNT_LAST_JOB.LIST
              },
              {
                name: 'Participants\'s Plan Details',
                link: '/reports/ppt-plan-details',
                permission: Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST
              },
              {
                name: 'Participants\' Plan Expiry',
                link: '/reports/ppt-plan-expiry',
                permission: Permissions.REPORT.CLIENT_PLAN_EXPIRY.LIST
              },
              {
                name: 'Participants\' Reports Due',
                link: '/reports/ppt-report-due',
                permission: Permissions.REPORT.CLIENT_REPORT_DUE.LIST
              },
              {
                name: 'Tasks Due',
                link: '/reports/task-due',
                permission: Permissions.REPORT.TASK_DUE.LIST
              },
              {
                name: 'Report Generate Request',
                link: '/reports/custom-generate',
                permission: Permissions.REPORT.SCHEDULER.LIST
              },
              {
                name: 'Report Recipient Setting',
                link: '/reports/setting',
                permission: Permissions.REPORT.RECIPIENT_SETTING.LIST
              },
            ],
          }
        ]
      }
    ]
  }
]
