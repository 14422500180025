import React, { Component } from 'react'
import { connect } from 'react-redux'
import { authService, clientCareplanService, fileService, logService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment'

// UI
import { Button, ControlLabel, FileUpload, Loading, List, Notification, Panel, Pager, SectionTitle, SearchInput, SideModal } from '../../../components'
import { CareplanConstant, ExportType, Permissions } from '../../../constants'
import { diff } from 'json-diff'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Modal from 'antd/lib/modal'
import Popconfirm from 'antd/lib/popconfirm'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
// import Notification from 'antd/lib/notification'
import { auth, exportFile, formatter, log, uploader, validator } from '../../../util'
import { apiHostname } from '../../../config'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane
const notify = Notification
const { confirm } = Modal

const Option = Select.Option
const pageSize = 20

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 18 }
}

const formItemLayoutCustom = (wrapper = 12, label = 6) => {
  return {
    labelCol: { sm: label, md: label, lg: label },
    wrapperCol: { sm: wrapper, md: wrapper, lg: wrapper }
  }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 12 }
}

export class ClientCarePlan extends Component {
  constructor (props) {
    super(props)
    this.state = {
      highlightFields: [],
      item: {},
      itemList: [],
      listHealthAllergy: [],
      listRiskAnalysis: [],
      listPastHealthAllergy: [],
      listPastRiskAnalysis: [],
      currentTab: '1',
      isLoaded: false,
      loading: true,
      loadingList: true,
      version: 0,
      // flags
      isLiveAlone: null,
    }
  }

  componentDidMount () {
    this.fetchCareplan()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
       this.fetchCareplan(nextProps.clientId)
      }
    }
  }

  render () {
    const { form, clientId, clientInfo = {} } = this.props
    const { getFieldDecorator } = form
    const { currentTab, loading } = this.state

    return (
      <Loading loading={loading} blur>
        {/* <Tabs defaultActiveKey={currentTab} type={'card'} style={{ marginBottom: 32 }}>
          <TabPane tab={'Care Plan'} key='1'>
            { this.renderCareplanTab() }
          </TabPane>
        </Tabs> */}
        { this.renderCareplanTab() }
      </Loading>
    )
  }

  renderCareplanTab = () => {
    const { form } = this.props
    const { getFieldDecorator } = form
    const {
      isLoaded,
      item,
      itemList,
      listHealthAllergy,
      listRiskAnalysis,
      listPastHealthAllergy,
      listPastRiskAnalysis,
      loading,
      version
    } = this.state

    const isCurrentVersion = !loading && isLoaded && version === itemList.length

    return (
      <div className='careplan'>
        <Loading loading={loading} blur>
          <Row gutter={16}>
            <Col lg={20}>
              <Row>
                <Col className='button-row'>
                  { !loading && itemList.length > 0 && this.hasAccess(Permissions.PARTICIPANT.CAREPLAN.LIST)
                    ? <div className='btn btn-ghost' onClick={this.handleDownload}>
                      Download Care Plan
                    </div>
                    : null
                  }
                  { !loading && isLoaded && isCurrentVersion && this.hasAccess(Permissions.PARTICIPANT.CAREPLAN.UPDATE)
                    ? <div className='btn' onClick={this.handleSave}>
                      Save Care Plan
                    </div>
                    : null
                  }
                </Col>
              </Row>

              {/** ----- Participant Information ------- */}
              <SectionTitle title='Participant Information' />

              <FormItem {...formItemLayout} label='About the Participant'>
                {getFieldDecorator('about_participant', {
                  initialValue: item.about_participant || null,
                  // rules: [
                  //   { required: true, message: 'Please enter About the Participant' }
                  // ]
                })(
                  <TextArea
                    rows={3}
                    style={this.inputShouldHighlight('about_participant')}
                  />
                )}
              </FormItem>

              <Row>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Lives Alone'>
                    {getFieldDecorator('lar_is_live_alone', {
                      initialValue: item.lar_is_live_alone,
                      // rules: [
                      //   { required: true, message: 'Please select Lives Alone' }
                      // ]
                    })(
                      <Radio.Group
                        onChange={(e) => this.inputUpdateFlag(e, 'isLiveAlone')}
                        name='lar_is_live_alone'
                        style={this.inputShouldHighlight('lar_is_live_alone')}>
                        <Radio value={1}>Yes</Radio>
                        <Radio value={0}>No</Radio>
                        <Radio value={2}>Undisclosed</Radio>
                      </Radio.Group>
                    )}
                  </FormItem>
                </Col>
                <Col lg={12}>
                  <FormItem {...sideBySideFormItemLayout} label='Accommondation'>
                    {getFieldDecorator('lar_accomondation', {
                      initialValue: item.lar_accomondation,
                      // rules: [
                      //   { required: true, message: 'Please select Accommondation.' }
                      // ]
                    })(
                      <Select
                        style={this.inputShouldHighlight('lar_accomondation')}
                        showSearch
                        filterOption={(input, option) => this.inputFilterOptions(input, option)}
                      >
                        {
                          CareplanConstant.Accommondation.map((acc) => {
                            return <Option key={`carck-${acc.value}`} value={acc.value} key={acc.value}>{acc.label}</Option>
                          })
                        }
                      </Select>
                    )}
                  </FormItem>
                </Col>
              </Row>

              <FormItem {...formItemLayout} label='Living Arrangement'>
                {getFieldDecorator('lar_living_arrangement', {
                  initialValue: item.lar_living_arrangement,
                  // rules: [
                  //   { required: true, message: 'Please enter Living Arrangement.' }
                  // ]
                })(
                  <TextArea
                    rows={4}
                    placeholder={'Details of current living arrangements, e.g. who lives with client'}
                    style={this.inputShouldHighlight('lar_living_arrangement')}
                  />
                )}
              </FormItem>

              {/** ----- Health Info ------- */}
              <SectionTitle title='Health Info' />

              <FormItem {...formItemLayout} label='Diagnosis/Medical conditions' hasFeedback>
                {getFieldDecorator('hinfo_dm_conditions', {
                  initialValue: item.hinfo_dm_conditions,
                  // rules: [
                  //   { required: true, message: 'Please enter Diagnosis/Medical conditions' }
                  // ]
                })(
                  <TextArea
                    rows={4}
                    placeholder={'Include relevant medical / surgical history, disability.'}
                    style={this.inputShouldHighlight('hinfo_dm_conditions')}
                  />
                )}
              </FormItem>

              {/** ----- Service Goals ------- */}
              <SectionTitle title='Service Goals' />

              <FormItem {...formItemLayout} label={`Participant's Goals`} hasFeedback>
                {getFieldDecorator('sgoal_participant_goal', {
                  initialValue: item.sgoal_participant_goal || null,
                  // rules: [
                  //   { required: true, message: 'Please enter Partcipant Goals.' }
                  // ]
                })(
                  <TextArea
                    rows={3}
                    placeholder={'What would the participant like to achieve in the course of the plan?'}
                    style={this.inputShouldHighlight('sgoal_participant_goal')}
                  />
                )}
              </FormItem>

              {/** ----- Assisted Daily Living ------- */}
              <SectionTitle title='Assisted Daily Living' />

              <FormItem {...formItemLayout} label={`Required Assistance`} hasFeedback>
                {getFieldDecorator('adl_required_assistance', {
                  initialValue: item.adl_required_assistance || null,
                  // rules: [
                  //   { required: true, message: 'Please enter Required Assistance.' }
                  // ]
                })(
                  <TextArea
                    rows={3}
                    placeholder={'Any assistance required in daily living?'}
                    style={this.inputShouldHighlight('adl_required_assistance')}
                  />
                )}
              </FormItem>

              {/** ----- Risk Analysis ------- */}
              <SectionTitle title='Risk Analysis' />

              <div style={{margin: '0px 5%'}}>
                Add any known risk that requires management. Risk examples as below but not limited to:
                <ul style={{paddingBottom: '0px'}}>
                  <li><strong>Physical – </strong>biting, punching, verbal abuse, spitting, yelling, inappropriate touching, falls risk etc.</li>
                  <li><strong>Environmental – </strong>confined spaces, crowds, loud noises, bright lights etc.</li>
                </ul>
                Also rate the risk level (defined below) and provide a strategy for managing the risk.
                <ul>
                  <li><strong>Low Risk – </strong>may happen 1 to 2 times a week (may not happen during our services)</li>
                  <li><strong>Medium Risk – </strong>may happen 3 to 4 times a week (may happen during our services)</li>
                  <li><strong>High Risk – </strong>may happen 5 or more times a week (most likely will happen during our services)</li>
                </ul>
              </div>

              <div className='row-custom-title'>
                <div> </div>
                { isCurrentVersion
                  ? <div className='btn' style={{marginBottom: '0px'}} onClick={() => this.updateNewRisk()}>Add Risk</div>
                  : null }
              </div>

              { isCurrentVersion
                ? listRiskAnalysis.map((e, idx) => {
                return (
                  <div key={`risk${idx}`} style={{marginLeft: '3%'}}>
                  <Row gutter={6}>
                    <Col lg={1}>
                      <div>{idx + 1}</div>
                    </Col>
                    <Col lg={6}>
                      <FormItem {...formItemLayoutCustom(15, 7)} label={`Risk`} hasFeedback>
                        {getFieldDecorator(`risk_name${idx}`, {
                          initialValue: e.risk_name || null,
                          // rules: [
                          //   { required: true, message: 'Please enter Risk Name.' }
                          // ]
                        })(
                          <TextArea
                            rows={2}
                            style={this.inputShouldHighlight(`risk_name${idx}`)}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={6}>
                      <FormItem {...formItemLayoutCustom(15, 7)} label={`Trigger(s)`} hasFeedback>
                        {getFieldDecorator(`risk_trigger${idx}`, {
                          initialValue: e.risk_trigger || null,
                          // rules: [
                          //   { required: true, message: 'Please enter Trigger(s).' }
                          // ]
                        })(
                          <TextArea
                            rows={2}
                            style={this.inputShouldHighlight(`risk_trigger${idx}`)}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={10}>
                      <FormItem {...formItemLayoutCustom(13, 10)} label={`Management Strategy`} hasFeedback>
                        {getFieldDecorator(`risk_mgnt_strategy${idx}`, {
                          initialValue: e.risk_mgnt_strategy || null,
                          // rules: [
                          //   { required: true, message: 'Please enter Management Strategy.' }
                          // ]
                        })(
                          <TextArea
                            rows={2}
                            style={this.inputShouldHighlight(`risk_mgnt_strategy${idx}`)}
                          />
                        )}
                      </FormItem>
                    </Col>
                    <Col lg={1}>
                      <Tooltip mouseLeaveDelay={0} title='Delete Risk'>
                        <Popconfirm
                          title={`Are you sure you want to delete this risk?`}
                          onConfirm={() => this.updateNewRisk(idx)}
                          okText='Yes'
                          cancelText='No'
                        >
                          <Icon type='delete' style={{ marginLeft: '15px' }} />
                        </Popconfirm>
                      </Tooltip>
                    </Col>
                  </Row>
                  <Row gutter={6}>
                    <Col lg={5} />
                    <Col lg={19}>
                      <FormItem hasFeedback style={{marginBottom: '18px'}}>
                        {getFieldDecorator(`risk_type${idx}`, {
                          initialValue: item[`risk_type${idx}`] || '',
                          // rules: [
                          //   { required: true, message: 'Please select Type' }
                          // ]
                        })(
                            <Radio.Group style={{margin: '0px', padding: '0px'}} name={`risk_type${idx}`} style={this.inputShouldHighlight(`risk_type${idx}`, true, true)}>
                              <Radio value={'low'}>Low Risk</Radio>
                              <Radio value={'medium'}>Medium Risk</Radio>
                              <Radio value={'high'}>High Risk</Radio>
                            </Radio.Group>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  </div>
                )})
                : listPastRiskAnalysis.map((e, idx) => {
                  return (
                    <div key={`riskp${idx}`} style={{marginLeft: '3%'}}>
                    <Row gutter={6}>
                      <Col lg={1}>
                        <div>{idx + 1}</div>
                      </Col>
                      <Col lg={6}>
                        <FormItem {...formItemLayoutCustom(15, 7)} label={`Risk`} hasFeedback>
                          {getFieldDecorator(`risk_name${idx}`, {
                            initialValue: e.risk_name || null,
                            // rules: [
                            //   { required: true, message: 'Please enter Risk Name.' }
                            // ]
                          })(
                            <TextArea
                              rows={2}
                              style={this.inputShouldHighlight(`risk_name${idx}`)}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={6}>
                        <FormItem {...formItemLayoutCustom(15, 7)} label={`Trigger(s)`} hasFeedback>
                          {getFieldDecorator(`risk_trigger${idx}`, {
                            initialValue: e.risk_trigger || null,
                            // rules: [
                            //   { required: true, message: 'Please enter Trigger(s).' }
                            // ]
                          })(
                            <TextArea
                              rows={2}
                              style={this.inputShouldHighlight(`risk_trigger${idx}`)}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={10}>
                        <FormItem {...formItemLayoutCustom(13, 10)} label={`Management Strategy`} hasFeedback>
                          {getFieldDecorator(`risk_mgnt_strategy${idx}`, {
                            initialValue: e.risk_mgnt_strategy || null,
                            // rules: [
                            //   { required: true, message: 'Please enter Management Strategy.' }
                            // ]
                          })(
                            <TextArea
                              rows={2}
                              style={this.inputShouldHighlight(`risk_mgnt_strategy${idx}`)}
                            />
                          )}
                        </FormItem>
                      </Col>
                      <Col lg={1}>

                      </Col>
                    </Row>
                    <Row gutter={6}>
                      <Col lg={5} />
                      <Col lg={19}>
                        <FormItem hasFeedback style={{marginBottom: '18px'}}>
                          {getFieldDecorator(`risk_type${idx}`, {
                            initialValue: item[`risk_type${idx}`] || '',
                            // rules: [
                            //   { required: true, message: 'Please select Type' }
                            // ]
                          })(
                              <Radio.Group name={`risk_type${idx}`} style={this.inputShouldHighlight(`risk_type${idx}`, true, true)}>
                                <Radio value={'low'}>Low Risk</Radio>
                                <Radio value={'medium'}>Medium Risk</Radio>
                                <Radio value={'high'}>High Risk</Radio>
                              </Radio.Group>
                          )}
                        </FormItem>
                      </Col>
                    </Row>
                    </div>
                  )})
              }
            </Col>
            <Col lg={4}>
              { itemList.length > 0
                ? <div className='version-container'>

                  <div className='version-title'>Previous Versions</div>
                  { itemList.map((careplan, index) => {
                      return <div key={`cpvr${index}`} className={`version ${version === careplan.version ? 'version-selected' : ''}`} onClick={() => { this.viewVersion(careplan.version) }}>
                        Version { careplan.version }
                        <div className='date'>{ formatter.toStandardDate(careplan.created_at) }</div>
                        <div className='name'>{ careplan.created_by_name }</div>
                      </div>
                    }) }
                </div>
                : null }
            </Col>
          </Row>
        </Loading>
      </div>
    )
  }

  fetchCareplan = (cid) => {
    this.fetchCurrentCareplan(cid)
    this.fetchListCareplan(cid)
  }

  fetchCurrentCareplan = async (cid) => {
    const { clientId: pcid } = this.props
    const clientId = cid || pcid

    if (!this.hasAccess(Permissions.PARTICIPANT.CAREPLAN.LIST) || !clientId) return

    this.setState({ loading: true })

    const cp = await clientCareplanService.get(clientId)

    if (cp && cp.id) {
      const listHealthAllergy = validator.isNotEmptyArray(cp.list_health_allergy) ? cp.list_health_allergy : []
      const listRiskAnalysis = validator.isNotEmptyArray(cp.list_risk_analysis) ? cp.list_risk_analysis : []

      this.setState({
        item: cp,
        version: cp.version || 0,
        listHealthAllergy,
        listRiskAnalysis
      }, () => {
        setTimeout(() => {
          this.validateInputs()
          this.setState({ loading: false, isLoaded: true })
        }, 500)
      })
    } else {
      this.setState({ loading: false, isLoaded: true })
    }
  }

  fetchListCareplan = async (cid) => {
    const { clientId: pcid } = this.props
    const clientId = cid || pcid

    if (!this.hasAccess(Permissions.PARTICIPANT.CAREPLAN.LIST) || !clientId) return

    this.setState({ loadingList: true })

    const cp = await clientCareplanService.listAll(clientId)

    if (cp && validator.isArray(cp)) {
      this.setState({ itemList: cp, loadingList: false }, () => {
        if (validator.isNotEmptyArray(cp) && cp.length > 1) {
          setTimeout(() => {
            this.viewVersion(cp[0].version)
          }, 800)
        }
      })
    } else {
      this.setState({ loadingList: false })
    }
  }

  viewVersion = (version) => {
    const { itemList } = this.state
    const changeIds = []

    this.props.form.resetFields()

    const currentVersion = itemList.filter(item => item.version === version)

    if (validator.isNotEmptyArray(currentVersion)) {
      const cp = currentVersion[0]
      const listPastHealthAllergy = validator.isNotEmptyArray(cp.list_health_allergy) ? cp.list_health_allergy : []
      const listPastRiskAnalysis = validator.isNotEmptyArray(cp.list_risk_analysis) ? cp.list_risk_analysis : []

      this.setState({
        version,
        item: cp,
        listPastHealthAllergy,
        listPastRiskAnalysis,
      })
    }


    if (version > 1) {
      const previousVersion = itemList.filter(item => item.version === version - 1)

      const changes = diff(currentVersion[0], previousVersion[0])
      // console.log('changes 1', changes)

      for (const key of Object.keys(changes)) {
        const value = changes[key]
        // console.log('changes 2', key, value)

        if (value && value.hasOwnProperty('__new')) {
          // check if the value is date
          if (value.__old && value.__old.toString().indexOf('.000Z') > -1) {
            // skip if no changes on date
            if (!moment(value.__old).isSame(moment(value.__new))) {
              changeIds.push(key)
            }
          } else {
            if (value.__old && value.__new && (value.__old.toString() !== value.__new.toString())) {
              changeIds.push(key)
            } else if (!value.__old && value.__new) {
              changeIds.push(key)
            } else if (value.__old && !value.__new) {
              changeIds.push(key)
            }
          }
        } else if (value !== null && value !== undefined && key && key.indexOf('__deleted') > -1) {
          const newKey = key.replace('__deleted', '')
          changeIds.push(newKey)
        }
      }
    }
    this.setState({ highlightFields: changeIds })
  }

  inputFilterOptions = (input, option) => {
    const text =  option.props.children

    return text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  inputShouldHighlight = (field, isPadding = true, isPaddingRight = false) => {
    const { highlightFields } = this.state
    const result = highlightFields.find(item => item === field)
    return {
      width: '100%',
      backgroundColor: result ? '#fff5dd99' : '#fff',
      paddingTop: isPadding ? '8px' : '0px',
      paddingBottom: isPadding ? '8px' : '0px',
      paddingRight: isPaddingRight ? '15px' : '0px'
      // padding: isPadding ? '8px 0px' : '0px 0px'
    }
  }

  inputUpdateFlag (e, field = '', inputField = '', isInverse = false) {
    const { form } = this.props
    const { validateFields } = form
    const value = e.target.value
    let finalVal = null
    if (field) {
      if (typeof value === 'boolean') {
        finalVal = isInverse ? !value : value
        // this.setState({[field]: isInverse ? !value : value})
      } else if (this.validateMultiBoolEntry(value)) {
        const bool = parseInt(value) === 1
        finalVal = isInverse ? !bool : bool
      } else if (value === 'verbal' || value === 'independent' || value === 'unknown') {
        finalVal = isInverse ? false : true
      } else {
        finalVal = isInverse ? true : false
      }

      this.setState({[field]: finalVal}, () => {
        if (inputField) {
          validateFields([inputField])
        }
      })
    }
  }

  updateNewRisk = (index = null) => {
    const { listRiskAnalysis } = this.state
    if (index === null) {
      listRiskAnalysis.push(Object.assign({}, CareplanConstant.DefaultRiskItem))
    } else if (index > -1 && listRiskAnalysis.length > index) {
      listRiskAnalysis.splice(index, 1)
    }

    this.setState({listRiskAnalysis})
  }

  validateInputs = () => {
    const { form } = this.props
    const values = form.getFieldsValue()

    if (values['lar_is_live_alone'] !== undefined) {
      this.inputUpdateFlag({target: {value: values['lar_is_live_alone']}}, 'isLiveAlone')
    }
  }

  validateMultiBoolEntry (value) {
    return validator.isDigit(value)
  }

  handleDownload = async (e) => {
    e.preventDefault()
    const { clientId } = this.props
    try {
      this.setState({ loading: true })
      // window.location.href = `${apiHostname}/api/export/list/client${queryString.stringify({ filter: JSON.stringify(filter) })}`
      const r = await exportFile.fetchExport(ExportType.TYPE_CLIENT_CAREPLAN, { client_id: clientId, is_open: true })
      setTimeout(() => {
        this.setState({ loading: false })
      }, 3000)
    } catch (e) {
      this.handleExportError()
      this.setState({ loading: false })
    }
  }

  restructureValues = (values) => {
    const { listHealthAllergy, listRiskAnalysis } = this.state
    let listH = []
    let listR = []
    for (let i = 0; i < listHealthAllergy.length; i++) {
      let item = {}
      item['hinfo_dm_allergy_name'] = values[`hinfo_dm_allergy_name${i}`]
      item['hinfo_dm_allergy_effects'] = values[`hinfo_dm_allergy_effects${i}`]

      delete values[`hinfo_dm_allergy_name${i}`]
      delete values[`hinfo_dm_allergy_effects${i}`]

      listH.push(item)
    }

    for (let i = 0; i < listRiskAnalysis.length; i++) {
      let item = {}

      item['risk_type'] = values[`risk_type${i}`]
      item['risk_name'] = values[`risk_name${i}`]
      item['risk_trigger'] = values[`risk_trigger${i}`]
      item['risk_mgnt_strategy'] = values[`risk_mgnt_strategy${i}`]

      delete values[`risk_type${i}`]
      delete values[`risk_name${i}`]
      delete values[`risk_trigger${i}`]
      delete values[`risk_mgnt_strategy${i}`]

      listR.push(item)
    }

    values.list_health_allergy = listH
    values.list_risk_analysis = listR

    return values
  }

  handleSave = async (e) => {
    e.preventDefault()
    const { form, clientId } = this.props
    const { loading } = this.state
    const { validateFieldsAndScroll } = form

    if (loading) {
      return
    }

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        this.setState({ loading: true })
        values.client_id = clientId
        values = this.restructureValues(values)
        const r = await clientCareplanService.add(values)

        if (r && r.id) {
          this.handleSaveSuccessful()
          this.fetchCareplan()
        } else {
          this.handleSaveError()
          this.setState({ loading: false })
        }
      } else {
        this.setState({ loading: false })
      }
    })
  }

  handleSaveSuccessful = () => {
    notify.success('Saved successfully', 'Participant Care Plan saved successfully.')
  }

  handleSaveError = () => {
    notify.error('Unable to save successfully', 'Unable to save participant care plan successfully. Please try again later.')
  }

  handleExportError = () => {
    notify.error('Unable to download', 'Unable to download care plan successfully. Please try again later.')
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  isEdit = () => {
    const { match } = this.props
    const { params } = match
    const { id } = params
    return id !== 'add'
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientCarePlan))
