import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../constants/menu/setting'
import { authService } from '../../../services'
import { auth } from '../../../util'

// UI
import { Page } from '../../../components'
import Form from 'antd/lib/form'
import Tabs from 'antd/lib/tabs'

import ServiceList from './List'
import './styles.css'

const TabPane = Tabs.TabPane
const settingTitle = 'Services'

const TabList = [
  { tabId: 1, path: '/providers', type: 'sc-provider-services', title: 'Providers' },
  { tabId: 2, path: '/support-coords', type: 'sc-supp-coords', title: 'Support Coordinators' }
]

export class SettingServices extends Component {
  constructor (props) {
    super(props)
    const { match } = props
    const { params = {} } = match
    const { type = '' } = params
    const tab = TabList.find(e => e.path === type || e.path === `/${type}`)
    this.state = {
      currentTab: tab && tab.tabId ? String(tab.tabId) : '1',
      tab: tab && tab.tabId ? tab : TabList[0]
    }
  }

  handleTabChange = (index) => {
    const tab = TabList.find(e => e.tabId === parseInt(index))
    this.setState({currentTab: index})
    if (tab && tab.tabId) {
      this.props.history.replace(`/settings/services${tab.path}`)
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentTab, tab } = this.state

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={SettingMenu} />
        </Page.Left>

        <Page.Content full>
          <Page.Header title={settingTitle} />

          <Tabs defaultActiveKey={currentTab} onChange={this.handleTabChange}>
            <TabPane tab={TabList[0].title} key='1'>
              <ServiceList type={TabList[0].type} />
            </TabPane>
            <TabPane tab={TabList[1].title} key='2'>
              <ServiceList type={TabList[1].type} />
            </TabPane>
            {/* <TabPane tab={TabList[2].title} key='3'>
              <ServiceList type={TabList[2].type} />
            </TabPane> */}
          </Tabs>
        </Page.Content>
      </Page.Body>
    )
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(SettingServices))
