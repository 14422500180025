import React, { Component } from 'react'
import _ from 'lodash'
import moment from 'moment-timezone'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import { apiHostname } from '../../../config'
import { auth, common, exportFile, formatter, queryString, validator } from '../../../util'
import { authService, billingService, billingExportService, clientService, employeeService } from '../../../services'
import { BillingListType, BillingUpdateType, ExportType, Permissions } from '../../../constants'

// UI
import { Button, Checkbox, ControlLabel, List, Page, Pager, SearchInput } from '../../../components'
import notify from '../../../components/Notification'

import './styles.css'

import Col from 'antd/lib/col'
import DatePicker from 'antd/lib/date-picker'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tooltip from 'antd/lib/tooltip'

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const dateFormat = 'DD/MM/YYYY'

const pageSize = 40
const Option = Select.Option
const { confirm } = Modal
const FormItem = Form.Item

const formItemLayout = {
  labelCol: { sm: 12, md: 12, lg: 12 },
  wrapperCol: { sm: 12, md: 12, lg: 12 }
}

const firstClient = {
  id: null,
  first_name: 'Select All Participant'
}

const firstEmployee = {
  id: null,
  fullname: 'Select All Support Coordinator'
}

const ItemsSelectErrMsg = 'No item is selected.'

const ColorHrBalance = '#a2448f'
const ColorInvoice = '#c44f35'
const ColorPayroll = '#3d34eb'
const ColorPaymentRequest = '#7c7310'
const ColorSubmit = '#223321'


const getFilterParams = (filter, value = '') => {
  if (!validator.isObject(filter)) {
    filter = {}
  }

  if (value.indexOf(' ') >= 0) {
    const words = value.split(' ')

    if (Array.isArray(words)) {
      filter.$and = []

      for (let i = 0; i < words.length; i++) {
        filter.$and.push({
          $or: [
            { id_numbering: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_first_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_last_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_acc_ref: { condition: 'ilike', value: `%${words[i]}%` } },
            { client_ndis_number: { condition: 'ilike', value: `%${words[i]}%` } },
            { provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { provider_abn: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_invoice_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_invoice_created_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_last_received_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { string_payment_date: { condition: 'ilike', value: `%${words[i]}%` } },
            { invoice_number: { condition: 'ilike', value: `%${words[i]}%` } },
            { invoice_provider_name: { condition: 'ilike', value: `%${words[i]}%` } },
            { status: { condition: 'ilike', value: `%${words[i]}%` } }
          ]
        })
      }
    }
  } else {
    if (Array.isArray(filter.$and)) {
      delete filter.$and
    }
  }

  return filter
}

class BillingExportList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      billingInfo: {},
      billingPeriodCode: null,
      currentPage: 1,
      filter: {},
      isLoaded: false,
      isShowModal: false,
      isShowAllModal: false,
      isUpdateFilter: false,
      isInitialised: false,
      isIdsSelectAll: false,
      list: [],
      loading: false,
      loadingExport: false,
      loadingList: false,
      loadingDataSelect: false,
      loadingModalSelect: false,
      loadingSummary: false,
      searching: false,
      searchText: '',
      selectedExportType: null,
      selectedExport: {},
      selectedIds: [],
      selectedIdsErrMsg: '',
      selectedIdsOri: [],
      sort: {},
      summary: {},
      total: 0
    }
  }

  static getDerivedStateFromProps (nextProps, prevState) {
    const { location, match } = nextProps
    const { code } = common.getPath(match)
    const { page, q, status } = common.getQueryStringSearchParams(location.search)
    const filter = getFilterParams(prevState.filter, q)

    const state = {
      ...prevState,
      billingPeriodCode: code,
      currentPage: page && !isNaN(parseInt(page)) ? parseInt(page) : prevState.currentPage,
      filter,
      pageKey: location.key,
      searchText: q || '',
    }

    return state
  }

  componentDidMount () {
    this.fetchDataList()
  }

  redirectUrl = (query) => {
    const { history, location } = this.props
    const params = new URLSearchParams(query)

    history.replace({ pathname: location.pathname, search: params.toString() })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  getTypeColor = (type) => {
    return type === ExportType.TYPE_BILLING_HR_BALANCE
      ? ColorHrBalance
      : type === ExportType.TYPE_BILLING_INVOICE
        ? ColorInvoice
        : type === ExportType.TYPE_BILLING_PAYROLL
          ? ColorPayroll
          : type === ExportType.TYPE_BILLING_PRODA
            ? ColorPaymentRequest
            : '#1d2c47'
  }

  getTypeName = (type) => {
    return type === ExportType.TYPE_BILLING_HR_BALANCE
      ? 'HR Balance'
      : type === ExportType.TYPE_BILLING_INVOICE
        ? 'Invoice'
        : type === ExportType.TYPE_BILLING_PAYROLL
          ? 'Payroll'
          : type === ExportType.TYPE_BILLING_PRODA
            ? 'Pymt Req & Invoice'
            : ''
  }

  getTypeActualName = (type) => {
    return type === ExportType.TYPE_BILLING_HR_BALANCE
      ? 'HR Balance'
      : type === ExportType.TYPE_BILLING_INVOICE
        ? 'Invoice'
        : type === ExportType.TYPE_BILLING_PAYROLL
          ? 'Payroll'
          : type === ExportType.TYPE_BILLING_PRODA
            ? 'Claim'
            : ''
  }

  getListColumns = (type) => {
    const { selectedIds } = this.state
    let column = []

    const id = {
      title: 'Job No',
      width: 2,
      render: ({ id_numbering }) => { return <span>
        {formatter.capitalize(id_numbering, false)}
      </span> }
    }

    const client = {
      title: 'Participant',
      width: 4,
      render: ({ client_ref_id, client_fullname }) => { return <div className='sccol-name'><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div> }
    }

    const employee = {
      title: 'Support Coordinator',
      width: 4,
      render: ({ employee_ref_id, employee_fullname }) => { return <div className='sccol-name'><a href={`/employees/${employee_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div> }
    }

    const jobCreatedAt = {
      title: 'Created At',
      width: 3,
      render: ({ created_at }) => { return <div>{formatter.toShortDate(created_at)}</div> }
    }

    const mgmt = {
      title: 'Fund Mgmt',
      width: 4,
      render: ({ budget_fund_managed_type_name }) => { return <div>{budget_fund_managed_type_name}</div> }
    }

    const jobDate = {
      title: 'Job Date',
      width: 3,
      render: ({ job_date }) => { return <div>{formatter.toShortDate(job_date)}</div> }
    }

    const jobDurationHrs = {
      title: 'Hours',
      width: 2,
      render: ({ job_duration_hrs }) => { return <div>{formatter.toFloatDecimal(job_duration_hrs)}</div> }
    }

    const checker = {
      title: 'Select',
      width: 1,
      render: (item) => {
        const isChecked = selectedIds.find(f => f === item.id) || false

        return (
          <div key={`rec${item.id}`} className={'section-row'}>
            <Checkbox
              checked={isChecked}
              onClick={f => this.updateModalIdsSelect(f, item.id)}
            />
          </div>
        )
      }
    }

    if (type === ExportType.TYPE_BILLING_PRODA) {
      return [id, client, employee, jobCreatedAt, mgmt, jobDate, jobDurationHrs, checker]
    } else if (type === ExportType.TYPE_BILLING_PAYROLL) {
      return [id, employee, client, jobCreatedAt, mgmt, jobDate, jobDurationHrs, checker]
    } else {
      return []
    }
  }

  render () {
    const { form, history } = this.props
    const {
      billingInfo,
      currentPage,
      isIdsSelectAll,
      isLoaded,
      isShowModal,
      isShowAllModal,
      list,
      loading,
      loadingExport,
      loadingList,
      loadingDataSelect,
      loadingModalSelect,
      searching,
      selectedExportType,
      selectedExport,
      selectedIds,
      selectedIdsErrMsg,
      total,
      title
    } = this.state
    const { getFieldDecorator } = form

    const columns = this.getListColumns(selectedExportType)
    const modalTitle = this.getTypeName(selectedExportType)
    const iconDiv = <Icon style={{fontSize: '15px', marginRight: '6px'}} type='download' />

    return (
      <Page.Body>
        <Page.Content nomenu>
          <Page.Header
            title={'Redownloads'}
            total={billingInfo && billingInfo.id ? billingInfo.name : ''}
          >
            { isLoaded && this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)
              ? <Button key='ahrbns' style={{backgroundColor: ColorHrBalance}} feedback={loadingExport} onClick={() => this.getFileAll(ExportType.TYPE_BILLING_HR_BALANCE)}>
                {iconDiv}
                {'HR Balance'}
              </Button>
              : null }
            {/* { isLoaded && this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)
              ? <Button key='ainvc' style={{backgroundColor: ColorInvoice}} feedback={loadingExport} onClick={() => this.triggerSelectAllModal(true, ExportType.TYPE_BILLING_INVOICE)}>
                {iconDiv}
                {'All Invoice'}
              </Button>
              : null } */}
            { isLoaded && this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)
              ? <Button key='apqs' style={{backgroundColor: ColorPaymentRequest}} feedback={loadingExport} onClick={() => this.triggerSelectAllModal(true, ExportType.TYPE_BILLING_PRODA)}>
                {iconDiv}
                {'All Pymt Req & Invoice'}
              </Button>
              : null }
            { isLoaded && this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)
              ? <Button key='aprl' style={{backgroundColor: ColorPayroll}} feedback={loadingExport} onClick={() => this.triggerSelectAllModal(true, ExportType.TYPE_BILLING_PAYROLL)}>
                {iconDiv}
                {'All Payroll'}
              </Button>
              : null }
            <Button key='hisback' feedback={loading || loadingList} onClick={() => history.goBack()}>Back</Button>
          </Page.Header>
          <div className='billing-export-list'>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                { list && validator.isNotEmptyArray(list) && list.map((item, index) => {
                  const {
                    id,
                    created_at: expCreatedAt,
                    is_expand: isExpand = false,
                    is_loading: isLoading = false,
                    type,
                    items,
                    total,
                    payment_date: paymentDate
                  } = item

                  const typeName = this.getTypeName(type)
                  const typeActualName = this.getTypeActualName(type)
                  const typeColor = this.getTypeColor(type)

                  const isDownloadEnable = type === ExportType.TYPE_BILLING_PRODA
                    ? this.hasAccess(Permissions.BILLINGS.CLAIM.LIST)
                    : type === ExportType.TYPE_BILLING_PAYROLL
                      ? this.hasAccess(Permissions.BILLINGS.PAYROLL.LIST)
                      : this.hasAccess(Permissions.BILLINGS.EXPORT.LIST)

                  return (
                    <div className={`list-item`} key={`rlst${id}-${formatter.toShortDate(expCreatedAt)}`}>
                      <Row>
                        <Col lg={21}>
                          <Row style={{ borderBottom: '1px dotted #ccc', paddingBottom: 2 }}>
                            <Col lg={6}>
                              <Row>
                                <Col lg={8}>
                                  <div className='subtitle'>Created At</div>
                                </Col>
                                <Col lg={14}>
                                  <div className='name title'>{formatter.toShortDate(expCreatedAt)}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col lg={8}>
                                  <div className='subtitle'>Type</div>
                                </Col>
                                <Col lg={14}>
                                  <div className='name title'>{typeActualName}</div>
                                </Col>
                              </Row>
                            </Col>
                            <Col lg={6}>
                              <Row>
                                <Col lg={8}>
                                  <div className='subtitle'>Jobs Count</div>
                                </Col>
                                <Col lg={14}>
                                  <div className='name title'>{total}</div>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row style={{ paddingTop: '2px' }}>
                            <Col lg={6}>
                              <Row >
                                <Col lg={8}>
                                  <span className='subtitle'>Jobs&nbsp;
                                    <span onClick={() => this.handleJobListExpand(index)}>
                                      <Icon type={isExpand ? 'up-circle' : 'down-circle'} className='icons' style={{paddingTop: '10px'}} />
                                    </span>
                                  </span>
                                </Col>
                                <Col lg={14}>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                          <Row style={{ paddingTop: '2px' }}>
                            { isExpand
                              ? items.map((item, idx) => {
                                const {
                                  id_numbering,
                                  budget_fund_managed_type_name,
                                  client_ref_id,
                                  client_fullname,
                                  created_at,
                                  employee_ref_id,
                                  employee_fullname,
                                  funder_ref_id,
                                  funder_fullname,
                                  job_date,
                                  job_duration_hrs,
                                  ref_id,
                                  task_ref_id,
                                } = item
                                return (
                                  <Row key={`jobsrs-${id}-${idx}`} className={'jobs-row'} gutter={10}>
                                    <Col lg={1} />
                                    <Col lg={2}>
                                      <div className='sccol-name'><a href={`/tasks/${task_ref_id}/info?sid=${ref_id}`} rel='noopener noreferrer' target='_blank'>{formatter.capitalize(id_numbering, false)}</a></div>
                                    </Col>
                                    <Col lg={3}>
                                      <div className='sccol-name'><a href={`/participants/${client_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a></div>
                                    </Col>
                                    <Col lg={3}>
                                      <div className='sccol-name'><a href={`/employees/${employee_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{employee_fullname}</a></div>
                                    </Col>
                                    {/* <Col lg={3}>
                                      <div className='sccol-name'><a href={`/funders/${funder_ref_id}/info`} rel='noopener noreferrer' target='_blank'>{funder_fullname}</a></div>
                                    </Col> */}
                                    <Col lg={4}>
                                      <div>Created at {formatter.toShortDate(created_at)}</div>
                                    </Col>
                                    <Col lg={3}>
                                      <div>{budget_fund_managed_type_name}</div>
                                    </Col>
                                    <Col lg={4}>
                                      <div>Job Date at {formatter.toShortDate(job_date)}</div>
                                    </Col>
                                    <Col lg={2}>
                                      <div>{formatter.toFloatDecimal(job_duration_hrs)} hr{job_duration_hrs === 1 ? '' : 's'}</div>
                                    </Col>
                                  </Row>
                                )
                              })
                            : null }
                          </Row>
                        </Col>
                        <Col lg={3}>
                          { isLoading
                            ? <div className={`list-button disable`} ><img src='/icon/button-loading.svg' alt='loading' /></div>
                            : isDownloadEnable
                              ? <div className={`list-button`} style={{backgroundColor: typeColor}} onClick={() => this.triggerSelectModal(true, item)}>{iconDiv} {typeName}</div>
                              : null }
                        </Col>
                      </Row>
                    </div>
                  )
                })}
              </Spin>
            </Skeleton>
          </div>

          { !loading
            ? <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} Record${total === 1 ? '' : 's'}`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
            : null }

          <Modal
            width='80%'
            title={`Select and Generate ${modalTitle}`}
            visible={isShowModal}
            onCancel={() => this.triggerSelectModal(false)}
            footer={
              [
                <Button key='close' ghost feedback={loadingModalSelect} onClick={() => this.triggerSelectModal(false)}>Close</Button>,
                <Button style={{backgroundColor: ColorSubmit}} key='submit1' feedback={loadingModalSelect} onClick={() => this.preGetFile()}>Download Selected</Button>,
                // isShowRmtModal ? <Button style={{backgroundColor: '#ebc034'}} key='submit2' feedback={loadingModalSelect} onClick={() => this.preGetFile(true, false)}>Email Selected</Button> : null,
                // isShowRmtModal ? <Button key='submit3' feedback={loadingModalSelect} onClick={() => this.preGetFile(true, true)}>Download & Email Selected</Button> : null
              ]
            }
          >
            <div className='billing-export-modal'>
              <Spin spinning={loadingModalSelect} blur>
                <Form>
                  {/* { selectedExportType === ExportType.TYPE_BILLING_PRODA
                    ? <div>
                      <div className='inv-notice-msg'>You may select a date to set payment date on files.</div>
                      <Row>
                        <Col lg={8}>
                          <FormItem key={`pdinp-${selectedExport.id}`} {...formItemLayout} label='Payment Date'>
                            {getFieldDecorator('payment_date', {
                              initialValue: selectedExport.payment_date ? formatter.toMoment(selectedExport.payment_date) : formatter.toMoment(new Date()),
                              rules: [
                                { required: true, message: ' ' },
                                { validator: this.validatePaymentDate }
                              ]
                            })(
                              <DatePicker
                                defaultPickerValue={moment(new Date())}
                                format={dateFormat}
                                disabled={loadingModalSelect}
                                disabledDate={billingInfo && billingInfo.id ? (date) => { return moment(billingInfo.date_from).isSameOrAfter(date) } : undefined}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col lg={16}></Col>
                      </Row>
                    </div>
                    : null } */}

                  { selectedExportType === ExportType.TYPE_BILLING_PRODA || selectedExportType === ExportType.TYPE_BILLING_PAYROLL
                    ? <div>
                      <div className='section-row' style={{marginTop: '15px'}}>
                        <div className='row-line-title'>
                          Select jobs to export.
                        </div>

                        <div className='section-row' style={{marginRight: '25px'}}>
                          <div style={{marginRight: '10px', fontSize: '11px'}}>Select / Deselect All</div>
                          <Checkbox
                            checked={isIdsSelectAll}
                            onClick={f => this.updateModalIdsSelectAll(f)}
                          />
                        </div>
                      </div>
                      { selectedExportType === ExportType.TYPE_BILLING_PRODA
                          ? <div className='section-row' style={{marginTop: '6px'}}>
                            <div className='row-line-title warning'>Only those claimed Jobs that the participants' plan under NDIA Managed will be listed in Payment Request file.</div>
                          </div>
                          : null }
                      { selectedIdsErrMsg ? <div className='err-msg'>{selectedIdsErrMsg}</div> : null }
                      <div className='list-section'>
                        <List cols={columns} rows={selectedExport.items || []} />
                      </div>
                    </div>
                    : null }
                </Form>
              </Spin>
            </div>
          </Modal>

          <Modal
            width='60%'
            title={`Generate ${modalTitle}`}
            visible={isShowAllModal}
            onCancel={() => this.triggerSelectAllModal(false)}
            footer={
              [
                <Button key='close2' ghost feedback={loadingModalSelect} onClick={() => this.triggerSelectAllModal(false)}>Close</Button>,
                <Button style={{backgroundColor: ColorSubmit}} key='submit11' feedback={loadingModalSelect} onClick={() => this.getFileAll()}>Download File</Button>,
              ]
            }
          >
            <div className='billing-export-modal'>
              <Spin spinning={loadingModalSelect} blur>
                <Form>
                  { selectedExportType === ExportType.TYPE_BILLING_PRODA
                    ? <div>
                      {/* <div className='inv-notice-msg'>You may select a date to set payment date on files.</div> */}
                      {/* <Row>
                        <Col lg={8}>
                          <FormItem key={`pdinpal`} {...formItemLayout} label='Payment Date'>
                            {getFieldDecorator('payment_date_all', {
                              initialValue: formatter.toMoment(new Date()),
                              rules: [
                                { required: true, message: ' ' },
                                { validator: this.validatePaymentDate }
                              ]
                            })(
                              <DatePicker
                                defaultPickerValue={moment(new Date())}
                                format={dateFormat}
                                disabled={loadingModalSelect}
                                disabledDate={billingInfo && billingInfo.id ? (date) => { return moment(billingInfo.date_from).isSameOrAfter(date) } : undefined}
                              />
                            )}
                          </FormItem>
                        </Col>
                        <Col lg={16}></Col>
                      </Row> */}
                      <div className='section-row'>
                        { selectedExportType === ExportType.TYPE_BILLING_PRODA
                          ? <div style={{marginTop: '6px'}}>
                            <div className='row-line-title warning'>Only those claimed Jobs that the the participants' plan under NDIA Managed will be listed in Payment Request file.</div>
                            <div className='row-line-title warning'>Only those claimed Jobs will be listed in Invoice file.</div>
                          </div>
                          : null }
                      </div>
                    </div>
                    : selectedExportType === ExportType.TYPE_BILLING_PAYROLL
                      ? <div>
                        <div className='section-row' style={{marginTop: '6px'}}>
                          <div className='row-line-title warning'>Only those Jobs that the payroll is made will be listed in Payroll file.</div>
                        </div>
                      </div>
                      : selectedExportType === ExportType.TYPE_BILLING_INVOICE
                        ? <div>
                          <div className='section-row' style={{marginTop: '6px'}}>
                            <div className='row-line-title warning'>Only those claimed Jobs will be listed in Invoice file.</div>
                          </div>
                        </div>
                        : null }
                </Form>
              </Spin>
            </div>
          </Modal>
        </Page.Content>
      </Page.Body>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchList({ currentPage, filter, searchText, sort })
  }

  fetchDataList = async () => {
    const { billingPeriodCode } = this.state
    this.setState({ loading: true, loadingList: true, loadingDataSelect: true, loadingSummary: true })

    const p = await billingService.getBillingInfo(billingPeriodCode)

    this.setState({
      billingInfo: p && p.id ? p : {}
    }, async () => {
      this.fetchList({ page: 1 }, false)

      const c = await clientService.listAllClients()
      const e = await employeeService.listAllEmployees()

      const clientList = validator.isNotEmptyArray(c) ? [firstClient].concat(c) : []
      const employeeList = validator.isNotEmptyArray(e) ? [firstEmployee].concat(e) : []

      this.setState({
        clientList,
        employeeList,
        loadingDataSelect: false
      })
    })
  }

  fetchJobSummary = async () => {
    const { billingPeriodCode } = this.state
    this.setState({ loadingSummary: true })

    const r = await billingService.getSummaryInfo(billingPeriodCode)

    if (r && r.id) {
      this.setState({
        loadingSummary: false,
        summary: r
      })
    }
  }

  fetchList = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.BILLINGS.INFO.LIST)) return

    try {
      const { billingInfo, isLoaded } = this.state

      filter.period_code = { condition: '=', value: billingInfo.code }

      this.setState({
        currentPage,
        loading: isLoaded ? false : true,
        loadingList: isLoaded ? true : false
      })

      const r = await billingExportService.listExportByPage(currentPage, pageSize, filter, sort, searchText)

      if (r && validator.isArray(r.list)) {
        this.setState({
          isLoaded: true,
          loading: false,
          loadingList: false,
          list: r.list || [],
          total: r.total || 0
        })
      } else {
        this.handleLoadError(r)
      }
    } catch (e) {
      this.handleLoadError(e)
    }

    this.scrollToTop()
  }

  getFile = async (isSendEmail = false, isDownload = true, values = {}) => {
    const { selectedExport, selectedIds = [] } = this.state
    const { id, type, created_at: expCreatedAt } = selectedExport

    if (!this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)) {
      notify.error('Unable to download', 'You have no permission to download the file.')
      this.triggerSelectModal(false)
      return
    }

    this.setState({ loadingModalSelect: true }, async () => {
      // const paymentDate = values.payment_date || undefined
      const body = Object({}, selectedExport)
      body.selected_ids = _.cloneDeep(selectedIds)
      // body.payment_date = paymentDate

      try {
        const r = await exportFile.fetchExport(type, selectedExport)

        if (r && r.id && isSendEmail) {
          notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
        }

        this.setState({ loadingModalSelect: false })
        this.triggerSelectModal(false)
      } catch (e) {
        console.log('get file error', e)
        this.setState({ loadingModalSelect: false })
        notify.error('Unable to Download', 'Please try again later.')
      }
    })
  }

  getFileAll = async (exportType = null, isSendEmail = false, isDownload = true) => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { billingPeriodCode, selectedExportType } = this.state

    const finalExportedType = exportType || selectedExportType

    if (!this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)) {
      notify.error('Unable to download', 'You have no permission to download the file.')
      this.triggerSelectModal(false)
      return
    }

    this.setState({ loadingExport: true, loadingModalSelect: true }, () => {
      validateFieldsAndScroll(async (errors, values) => {
        if (!errors) {
          // const paymentDate = values.payment_date_all || undefined
          const body = {
            type: finalExportedType,
            // payment_date: paymentDate,
            period_code: billingPeriodCode
          }

          try {
            const r = await exportFile.fetchExport(finalExportedType, body)

            if (r && r.id && isSendEmail) {
              notify.success('Email Sent Successfully', 'Notification Emails have been sent out successfully.')
            }

            this.setState({ loadingExport: false, loadingModalSelect: false })
            this.triggerSelectAllModal(false)
          } catch (e) {
            console.log('get file all error', e)
            this.setState({ loadingExport: false, loadingModalSelect: false })
            notify.error('Unable to Download', 'Please try again later.')
          }
        }
      })
    })
  }

  handleJobListExpand = (index) => {
    const { list } = this.state

    if (index < list.length) {
      list[index].is_expand = !list[index].is_expand
    }

    this.setState({ list })
  }

  handleLoadError = (r) => {
    notify.error('Unable to load successfully', r && r.errors ? formatter.toErrorMessage(r.errors) : 'Unable to load billing re-download list successfully. Please try again later.')
  }

  preGetFile = (isSendEmail, isDownload) => {
    const { form } = this.props
    const { validateFieldsAndScroll } = form
    const { getFile } = this
    const { selectedIds = [] } = this.state

    const title = isSendEmail && isDownload
      ? `Are you sure to export and email selected items?`
      : isSendEmail
        ? `Are you sure to email selected items?`
        : `Are you sure to export selected items?`

    validateFieldsAndScroll(async (errors, values) => {
      if (!errors) {
        if (validator.isNotEmptyArray(selectedIds)) {
          confirm({
            title: title,
            content: 'Press OK to continue, Cancel to return',
            onOk () {
              getFile(isSendEmail, isDownload, values)
            }
          })
        } else {
          this.setState({ selectedIdsErrMsg: ItemsSelectErrMsg })
        }
      }
    })
  }


  triggerSelectModal = (isShowModal = false, item = {}) => {
    const { filterParam, loadingExport } = this.state
    const { setFieldsValue } = this.props.form
    let selectData = []
    let selectIds = []
    const type = item.type

    if (!this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)) return
    if (loadingExport) return

    if (isShowModal === true) {
      const items = item.items || []
      const ids = items.map(e => e.item_id)

      const selectedIds = _.cloneDeep(ids)

      this.setState({
        isShowModal,
        isIdsSelectAll: true,
        loadingModalSelect: false,
        selectedExport: item,
        selectedIds,
        selectedIdsOri: _.cloneDeep(selectedIds),
        selectedExportType: type
      }, () => {
        // if (item.payment_date) {
        //   setFieldsValue({payment_date: formatter.toMoment(item.payment_date)})
        // }
      })
    } else {
      this.setState({
        isShowModal,
        isIdsSelectAll: false,
        loadingModalSelect: false,
        selectedExport: {},
        selectedIds: [],
        selectedIdsOri: [],
        selectedExportType: null
      }, () => {
        // setFieldsValue({ payment_date: null })
      })
    }
  }

  triggerSelectAllModal = (isShowAllModal = false, type) => {
    const { loadingExport } = this.state
    const { setFieldsValue } = this.props.form

    if (!this.hasAccess(Permissions.BILLINGS.EXPORT.DOWNLOAD)) return
    if (loadingExport) return

    if (isShowAllModal === true) {
      this.setState({
        isShowAllModal,
        selectedExportType: type
      }, () => {
        // if (type === ExportType.TYPE_BILLING_PRODA) {
        //   setFieldsValue({ payment_date_all: formatter.toMoment(new Date()) })
        // }
      })


    } else {
      this.setState({
        isShowAllModal,
        selectedExportType: null
      }, () => {
        // if (type === ExportType.TYPE_BILLING_PRODA) {
        //   setFieldsValue({ payment_date_all: formatter.toMoment(new Date()) })
        // }
      })
    }
  }

  updateModalIdsSelect = (e, id) => {
    const check = e.target.checked
    let { selectedIds, selectedIdsOri } = this.state
    const r = selectedIds.findIndex(f => f === id)
    if (check) {
      if (!(r > -1)) {
        selectedIds.push(id)
      }
    } else {
      if (r > -1) {
        selectedIds.splice(r, 1)
      }
    }

    this.setState({
      selectedIds,
      selectedIdsErrMsg: validator.isNotEmptyArray(selectedIds) ? '' : ItemsSelectErrMsg,
      isIdsSelectAll: selectedIds.length < selectedIdsOri.length ? false : true
    })
  }

  updateModalIdsSelectAll = (e) => {
    const check = e.target.checked
    let { selectedIds, selectedIdsOri } = this.state

    let newIdsSelect = []

    if (check) {
      newIdsSelect = _.cloneDeep(selectedIdsOri)
    } else {
      newIdsSelect = []
    }

    this.setState({ selectedIds: newIdsSelect, isIdsSelectAll: check, selectedIdsErrMsg: '' })
  }

  validatePaymentDate = (rule, value, callback) => {
    const { selectedExport } = this.state
    if (value === null || value === undefined || value === '' || value === 0) {
      callback(new Error(`Payment Date is required`))
    } else {
      if (selectedExport && selectedExport.id) {
        const m = moment(selectedExport.created_at).startOf('day')
        const v = moment.isMoment(value) ? value : moment(value)

        if (v.isBefore(m)) {
          callback(new Error(`Payment Date cannot be earlier than file created day.`))
        } else {
          callback()
        }
      } else {
        callback()
      }
    }
  }

  scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0)
    }
  }
}

const mapDispatchToProps = {

}

const mapStateToProps = (state) => {
  return { ...state }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(BillingExportList))
