import React, { Component } from 'react'
import './styles.css'

export default class Checkbox extends Component {
  render () {
    const { children, className, feedback = false, defaultChecked = undefined, checked = undefined, onClick = null } = this.props
    const properties = { ...this.props }
    delete properties.feedback
    delete properties.defaultValue
    delete properties.onClick
    return (
      <label className='check-container'>
        <input
          type='checkbox'
          defaultChecked={defaultChecked}
          checked={checked}
          onClick={onClick}
        />
        <span className='checkmark' />
      </label>
    )
  }
}
