import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import debounce from 'lodash.debounce'
import { clientProviderService, logService, settingGeneralService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import moment from 'moment-timezone'

// UI
import { Button, ControlLabel, FileUpload, Loading, List, Notification, Panel, Pager, SectionTitle, SideModal, SearchInput, Spin } from '../../../components'
import { Permissions } from '../../../constants'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Popconfirm from 'antd/lib/popconfirm'
import Popover from 'antd/lib/popover'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Switch from 'antd/lib/switch'
import Tabs from 'antd/lib/tabs'
import Tooltip from 'antd/lib/tooltip'
import { auth, formatter, log, uploader, validator } from '../../../util'
import { apiHostname } from '../../../config'

import AddProviderModal from '../AddProviderModal'

import './styles.css'

const { Item: FormItem } = Form
const { TextArea } = Input
const TabPane = Tabs.TabPane
const notify = Notification

const Option = Select.Option
const pageSize = 20

const timezone = 'Australia/Melbourne'
moment.tz.setDefault(timezone)

const formItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 6 },
  wrapperCol: { sm: 14, md: 14, lg: 17 }
}

const sideBySideFormItemLayout = {
  labelCol: { sm: 6, md: 6, lg: 12 },
  wrapperCol: { sm: 14, md: 14, lg: 10 }
}

export class ClientProvider extends Component {
  constructor (props) {
    super(props)
    this.state = {
      allProviders: [],
      allServices: [],
      currentServices: [],
      loadingList: false,
      loadingProvider: false,
      loadingService: false,
      loadingUpdate: false,
      providers: { list: [], total: 0 },
      providerInfo: {},
      showProviderModal: false,
    }
  }

  componentDidMount () {
    this.fetchProviders()
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.clientId !== this.props.clientId) {
      if (nextProps.clientId) {
        this.fetchProviders(nextProps.clientId)
      }
    }
  }

  render () {
    const { form, clientId } = this.props
    const { allProviders, currentServices, loadingList, loadingProvider, loadingUpdate, providers, providerInfo, showProviderModal } = this.state

    const columns = [
      {
        title: 'Name',
        width: 4,
        render: ({ provider_fullname }) => <div>{provider_fullname}</div>
      },
      {
        title: 'Start Date',
        width: 4,
        render: ({ start_date }) => <div>{formatter.toShortDate(start_date)}</div>
      },
      {
        title: 'Services',
        width: 4,
        render: ({ services_name }) => <div>{validator.isArray(services_name) ? services_name.join(', ') : services_name}</div>
      },
      {
        title: 'Contact',
        width: 4,
        render: ({ cmsc_first_name, cmsc_last_name, cmsc_contact, cmsc_email }) => {
          const isShowPopover = cmsc_contact || cmsc_first_name || cmsc_email
          return (<span>
            {cmsc_first_name}&nbsp;&nbsp;
            { isShowPopover
              ? <span style={{cursor: 'help'}}>
                <Popover
                content={<div>
                  {cmsc_contact ? <div>{cmsc_contact}<br /></div> : null}
                  {cmsc_email ? <div>{cmsc_email}<br /></div> : null}
                </div>}
                title={`${cmsc_first_name || ''}`}>
                <Icon type='info-circle' />
              </Popover></span>
              : null }
            </span>)
        }
      },
      {
        title: 'Active',
        width: 1,
        render: ({ active }) =>  active
          ? <div style={{ color: '#4fbc85', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
          : <div style={{ color: '#EC7063', fontSize: '11pt' }}><Icon type='close-circle' theme='filled' /></div>
      },
      {
        title: 'Action',
        width: 1,
        render: (item) => <div className='action-buttons'>
          { this.hasAccess(Permissions.PARTICIPANT.PROVIDER.READ) || this.hasAccess(Permissions.PARTICIPANT.PROVIDER.UPDATE)
            ? <Tooltip mouseLeaveDelay={0} title='Edit'>
              <div onClick={() => this.showAddProviderModal(true, item)}>
                <Icon type='form' />
              </div>
            </Tooltip>
            : null }
          { this.hasAccess(Permissions.PARTICIPANT.PROVIDER.DELETE)
            ? <Tooltip mouseLeaveDelay={0} title='Delete'>
              <Popconfirm
                title='Confirm to remove this provider?'
                onConfirm={() => this.removeProvider(item)}
                okText='Yes'
                cancelText='No'
              >
                <Icon type='delete' />
              </Popconfirm>
            </Tooltip>
            : null }
        </div>
      }
    ]

    return (
      <Panel title={'Providers'}
        subtitle={ this.hasAccess(Permissions.PARTICIPANT.PROVIDER.CREATE)
          ? <div className='btn' onClick={() => this.showAddProviderModal(true)}>
              Add Provider
          </div>
          : null
        }
      >
        <Skeleton loading={loadingList} active>
          <List cols={columns} rows={providers.list} />

          <AddProviderModal
            clientId={clientId}
            item={providerInfo}
            loadingProvider={loadingProvider}
            providerList={allProviders}
            currentServices={currentServices}
            visible={showProviderModal}
            onClose={() => this.showAddProviderModal(false)}
            onUpdate={() => this.onUpdateProvider()}
          />
        </Skeleton>
      </Panel>
    )
  }

  fetchProviders = async (cid = null) => {
    // the sequence of fetchAllServices and fetchAllAvailableProviders are specially allocated to hide the id when open up the modal as possible
    this.fetchAllServices()

    if (!this.hasAccess(Permissions.PARTICIPANT.PROVIDER.LIST)) return

    const clientId = cid || this.props.clientId
    this.setState({ loadingList: true })

    if (!clientId) return

    const r = await clientProviderService.listAll(clientId)

    if (r && r.list) {
      this.setState({ providers: r, loadingList: false })
    } else {
      this.setState({ loadingList: false })
    }

    this.fetchAllAvailableProviders(clientId)
  }

  fetchAllAvailableProviders = async (cid = null) => {
    if (!this.hasAccess(Permissions.PARTICIPANT.PROVIDER.LIST)) return

    const clientId = cid || this.props.clientId
    if (!clientId) return

    this.setState({ loadingProvider: true })

    const r = await clientProviderService.getAllProvider(clientId)

    if (validator.isArray(r)) {
      this.setState({ allProviders: r, loadingProvider: false })
    } else {
      this.setState({ loadingProvider: false })
    }
  }

  fetchAllServices = async () => {
    this.setState({ loadingService: true })

    const filter = {}
    filter.identifier = { condition: '=', value: 'sc-provider-services' }
    filter.active = { condition: '=', value: true }

    const settings = await settingGeneralService.listByPage(1, 0, filter)

    if (settings && validator.isArray(settings.list)) {
      this.setState({ allServices: settings.list, loadingService: false })
    } else {
      this.setState({ loadingService: false })
    }
  }

  removeProvider = (item) => {
    const { loadingList } = this.state

    if (loadingList) return

    this.setState({ loadingList: true }, async () => {
      const r = await clientProviderService.remove(item.id)

      if (r && r.id) {
        this.handleRemoveSuccessful()
        this.fetchProviders()
      } else {
        this.handleRemoveError(r)
      }
    })
  }

  showAddProviderModal = (showProviderModal, info = {}) => {
    const { allServices } = this.state

    let currentServices = []

    if (info && info.id && info.sc_provider_services) {
      currentServices = allServices.filter(e => info.sc_provider_services.findIndex(f => f === e.id) > -1)
    }

    this.setState({
      currentServices,
      showProviderModal,
      providerInfo: info
    }, () => {
      if (showProviderModal === true) {
        this.fetchAllAvailableProviders()
      }
    })
  }

  onUpdateProvider = () => {
    this.fetchProviders()
  }

  handleRemoveSuccessful = () => {
    notify.success('Deleted successfully', 'Participant Provider deleted successfully.')
  }

  handleRemoveError = (e) => {
    if (e && e.errors && validator.isNotEmptyArray(e.errors)) {
      notify.error('Unable to delete successfully', `${formatter.toErrorMessage(e.errors)}`)
    } else if (e && e.message) {
      notify.error('Unable to delete successfully', `${e.message}`)
    } else {
      notify.error('Unable to delete successfully', 'Unable to delete Participant Provider successfully. Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ClientProvider))
