import React, { Component } from 'react'
import { connect } from 'react-redux'
import { logService } from '../../../services'
import { setRefreshActivityLog } from '../../../states/actions'
import Moment from 'moment-timezone'

// UI
import { Loading, List, Pager } from '../../../components'
import Form from 'antd/lib/form'
import Skeleton from 'antd/lib/skeleton'

import './styles.css'

const pageSize = 20

const timezone = 'Australia/Melbourne'
Moment.tz.setDefault(timezone)

export class ProviderActivity extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      total: 0,
      logList: [],
      loading: false
    }
    this.filterLogs = this.filterLogs.bind(this)
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.shouldRefreshActivityLog !== this.props.shouldRefreshActivityLog) {
      if (nextProps.shouldRefreshActivityLog) {
        this.fetchLogs(this.state.currentPage)
      }
    }

    if (nextProps.providerId !== this.props.providerId) {
      if (nextProps.providerId) {
        this.fetchLogs(this.state.currentPage, nextProps.providerId)
      }
    }
  }

  componentDidMount () {
    const { currentPage } = this.state
    this.fetchLogs(currentPage)
  }

  filterLogs (e) {
    this.setState({ currentPage: 1 })
    this.fetchLogs(e.target.value, 1)
  }

  render () {
    const { currentPage, loading, logList, total } = this.state

    const logColumns = [
      {
        title: 'Created At',
        width: 5,
        render: ({ created_at }) => Moment(created_at).format('DD/MM/YYYY hh:mm A')
      },

      {
        title: 'Updated By',
        width: 4,
        render: ({ member }) => <div className='updated-name' style={{ textTransform: 'capitalize' }}>{member}</div>
      },

      {
        title: 'Action',
        width: 3,
        render: ({ action }) => <div className='updated-name' style={{ textTransform: 'uppercase' }}>{action}</div>
      },

      {
        title: 'Changes',
        width: 12,
        key: 'changes'
      }
    ]

    return (
      <Loading loading={loading} blur>

        <div className='provider-activity-list'>
          <Skeleton loading={loading} active>
            <List cols={logColumns} rows={logList} />

          </Skeleton>
        </div>

        <Pager
          size={pageSize}
          total={total}
          totalText={`Total ${total} log${total === 1 ? '' : 's'}`}
          current={currentPage}
          onChange={(e) => this.changePage(e)}
          style={{ marginTop: '15px' }}
        />

      </Loading>
    )
  }

  fetchLogs = async (startPage = null, pid = null) => {
    this.setState({ loading: true })

    const { currentPage } = this.state
    const filter = { }

    const providerId = pid || this.props.providerId
    const page = startPage || currentPage

    if (!providerId) return

    filter.genre = { condition: '=', value: 'provider' }
    filter.genre_id = { condition: '=', value: providerId }

    const { list: logList, total } = await logService.listByPage(page, pageSize, filter)
    this.setState({ logList, total, loading: false, currentPage: page, filter })

    setTimeout(() => {
      this.props.setRefreshActivityLog(false)
    }, 200)
  }

  changePage = async (currentPage) => {
    this.fetchLogs(currentPage)
  }
}

const mapDispatchToProps = {
  setRefreshActivityLog
}

const mapStateToProps = (state) => {
  return { ...state.Client, ...state.General }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(ProviderActivity))
