import React, { Component } from 'react'
import { connect } from 'react-redux'
import SettingMenu from '../../../../constants/menu/setting'
import { Link, withRouter } from 'react-router-dom'
import { settingGeneralService } from '../../../../services/setting'
import { authService } from '../../../../services'

// UI
import { Button, Loading, Page, Pager, Panel, SideModal, List } from '../../../../components'
import { Permissions } from '../../../../constants'
import Form from 'antd/lib/form'
import Switch from 'antd/lib/switch'
import Icon from 'antd/lib/icon'
import Input from 'antd/lib/input'
import Col from 'antd/lib/col'
import Row from 'antd/lib/row'
import Skeleton from 'antd/lib/skeleton'
import Popconfirm from 'antd/lib/popconfirm'
import message from 'antd/lib/message'

import './styles.css'
import notify from '../../../../components/Notification'

import { auth, formatter, validator } from '../../../../util'

const pageSize = 20
const { Item: FormItem } = Form

const SetList = [
  { tabId: 1, path: '/providers', type: 'sc-provider-services', title: 'Providers', buttonTitle: 'Provider Service' },
  { tabId: 2, path: '/support-coords', type: 'sc-supp-coords', title: 'Support Coordinators', buttonTitle: 'SC Service' }
]

export class SettingServicesList extends Component {
  constructor (props) {
    super(props)
    const set = SetList.find(e => e.type === props.type || e.type === `/${props.type}`)
    this.state = {
      currentPage: 1,
      filter: {},
      isItemEditable: false,
      isShowModal: false,
      list: [],
      loading: false,
      loadingForm: false,
      searchText: '',
      sort: {},
      selectedItem: {},
      set: set || SetList[0],
      type: props.type || SetList[0].type,
      total: 0,
    }
  }

  componentDidMount () {
    this.refreshList()
  }

  refreshList = () => {
    const { currentPage } = this.state
    this.fetchServiceList({ currentPage })
  }

  fetchServiceList = async ({ currentPage = 1, filter = {}, sort = {}, searchText }) => {
    if (!this.hasAccess(Permissions.SETTING.SERVICES.LIST)) return

    const { set } = this.state
    try {
      this.setState({ currentPage, loading: true })

      const settings = await settingGeneralService.listByPage(currentPage, pageSize, { identifier: set.type })

      this.setState({ list: settings.list, loading: false, total: settings.total })
    } catch (e) {
      notify.error('Unable to load successfully', 'Unable to load settings successfully. Please try again later.')
    }
  }

  showModal = (item = {}) => {
    const isItemEditable = !!this.hasAccess(Permissions.SETTING.SERVICES.UPDATE) && (!item.id || item.id && item.is_editable === true)
    this.setState({ isShowModal: true, isItemEditable, selectedItem: item })
  }

  hideModal = () => {
    const { form } = this.props

    this.setState({ isShowModal: false, isItemEditable: false, selectedItem: {} }, () => {
      form.resetFields()
    })
  }

  async handleDelete (id) {
    const { set } = this.state
    this.setState({ loading: true })
    const res = await settingGeneralService.remove(id)

    if (res && res.id) {
      notify.success('Deleted successfully', `${formatter.capitalize(set.title)} deleted successfully.`)
    } else {
      notify.error('Unable to delete successfully', `Unable to delete ${set.title} successfully. Please try again later.`)
    }

    this.refreshList()
  }

  handleSave = () => {
    const { form, match } = this.props
    const { validateFields } = form
    const { selectedItem, set } = this.state

    validateFields(async (errors, values) => {
      if (!errors) {
        try {
          this.setState({ loadingForm: true })

          values.value = values.name.trim().toLowerCase()

          const body = {
            name: values.name,
            active: values.active,
            value: values.value
          }

          let r = null
          if (selectedItem.id) {
            r = await settingGeneralService.save(selectedItem.id, body)
          } else {
            body.identifier = set.type
            r = await settingGeneralService.add(body)
          }

          if (r && r.id) {
            notify.success('Saved successfully', `${set.buttonTitle} saved successfully.`)
            this.refreshList()
            this.hideModal()
          } else {
            notify.error('Unable to save successfully', `Unable to save ${set.buttonTitle} successfully. Please try again later.`)
          }

          this.setState({ loadingForm: false })
        } catch (e) {
          this.setState({ loadingForm: false })
          notify.error('Unable to save successfully', `Unable to save ${set.buttonTitle} successfully. Please try again later.`)
        }
      }
    })
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }

  render () {
    const { currentPage, isShowModal, isItemEditable, list, loading, loadingForm, selectedItem, total, set, type } = this.state
    const { form } = this.props
    const { getFieldDecorator } = form

    const columns = [
      {
        title: 'Name',
        width: 16,
        render: ({ id, name }) => <div>{name}</div>
      },
      {
        title: 'Enable',
        width: 6,
        render: ({ active }) => <div style={{ color: active ? '#4fbc85' : '#ccc', fontSize: '11pt' }}><Icon type='check-circle' theme='filled' /></div>
      },
      {
        title: '',
        width: 1,
        render: (item) => (
          this.hasAccess(Permissions.SETTING.SERVICES.READ) && !this.hasAccess(Permissions.SETTING.SERVICES.UPDATE)
          ? <div style={{cursor: 'pointer'}} onClick={() => this.showModal(item)}><Icon type='eye' /></div>
          : this.hasAccess(Permissions.SETTING.SERVICES.UPDATE)
            ? <div style={{cursor: 'pointer'}} onClick={() => this.showModal(item)}><Icon type='form' /></div>
            : null
        )
      },
      {
        title: '',
        width: 1,
        render: ({ id, is_editable }) => this.hasAccess(Permissions.SETTING.SERVICES.DELETE) && is_editable
        ? <Popconfirm
          title='Confirm to delete this?'
          onConfirm={(e) => this.handleDelete(id)}
          okText='Yes'
          cancelText='No'
          >
            <Icon type='delete' />
          </Popconfirm>
        : null
      }
    ]

    return (
      <div>
        <div className='setting-list'>
          <Skeleton loading={loading} active>
            <div className='header-action'>
              { this.hasAccess(Permissions.SETTING.SERVICES.LIST)
                ? <div className='btn btn-ghost' onClick={() => this.fetchServiceList({ currentPage: 1 })}>
                  {`Refresh`}
                </div>
                : null }
              { this.hasAccess(Permissions.SETTING.SERVICES.CREATE)
                ? <div className='btn' onClick={() => this.showModal()}>
                  {`Add ${set.buttonTitle}`}
                </div>
                : null }
            </div>

            <List cols={columns} rows={list} />

            <Pager
              size={pageSize}
              total={total}
              totalText={`Total ${total} ${set.buttonTitle}${total === 1 ? '' : 's'}`}
              current={currentPage}
              onChange={this.changePage}
              style={{ marginTop: '15px' }}
            />
          </Skeleton>

          <SideModal
            title={`${set.buttonTitle}`}
            showModal={isShowModal}
            onClose={this.hideModal}
            buttons={[
              <Button key='save' onClick={this.handleSave} feedback={loadingForm}>Save</Button>
            ]}
          >
            <Loading loading={loadingForm} blur>
              <Form layout='vertical'>
                <FormItem label='Name'>
                  {getFieldDecorator('name', {
                    initialValue: selectedItem.name,
                    rules: [
                      { required: true, message: 'Please enter name' },
                      { whitespace: true, message: 'Please enter name' }
                    ]
                  })(
                    <Input disabled={!isItemEditable}/>
                  )}
                </FormItem>
                <FormItem label=''>
                  {getFieldDecorator('active', {
                    initialValue: selectedItem.active !== undefined ? selectedItem.active : true,
                    valuePropName: 'checked'
                  })(
                    <Switch
                      disabled={loadingForm}
                      checkedChildren='Enable'
                      unCheckedChildren='Disable'
                    />
                  )}
                </FormItem>
              </Form>
            </Loading>
          </SideModal>
        </div>
      </div>
    )
  }

  changePage = (currentPage) => {
    const { filter, searchText, sort } = this.state
    this.fetchServiceList({ loading: true, currentPage, filter, sort, searchText })
  }
}

const mapDispatchToProps = {
}

const mapStateToProps = (state) => {
  return state
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form.create()(withRouter(SettingServicesList)))
