import { combineReducers } from 'redux'

import ClientReducer from './client'
import EmployeeReducer from './employee'
import FunderReducer from './funder'
import DashboardReducer from './dashboard'
import GeneralReducer from './general'
import ProviderReducer from './provider'
import PayrollReducer from './payroll'

export default combineReducers({
  Client: ClientReducer,
  Employee: EmployeeReducer,
  Funder: FunderReducer,
  Dashboard: DashboardReducer,
  General: GeneralReducer,
  Provider: ProviderReducer,
  Payroll: PayrollReducer,
})
