import { FETCHING_CLIENTS, CLIENTS_FETCHED, FETCHING_CLIENT_LIMIT, CLIENT_LIMIT_FETCHED } from '../actions/client'

const INITIAL_STATE = {
  clients: { list: [], total: 0 },
  clientLimit: {},
  loading: true
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CLIENTS:
      return handleFetchingClients(state, action)
    case CLIENTS_FETCHED:
      return handleClientsFetched(state, action)
    case FETCHING_CLIENT_LIMIT:
      return handleFetchingClientLimit(state, action)
    case CLIENT_LIMIT_FETCHED:
      return handleClientLimitFetched(state, action)
    default:
      return state
  }
}

function handleFetchingClients (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleClientsFetched (state, action) {
  const { clients, clientLimit, loading } = action
  return { ...state, clients, clientLimit, loading }
}

function handleFetchingClientLimit (state, action) {
  const { loading } = action
  return { ...state, loading }
}

function handleClientLimitFetched (state, action) {
  const { clientLimit, loading } = action
  return { ...state, clientLimit, loading }
}
