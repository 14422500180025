import Permissions from '../permissions'

export default [
  {
    name: 'Support Coordinators',
    desc: 'Manage all Support Coordinators',
    icon: 'team',
    link: '/employees',
    enable: true,
    permission: Permissions.EMPLOYEE.INFO.LIST
  }
]
