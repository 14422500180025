import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReportMenu from '../../../constants/menu/report'
import { authService, reportTypeService } from '../../../services'
import { auth, exportFile, formatter, validator } from '../../../util'

// UI
import { Button, ControlLabel, List, Page, Pager } from '../../../components'
import { Permissions, ReportType } from '../../../constants'
import { apiHostname } from '../../../config'
import notify from '../../../components/Notification'
import Col from 'antd/lib/col'
import Form from 'antd/lib/form'
import Icon from 'antd/lib/icon'
import Modal from 'antd/lib/modal'
import Radio from 'antd/lib/radio'
import Row from 'antd/lib/row'
import Select from 'antd/lib/select'
import Skeleton from 'antd/lib/skeleton'
import Spin from 'antd/lib/spin'
import Tabs from 'antd/lib/tabs'
import Upload from 'antd/lib/upload'

import '../styles.css'

const pageSize = 40

const ThresholdLowerPercentage = 25
const ThresholdUpperPercentage = 75
const ThresholdDayCount = 60

const title = `Participants' Plan Details`
const DefaultSort = { end_day_count: 1 }

class ReportClientPlanDetails extends Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      filter: {},
      list: { list: [], total: 0 },
      isGenerating: false,
      isLoaded: false,
      loading: false,
      loadingList: false,
      sort: Object.assign({}, DefaultSort)
    }
  }

  componentDidMount () {
    this.fetchReport()
  }

  render () {
    const { currentPage, isGenerating, list, loading, loadingList } = this.state

    const columns = [
      {
        title: 'Participant',
        width: 5,
        key: 'client_fullname',
        render: ({ client_ref_id, client_fullname }) => <a href={`/participants/${client_ref_id}/funding-plan`} rel='noopener noreferrer' target='_blank'>{client_fullname}</a>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Plan Allocated Hours',
        width: 2,
        key: 'allocated_hrs',
        render: ({ allocated_hrs }) =>  <div>{formatter.toFloatDecimal(allocated_hrs)}</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Usable Hours',
        width: 2,
        key: 'actual_hrs',
        render: ({ is_tbd, actual_hrs }) => is_tbd
          ? <div className='disabled-text'>TBC</div>
          : <div>{formatter.toFloatDecimal(actual_hrs)}</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Used SC Hours',
        width: 2,
        key: 'sum_job_duration_hrs',
        render: ({ is_tbd, sum_job_duration_hrs, sum_job_duration_hrs_percentage }) => {
          const pcs = formatter.toFloatDecimal(sum_job_duration_hrs_percentage)
          const className = sum_job_duration_hrs_percentage === null
            ? 'disabled-text'
            : pcs >= 100
            ? 'overmax-text'
            : pcs > ThresholdUpperPercentage
              ? 'warning-text'
              : ''
          return (is_tbd
          ? <div className='disabled-text'>TBC</div>
          : <div className={className}>
            <div>{formatter.toFloatDecimal(sum_job_duration_hrs)}</div>
            <div>{`(${pcs}%)`}</div>
          </div>)
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Remaining SC Hours',
        width: 2,
        key: 'remaining_hrs',
        render: ({ is_tbd, remaining_hrs, remaining_hrs_percentage }) => {
          const pcs = formatter.toFloatDecimal(remaining_hrs_percentage)
          const className = remaining_hrs_percentage === null
            ? 'disabled-text'
            : pcs <= 0
            ? 'overmax-text'
            : pcs < ThresholdLowerPercentage
              ? 'warning-text'
              : ''
          return (is_tbd
          ? <div className='disabled-text'>TBC</div>
          : <div className={className}>
            <div>{formatter.toFloatDecimal(remaining_hrs)}</div>
            <div>{`(${pcs}%)`}</div>
          </div>)
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Remaining Plan Days',
        width: 2,
        key: 'end_day_count',
        render: ({ end_day_count, end_day_count_percentage }) => {
          const pcs = formatter.toFloatDecimal(end_day_count_percentage)
          const className = end_day_count_percentage === null
            ? 'overmax-text'
            : pcs <= 0
            ? 'disabled-text'
            : pcs < ThresholdLowerPercentage
              ? 'warning-text'
              : ''
          const pct = end_day_count_percentage === null
            ? 'OVER'
            : pcs == 0
              ? 'Not Started'
              : `${pcs}%`
          return (
            <div className={className}>
              <div>{formatter.toFloatDecimal(end_day_count)} Days</div>
              <div>{`(${pct})`}</div>
            </div>
          )
        },
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Plan Starts On',
        width: 2,
        key: 'period_start_date',
        render: ({ period_start_date }) => <div>{ formatter.toShortDate(period_start_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
      {
        title: 'Plan Ends On',
        width: 2,
        key: 'period_end_date',
        render: ({ period_end_date }) => <div>{ formatter.toShortDate(period_end_date) }</div>,
        onSort: (key, order) => this.handleSort({[key]: order})
      },
    ]

    return (
      <Page.Body>
        <Page.Left>
          <Page.Menu title='Home' menu={ReportMenu} />
        </Page.Left>


        <Page.Content full>
          <Page.Header title={title}>
            { this.hasAccess(Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST)
              ? <Button feedback={isGenerating} onClick={() => this.export()}>
                EXPORT
              </Button>
              : null }
          </Page.Header>

          <div style={{marginTop: '24px'}}>
            <Skeleton loading={loading} active>
              <Spin spinning={loadingList}>
                <div className='report'>
                  <div className='report-title'>{`Participants' Plan Details `} <span className='report-tag'>{list.total}</span></div>
                  <List cols={columns} rows={list.list} />

                  <Pager
                    size={pageSize}
                    total={list.total}
                    totalText={`Total ${list.total} Record${list.total === 1 ? '' : 's'}`}
                    current={currentPage}
                    onChange={(e) => this.onChangePage(e)}
                    style={{ marginTop: '15px' }}
                  />
                </div>
              </Spin>
            </Skeleton>
          </div>
        </Page.Content>
      </Page.Body>
    )
  }

  onChangePage = (page) => {
    this.setState({ currentPage: page }, () => {
      this.fetchReport()
    })
  }

  fetchReport = () => {
    const { currentPage, filter, isLoaded, loading, sort } = this.state
    this.fetchClientReport({ currentPage, filter, isLoaded, loading, sort })
  }

  fetchClientReport = async ({ currentPage, filter, isLoaded = true, loading, sort }) => {
    if (this.hasAccess(Permissions.REPORT.CLIENT_PLAN_DETAILS.LIST)) {
      if (isLoaded) {
        this.setState({ loadingList: true })
      } else {
        this.setState({ loading: true })
      }

      try {
        const list = await reportTypeService.listReport(ReportType.SC_CLIENT_PLAN_DETAILS, filter, sort)

        if (list) {
          this.setState({
            list,
            isLoaded: true,
            loadingList: false,
            loading: false
          })
        } else {
          notify.error(`Unable to get ${title} Report`, 'Please try again later.')
        }
      } catch (e) {
        notify.error(`Unable to get ${title} Report`, 'Please try again later.')
      }

      if (window) window.scrollTo({ top: 0 })
    }
  }

  handleSort = (sort, statusType) => {
    for (var s in sort) {
      if (sort[s] === 0) {
        delete sort[s]
      }
    }

    if (!validator.isNotEmptyObject(sort)) {
      sort = Object.assign({}, DefaultSort)
    }

    this.setState({ sort, currentPage: 1 }, () => {
      this.fetchReport()
    })
  }

  export = async () => {
    try {
      this.setState({ isGenerating: true })

      const r = await exportFile.fetchReportExport(ReportType.SC_CLIENT_PLAN_DETAILS)
      setTimeout(() => {
        this.setState({ isGenerating: false })
      }, 2000)
    } catch (e) {
      this.setState({ isGenerating: false })
      notify.error(`Unable to download ${title} Report`, 'Please try again later.')
    }
  }

  hasAccess (accessLevel) {
    return auth.hasAccess(accessLevel)
  }
}

export default ReportClientPlanDetails
