import Permissions from '../permissions'

export default [
  {
    name: 'Custom Identifiers',
    icon: 'control',
    link: '/settings/custom-identifier',
    desc: 'Configure Custom Identifier',
    enable: true,
    permission: Permissions.SETTING.CUSTOM_IDF.LIST
  },
  {
    name: 'File',
    icon: 'file-protect',
    link: '/settings/files',
    desc: 'File Category Settings',
    enable: true,
    permission: Permissions.SETTING.FILES.LIST
  },
  {
    name: 'Profile',
    icon: 'idcard',
    link: '/settings/profile',
    permission: [
      Permissions.SETTING.PLANGUAGE.LIST,
      Permissions.SETTING.PRELATIONSHIP.LIST
    ],
    enable: true,
    desc: 'Configure profile settings'
  },
  {
    name: 'Rate Set & Categories',
    icon: 'apartment',
    link: '/settings/rate-set',
    desc: 'Configure Rate Sets and Categories',
    enable: true,
    permission: Permissions.SETTING.RATESETS.LIST
  },
  {
    name: 'Reasons',
    link: '/settings/reasons',
    icon: 'exception',
    desc: 'Configure reasons of cancellation',
    enable: true,
    permission: Permissions.SETTING.REASON.LIST
  },
  {
    name: 'Services',
    link: '/settings/services',
    icon: 'tool',
    desc: 'Configure services',
    enable: true,
    permission: Permissions.SETTING.SERVICES.LIST
  },
  {
    name: 'Tasks',
    link: '/settings/tasks',
    icon: 'container',
    desc: 'Configure tasks',
    enable: true,
    permission: Permissions.SETTING.TASKSTATUS.LIST
  },
  {
    name: 'Others',
    icon: 'setting',
    link: '/settings/others',
    desc: 'Miscellaneous configuration',
    enable: true,
    permission: Permissions.SETTING.OTHERS.LIST
  },
]
