import jwt from 'jsonwebtoken'
import { localStorage, sessionStorage, notification, validator, request } from '../util'

const headers = { 'Content-Type': 'application/json' }
const url = '/api'

export default {
  getCurrentToken () {
    return localStorage.getItem('token')
  },
  getCurrentUser () {
    try {
      const tokenUser = localStorage.getItem('user-token')
      const user = jwt.verify(tokenUser, this.getCurrentToken())

      return user
    } catch (e) {
      this.forceSignOut()
      console.log('pms cu err', e)
      return {}
    }
  },
  hasAccess (permission) {
    /** TODO: amend on hasAccess once updated the access right */
    return true
    // try {
    //   const pms = localStorage.getItem('permissions', '')
    //   if (permission && pms) {
    //     const permissions = jwt.verify(pms, this.getCurrentToken())
    //     return validator.isNotEmptyArray(permissions) ? permissions.indexOf(permission) > -1 : false
    //   }

    //   return false
    // } catch (e) {
    //   // console.log('pms err', e)
    //   return false
    // }
  },
  setCurrentUser (user) {
    const token = this.getCurrentToken()
    const tokenUser = jwt.sign(user, token)
    localStorage.setItem('user-token', tokenUser)
  },
  setPermission (permission) {
    try {
      const token = this.getCurrentToken()
      const pms = JSON.stringify(permission)
      const tokenPms = jwt.sign(pms, token)
      localStorage.setItem('permissions', tokenPms)
    } catch (e) {
      // console.log('pms error', e)
    }
  },
  isSignedIn () {
    const token = localStorage.getItem('token')
    return token && token !== 'undefined' && token.length > 0
  },
  resetPassword (username) {
    try {
      const result = request(`${url}/reset-password`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  signIn (username, password, newPassword, rememberMe) {
    try {
      const { location } = window
      const { search } = location
      const result = request(`${url}/login${search}`, {
        method: 'POST',
        headers,
        body: JSON.stringify({ username, password, newPassword, rememberMe })
      })
      return result
    } catch (e) {
      console.error(e)
    }
  },
  forceSignOut () {
    try {
      localStorage.clear()
      sessionStorage.clear()
      window.location.href = '/login'
    } catch (e) {
      console.log('force sign out', e)
    }
  },
  signOut () {
    try {
      const token = this.getCurrentToken()
      const user = this.getCurrentUser()

      if (token && user && user.id) {
        const result = request(`${url}/logout`, {
          method: 'POST',
          headers,
          body: JSON.stringify({ token, user })
        })

        return result
      } else {
        this.forceSignOut()
      }
    } catch (e) {
      console.error(e)
    }
  },
  unauthorizeAction (redirectPath, duration, callback) {
    if (typeof callback === 'function') {
      callback()
    } else {
      redirectPath = typeof redirectPath === 'string' ? redirectPath : '/'
      duration = typeof duration === 'number' ? duration : 3000
      notification.show('error', 'You do not have permission to perform this action.')
      setTimeout(() => window.location.replace(redirectPath), duration)
    }
  }
}
