import { put, takeEvery } from 'redux-saga/effects'
import {
  FETCH_CLIENTS, FETCHING_CLIENTS, CLIENTS_FETCHED, FETCH_CLIENT_LIMIT, FETCHING_CLIENT_LIMIT, CLIENT_LIMIT_FETCHED
} from '../actions/client'
import clientService from '../../services/client'

function * fetchClients ({ loading, currentPage, pageSize, filter, sort, searchText }) {
  try {
    yield put({ type: FETCHING_CLIENTS, loading })
    const [clients, clientLimit] = yield Promise.all([
      clientService.listByPage(currentPage, pageSize, filter, sort, searchText),
      clientService.getLimit()
    ])
    yield put({ type: CLIENTS_FETCHED, clients, clientLimit, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_CLIENTS, loading: false })
    console.error(e)
  }
}

function * fetchClientLimit ({ loading }) {
  try {
    yield put({ type: FETCHING_CLIENT_LIMIT, loading })
    const clientLimit = yield clientService.getLimit()
    yield put({ type: CLIENT_LIMIT_FETCHED, clientLimit, loading: false })
  } catch (e) {
    yield put({ type: FETCHING_CLIENT_LIMIT, loading: false })
    console.error(e)
  }
}

function * watchClient () {
  yield takeEvery(FETCH_CLIENTS, fetchClients)
  yield takeEvery(FETCH_CLIENT_LIMIT, fetchClientLimit)
}

export default watchClient
